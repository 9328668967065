import React from 'react';
import { Col } from 'react-bootstrap';
import SimpleLink from '../../components/SimpleLink';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ContactUsPage.scss';

const ContentPanel = styled.div`
  height: 80vh;
`;

const contactInfo = () => {
  if (window.location.host === 'foster-app-7dcfe.web.app') {
    return (
      <ul className="list-unstyled mb-3 mb-md-4">
        <li className="mb-3">
          <SimpleLink href="mailto:outreach@911fosterpets" type="normal">
            <FontAwesomeIcon icon="envelope" className="mr-2" />
            outreach@911fosterpets
          </SimpleLink>
        </li>
      </ul>
    );
  }

  return (
    <ul className="list-unstyled mb-3 mb-md-4">
      <li className="mb-3">
        <SimpleLink href="tel:4806053652" type="normal">
          <FontAwesomeIcon icon="phone" className="mr-2" />
          (480) 605-3652
        </SimpleLink>
      </li>
      <li className="mb-3">
        <SimpleLink href="mailto:outreach@911FosterPets.com" type="normal">
          <FontAwesomeIcon icon="envelope" className="mr-2" />
          outreach@911FosterPets.com
        </SimpleLink>
      </li>
    </ul>
  );
};

function ContactUsPage() {
  return (
    <ContentPanel className="d-flex flex-column justify-content-center align-content-center align-items-center h-75">
      <Col
        md="8"
        className="d-flex flex-column d-flex flex-column align-items-center justify-content-center text-center"
      >
        <h2>Contact Us</h2>
        <p>Need help? Get in touch with us below:</p>
        {contactInfo()}
      </Col>
    </ContentPanel>
  );
}

export default ContactUsPage;
