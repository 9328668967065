import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleLink = styled.a`
  .link {
    padding: 0px;
    color: $primary;
    border-bottom: 2px solid $primary;
    line-height: 1.25;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: lighten($primary, 10%);
      border-bottom-color: lighten($primary, 10%);
      text-decoration: none !important;
    }
    &:active {
      color: darken($primary, 5%);
      border-bottom-color: darken($primary, 5%);
      text-decoration: none !important;
    }
  }

  .danger {
    border-bottom-color: $gray-200;
    color: $gray-600;
    &:hover {
      color: $danger;
      border-bottom-color: $danger;
    }
    &:active {
      color: darken($danger, 18%);
      border-bottom-color: darken($danger, 18%);
    }
  }

  .white {
    border-bottom-color: transparentize($white, 0.5%);
    color: transparentize($white, 0.15%) !important;
    &:hover {
      color: $white !important;
      border-bottom-color: transparentize($white, 0.5%);
    }
    &:active {
      color: $white !important;
      border-bottom-color: transparentize($white, 0.25%);
    }
  }

  .subtle {
    border-bottom-color: $gray-200;
    color: $gray-600;
  }
`;

const SimpleLink = ({ href, type, children, targetBlank, className }) => {
  return (
    <StyledSimpleLink href={href} target={targetBlank ? '_blank' : ''}>
      {children}
    </StyledSimpleLink>
  );
};

SimpleLink.propTypes = {
  href: PropTypes.string,
  type: PropTypes.oneOf(['normal', 'subtle', 'danger', 'white']),
};

SimpleLink.defaultProps = {
  href: '',
  type: 'normal',
};

export default SimpleLink;
