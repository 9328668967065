import ReactGA from 'react-ga';

function trackException(appError) {
  ReactGA.exception({
    description: `${appError.name}: ${appError.message}.
${appError.stack}`.substr(0, 150),
  });
}

export { trackException };
