import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container } from 'react-bootstrap';
import { Formik } from 'formik';
import AddRescueUserForm from '../AddRescueUserPage/AddRescueUserForm';
import { EditUserSchema } from '../EditUserPage/EditUserSchema';
import { RESCUE_ADMIN_URL } from '../../constants/urls.constant';
import authService from '../../services/auth.service';
import { requestService } from '../../services/request.service';
import Loader from '../../components/Loader';
import { Redirect } from 'react-router-dom';

const EditUser = (props) => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(true);
  const [redirectToUserList, setRedirectToUserList] = useState(false);

  const {
    match: { params },
  } = props;

  useMountEffect(() => {
    requestService
      .fetch(`${RESCUE_ADMIN_URL}/v1/user?userId=${params.userid}`)
      .then((res) => res.json())
      .then((userData) => {
        setInitialValues({
          displayName: userData.displayName,
          email: userData.emailAddress,
          phoneNumber: userData.phoneNumber ? userData.phoneNumber : '',
          isRescueAdmin: userData.isRescueAdmin,
          shouldSendWantsToFosterAnimalNotification:
            userData.shouldSendWantsToFosterAnimalNotification === undefined
              ? true
              : userData.shouldSendWantsToFosterAnimalNotification,
          shouldSendCheckInNotifications:
            userData.shouldSendCheckInNotifications === undefined
              ? true
              : userData.shouldSendCheckInNotifications,
          shouldSendNewFosterParentNotifications:
            userData.shouldSendNewFosterParentNotifications === undefined
              ? true
              : userData.shouldSendNewFosterParentNotifications,
          editting: true,
        });
        setLoading(false);
      });
  });

  if (loading) {
    return <Loader />;
  }

  if (redirectToUserList) {
    return <Redirect to={`/rescue/${params.rescueid}/users`} />;
  }

  return (
    <Container className="py-3 py-sm-5">
      <Formik
        onSubmit={(values, actions) => {
          const {
            match: { params },
          } = props;

          const {
            phoneNumber,
            displayName,
            isRescueAdmin,
            shouldSendWantsToFosterAnimalNotification,
            shouldSendCheckInNotifications,
            shouldSendNewFosterParentNotifications,
          } = values;
          const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

          requestService
            .fetch(`${RESCUE_ADMIN_URL}/v1/user?userId=${params.userid}`, {
              body: JSON.stringify({
                emailAddress: values.email,
                phoneNumber: formattedPhoneNumber,
                displayName,
                isRescueAdmin,
                shouldSendWantsToFosterAnimalNotification,
                shouldSendCheckInNotifications,
                shouldSendNewFosterParentNotifications,
              }),
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authService.idToken,
              },
              method: 'PATCH',
            })
            .then((res) => {
              actions.setSubmitting(false);
              if (res.status === 200) {
                setRedirectToUserList(true);
              } else if (res.status !== 200) {
                alert(
                  'There was an error, please try again. Contact us if the issue continues.',
                );
              }
            });
        }}
        initialValues={initialValues}
        validationSchema={EditUserSchema}
        component={AddRescueUserForm}
      />
    </Container>
  );
};

export default EditUser;
