import React from 'react';
import { CenteredPage } from '../components/pages';

export default function PrivacyPolicy() {
  return (
    <CenteredPage className="text-justify">
      <h3 className="text-center">911fosterpets.com Privacy Policy</h3>
      <p>
        This privacy policy has been compiled to better serve those who are
        concerned with how their 'Personally Identifiable Information' (PII) is
        being used online. PII, as described in US privacy law and information
        security, is information that can be used on its own or with other
        information to identify, contact, or locate a single person, or to
        identify an individual in context. Please read our privacy policy
        carefully to get a clear understanding of how we collect, use, protect
        or otherwise handle your Personally Identifiable Information in
        accordance with our website.
      </p>
      <h5>
        What personal information do we collect from the people that visit our
        blog, website or app?
      </h5>
      <p>
        When ordering or registering on our site, as appropriate, you may be
        asked to enter your name, email address, mailing address, phone number
        or other details to help you with your experience.
      </p>
      <h5>When do we collect information?</h5>
      <p>
        We collect information from you when you register on our site, fill out
        a form or enter information on our site.
      </p>
      <h5>How do we use your information?</h5>
      <p>
        We may use the information we collect from you when you register, make a
        purchase, sign up for our newsletter, respond to a survey or marketing
        communication, surf the website, or use certain other site features in
        the following ways:
      </p>
      <ul>
        <li>
          To personalize your experience and to allow us to deliver the type of
          content and product offerings in which you are most interested.
        </li>
        <li>To improve our website in order to better serve you.</li>
        <li>
          To allow us to better service you in responding to your customer
          service requests.
        </li>
        <li>
          To administer a contest, promotion, survey or other site feature.
        </li>
        <li>
          To send periodic emails regarding your order or other products and
          services.
        </li>
        <li>
          To follow up with them after correspondence (live chat, email or phone
          inquiries)
        </li>
      </ul>
      <h5>How do we protect your information?</h5>
      <p>
        We do not use vulnerability scanning and/or scanning to PCI standards.
        We only provide articles and information. We never ask for credit card
        numbers. We use regular Malware Scanning.
      </p>
      <p>
        Your personal information is contained behind secured networks and is
        only accessible by a limited number of persons who have special access
        rights to such systems, and are required to keep the information
        confidential.
      </p>
      <p>
        In addition, all sensitive/credit information you supply is encrypted
        via Secure Socket Layer (SSL) technology. We implement a variety of
        security measures when a user enters, submits, or accesses their
        information to maintain the safety of your personal information. All
        transactions are processed through a gateway provider and are not stored
        or processed on our servers.
      </p>
      <h5>Do we use 'cookies'?</h5>
      <p>
        Yes. Cookies are small files that a site or its service provider
        transfers to your computer's hard drive through your Web browser (if you
        allow) that enables the site's or service provider's systems to
        recognize your browser and capture and remember certain information. For
        instance, we use cookies to help us remember and process the items in
        your shopping cart. They are also used to help us understand your
        preferences based on previous or current site activity, which enables us
        to provide you with improved services. We also use cookies to help us
        compile aggregate data about site traffic and site interaction so that
        we can offer better site experiences and tools in the future.
      </p>
      <h5>We use cookies to:</h5>
      <ul>
        <li>Understand and save user's preferences for future visits.</li>
        <li>Keep track of advertisements.</li>
        <li>
          Compile aggregate data about site traffic and site interactions in
          order to offer better site experiences and tools in the future. We may
          also use trusted third-party services that track this information on
          our behalf.
        </li>
      </ul>
      <p>
        You can choose to have your computer warn you each time a cookie is
        being sent, or you can choose to turn off all cookies. You do this
        through your browser settings. Since browser is a little different, look
        at your browser's Help Menu to learn the correct way to modify your
        cookies.
      </p>
      <h5>Third-party disclosure</h5>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your
        Personally Identifiable Information unless we provide users with advance
        notice. This does not include website hosting partners and other parties
        who assist us in operating our website, conducting our business, or
        serving our users, so long as those parties agree to keep this
        information confidential. We may also release information when it's
        release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others' rights, property or safety.{' '}
      </p>{' '}
      <p>
        However, non-personally identifiable visitor information may be provided
        to other parties for marketing, advertising, or other uses.
      </p>
      <h5>Third-party links</h5>
      <p>
        Occasionally, at our discretion, we may include or offer third-party
        products or services on our website. These third-party sites have
        separate and independent privacy policies. We therefore have no
        responsibility or liability for the content and activities of these
        linked sites. Nonetheless, we seek to protect the integrity of our site
        and welcome any feedback about these sites.
      </p>
      <h5>Google</h5>
      <p>
        Google's advertising requirements can be summed up by Google's
        Advertising Principles. They are put in place to provide a positive
        experience for users.{' '}
        <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
          https://support.google.com/adwordspolicy/answer/1316548?hl=en
        </a>{' '}
        <p>
          We have not enabled Google AdSense on our site but we may do so in the
          future.
        </p>
      </p>
      <h5>California Online Privacy Protection Act</h5>
      <p>
        CalOPPA is the first state law in the nation to require commercial
        websites and online services to post a privacy policy. The law's reach
        stretches well beyond California to require any person or company in the
        United States (and conceivably the world) that operates websites
        collecting Personally Identifiable Information from California consumers
        to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals or companies with whom
        it is being shared. - See more at:{' '}
        <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
        </a>
      </p>
      <h5>According to CalOPPA, we agree to the following:</h5>
      <p>
        Users can visit our site anonymously. Once this privacy policy is
        created, we will add a link to it on our home page or as a minimum, on
        the first significant page after entering our website. Our Privacy
        Policy link includes the word 'Privacy' and can easily be found on the
        page specified above.
      </p>
      <h6>You will be notified of any Privacy Policy changes: </h6>
      <ul>
        <li>On our Privacy Policy Page</li>
      </ul>
      <h6>Can change your personal information:</h6>
      <ul>
        <li>By logging in to your account</li>
      </ul>
      <h5>How does our site handle Do Not Track signals?</h5>
      <p>
        We honor Do Not Track signals and Do Not Track, plant cookies, or use
        advertising when a Do Not Track (DNT) browser mechanism is in place.
      </p>
      <h5>Does our site allow third-party behavioral tracking?</h5>
      <p>
        It's also important to note that we do not allow third-party behavioral
        tracking
      </p>
      <h5>COPPA (Children Online Privacy Protection Act)</h5>
      <p>
        When it comes to the collection of personal information from children
        under the age of 13 years old, the Children's Online Privacy Protection
        Act (COPPA) puts parents in control. The Federal Trade Commission,
        United States' consumer protection agency, enforces the COPPA Rule,
        which spells out what operators of websites and online services must do
        to protect children's privacy and safety online.
      </p>
      <p>
        We do not specifically market to children under the age of 13 years old.
      </p>
      <h5>Fair Information Practices</h5>
      <p>
        The Fair Information Practices Principles form the backbone of privacy
        law in the United States and the concepts they include have played a
        significant role in the development of data protection laws around the
        globe. Understanding the Fair Information Practice Principles and how
        they should be implemented is critical to comply with the various
        privacy laws that protect personal information.
      </p>
      <h6>
        In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:
      </h6>
      <p>We will notify the users via in-site notification</p>
      <ul>
        <li>Within 7 business days</li>
      </ul>
      <p>
        We also agree to the Individual Redress Principle which requires that
        individuals have the right to legally pursue enforceable rights against
        data collectors and processors who fail to adhere to the law. This
        principle requires not only that individuals have enforceable rights
        against data users, but also that individuals have recourse to courts or
        government agencies to investigate and/or prosecute non-compliance by
        data processors.
      </p>
      <h5>CAN SPAM Act</h5>
      <p>
        The CAN-SPAM Act is a law that sets the rules for commercial email,
        establishes requirements for commercial messages, gives recipients the
        right to have emails stopped from being sent to them, and spells out
        tough penalties for violations.
      </p>
      <h6>We collect your email address in order to:</h6>
      <ul>
        <li>
          Send information, respond to inquiries, and/or other requests or
          questions
        </li>
      </ul>
      <h5>To be in accordance with CANSPAM, we agree to the following:</h5>
      <ul>
        <li>Not use false or misleading subjects or email addresses.</li>
        <li>
          Identify the message as an advertisement in some reasonable way.
        </li>
        <li>
          Include the physical address of our business or site headquarters.
        </li>
        <li>
          Monitor third-party email marketing services for compliance, if one is
          used.
        </li>
        <li>Honor opt-out/unsubscribe requests quickly.</li>
        <li>
          Allow users to unsubscribe by using the link at the bottom of each
          email.
        </li>
      </ul>
      <p>
        If at any time you would like to unsubscribe from receiving future
        emails, you can email us at outreach@911fosterpets.com and we will
        promptly remove you from <strong>ALL</strong> correspondence.
      </p>
      <h5>Contacting Us</h5>
      <p>
        If there are any questions regarding this privacy policy, you may
        contact us using the information below.
      </p>
      <p>
        <a href="https://911fosterpets.com">911fosterpets.com</a>
        <br />
        <a href="mailto:outreach@911fosterpets.com">
          outreach@911fosterpets.com
        </a>
      </p>
      <p className="small text-muted font-italic">Last Edited on 2017-05-09</p>
    </CenteredPage>
  );
}
