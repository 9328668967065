import 'firebase/auth';
import React from 'react';
import { SpreadTheWordPanel } from '../../components';
import {
  Covid19Panel,
  RehomePanel,
  // FosterHeroPanel,
  AnimalsPanel,
  PartnersPanel,
} from './panels';
import { HomePageBanner } from '.';
import CovidBanner from './CovidBanner';

export default function HomePage() {
  window.location.href = 'https://www.911FosterPets.com';
  return (
    <>
      <HomePageBanner />
      <CovidBanner />
      <Covid19Panel />
      <RehomePanel />
      {/* <FosterHeroPanel /> */}
      <AnimalsPanel />
      <PartnersPanel />
      <SpreadTheWordPanel />
    </>
  );
}
