import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Redirect } from 'react-router';
import PlaceholderText from '../../components/PlaceholderText';
import AnimalPageFosterList from './AnimalPageFosterList';
import BackButton from '../../components/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from '../../Firestore';
import Modal from 'react-modal';
import AddAnimalFosterRelationshipModal from '../../components/AddAnimalFosterRelationshipForm/AddAnimalFosterRelationshipModal';
import FosterParentList from '../../components/FosterParentList/FosterParentList';
import statusMap from '../../utils/animalStatusMap';
import placeholder from '../../assets/paw.png';
import SimpleLink from '../../components/SimpleLink';
import Loader from '../../components/Loader';
import { PLURALIZATION } from '../../constants/pluralization.constant';
import { CONDITIONSMAPWITHUNKNOWNS } from '../../constants/conditions.constant';

class AnimalPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allFosters: [],
      animalInfo: {},
      loaded: false,
      redirectToBroadcast: false,
      redirectToDelete: false,
      redirectToEdit: false,
      shouldRedirect: false,
      redirectToCheckIns: false,
      imageName: '',
      potentialFostersLoaded: false,
      publicUrl: '',
    };

    this.deleteAnimal = this.deleteAnimal.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.load = this.load.bind(this);
    this.openFosterModal = this.openFosterModal.bind(this);
    this.closeFosterModal = this.closeFosterModal.bind(this);

    this.placeAnimal = this.placeAnimal.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async load() {
    const db = firebase.firestore();

    const {
      match: { params },
    } = this.props;

    const animalInfo = await db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid).get();

      let publicUrl = '';
      
      if(animalInfo.data().publicId) {
        const publicAnimalInfo = await db
          .collection('publicAnimals')
          .doc(animalInfo.data().publicId).get();

        publicUrl = publicAnimalInfo.data()?.publicUrl;
      }

      const amountOfAnimals =
        animalInfo.data().quantity && animalInfo.data().quantity > 1
          ? 'plural'
          : 'singular';

      this.setState({
        animalDoc: animalInfo,
        animalInfo: { ...animalInfo.data(), id: animalInfo.id },
        loaded: true,
        amountOfAnimals,
        imageName: animalInfo.data().imageName,
        publicUrl,
      });

    const allFosters = [];

    db.collection('rescues')
      .doc(params.rescueid)
      .collection('animalFosterApplication')
      .where('animalId', '==', params.animalid)
      .get()
      .then((data) => {
        if (data.docs.length > 0) {
          const animalFosterApplicationDocs = data.docs
            .map((animalFosterApplicationDoc) => {
              const fosterId = animalFosterApplicationDoc.data().fosterId;

              const status = animalFosterApplicationDoc.get('status');

              if (status === 'archived') {
                return undefined;
              }

              return {
                fosterDoc: db
                  .collection('rescues')
                  .doc(params.rescueid)
                  .collection('fosters')
                  .doc(fosterId),
                animalFosterApplicationId: animalFosterApplicationDoc.id,
                status,
              };
            })
            .filter((doc) => doc);

          animalFosterApplicationDocs.forEach((doc) => {
            doc.fosterDoc.get().then((fosterData) => {
              allFosters.push({
                ...fosterData.data(),
                fosterId: doc.fosterDoc.id,
                applicationStatus: doc.status,
                animalFosterApplicationId: doc.animalFosterApplicationId,
              });
              this.setState({ allFosters, potentialFostersLoaded: true });
            });
          });
        } else {
          this.setState({ potentialFostersLoaded: true });
        }
      });
  }

  componentDidMount() {
    this.load();
  }

  async placeAnimal(rescueId, animalFosterApplicationId) {
    const db = firebase.firestore();

    const animalFosterApplicationDocRef = db
      .collection('rescues')
      .doc(rescueId)
      .collection('animalFosterApplication')
      .doc(animalFosterApplicationId);

    const animalFosterApplicationDoc = await animalFosterApplicationDocRef.get();

    this.setState({
      fosterIdForFosterModal: animalFosterApplicationDoc.get('fosterId'),
      animalIdForFosterModal: animalFosterApplicationDoc.get('animalId'),
    });

    this.setState({ isAddFosterModalOpen: true });
    this.load();
  }

  deleteAnimal() {
    const user = JSON.parse(localStorage.getItem('user'));

    if(user.isRehomingUser) {
      this.setState({ redirectToDelete: true });
    } else {
      const r = window.confirm('Are you sure you want to delete this animal?');
      if (r === true) {
        this.state.animalDoc.ref.delete().then(() => {
          if (this.state.imageUrl) {
            firebase
              .storage()
              .ref('images/' + this.state.imageName)
              .delete()
              .then(() => {
                this.setState({ shouldRedirect: true });
              });
          } else {
            this.setState({ shouldRedirect: true });
          }
        });
      }
    }
  }

  openFosterModal(animalId, fosterId) {
    this.setState({
      isAddFosterModalOpen: true,
      animalIdForFosterModal: animalId,
      fosterIdForFosterModal: fosterId,
    });
  }

  closeFosterModal() {
    this.load();
    this.setState({ isAddFosterModalOpen: false });
  }

  buildConditionStatement() {
    let conditions = this.state.animalInfo.conditions.map((condition) => {
      return CONDITIONSMAPWITHUNKNOWNS[condition];
    });

    let conditionStatement;

    if (conditions.length > 0) {
      if (conditions.length > 1) {
        conditions[conditions.length - 1] = `and ${
          conditions[conditions.length - 1]
        }.`;
      }

      conditions = conditions.join(', ');

      conditionStatement = `${PLURALIZATION[this.state.amountOfAnimals].ThisFosterAnimal} foster ${PLURALIZATION[this.state.amountOfAnimals].animal} will need a home that can support animals that ${conditions}\n\n`;
    } else {
      conditionStatement = `${PLURALIZATION[this.state.amountOfAnimals].ThisFosterAnimal} has no known special needs other than needing a good temporary home.\n\n`;
    }

    return conditionStatement;
  }

  showAgeInfo(ageMonths, ageYears) {
    if (!ageMonths && !ageYears) {
      return <div>unknown</div>;
    } else {
      return (
        <div>
          {ageYears && !ageMonths ? `${ageYears} year(s)` : ''}
          {ageYears && ageMonths
            ? `${ageYears} year(s) and ${ageMonths} month(s)`
            : ''}
          {ageMonths && !ageYears ? `${ageMonths} month(s)` : ''} old
        </div>
      );
    }
  }

  showPublicRecord() {
    if (
      this.state.animalInfo.status === 'available' &&
      !this.state.animalInfo.publicId
    ) {
      return <span>Public records are being created</span>;
    } else if (
      this.state.animalInfo.status === 'available' &&
      this.state.animalInfo.publicId && this.state.publicUrl
    ) {
      return (
        <span>
          Public record can be viewed{' '}
          <SimpleLink href={this.state.publicUrl}>
            here
          </SimpleLink>
        </span>
      );
    }
  }

  showAnimalInfo() {
    if (this.state.loaded) {
      const conditionStatement = this.buildConditionStatement();
      const {
        animalInfo,
        redirectToBroadcast,
        redirectToEdit,
        redirectToCheckIns,
        redirectToDelete,
      } = this.state;
      const { rescueid, animalid } = this.props.match.params;
      const {
        species,
        gender,
        ageYears,
        ageMonths,
        breed,
        notes,
        status,
        id,
        publicNotes,
        quantity,
        potentialLengthOfStay,
      } = animalInfo;

      const amountOfAnimals = quantity && quantity > 1 ? 'plural' : 'singular';

      return (
        <div>
          <div className="mb-4">
            <h3 className="mb-0">{this.state.animalInfo.name}</h3>
            <h5>{this.showPublicRecord()}</h5>
            <div className="text-muted small">ID: {animalid}</div>
            <div className="text-muted small text-uppercase">{species}</div>
          </div>

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">
              Foster Parents
            </Form.Label>
            <br />

            <FosterParentList
              fosterParents={this.state.animalInfo.fosterParents || []}
              afterRemove={this.load}
              animalName={this.state.animalInfo.name}
              rescueId={rescueid}
              animalId={animalid}
              onClickAdd={(animalId, fosterId) =>
                this.openFosterModal(animalId, fosterId)
              }
            />
          </Form.Group>

          {quantity > 1 ? (
            <Form.Group>
              <Form.Label className="font-weight-bold mb-1">
                Quantity
              </Form.Label>
              <br />

              <div>{quantity}</div>
            </Form.Group>
          ) : (
            <div></div>
          )}

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">Gender</Form.Label>
            <br />

            {gender}
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">Details</Form.Label>
            <br />

            <div>{conditionStatement}</div>
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">
              Breed (or looks like)
            </Form.Label>
            <br />

            <div>{breed}</div>
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">Age</Form.Label>
            <br />
            <div> {this.showAgeInfo(ageMonths, ageYears)} </div>
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">Status</Form.Label>
            <br />

            <div>{statusMap[status] || status}</div>
          </Form.Group>

          {potentialLengthOfStay ? <Form.Group>
            <Form.Label className="font-weight-bold mb-1">Expected length of fostering</Form.Label>
            <br />

            <div>{potentialLengthOfStay}</div>
          </Form.Group> : <div/>}

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">
              Private Notes
            </Form.Label>
            <br />

            <div>{notes || <PlaceholderText>No notes</PlaceholderText>}</div>
          </Form.Group>

          <Form.Group>
            <Form.Label className="font-weight-bold mb-1">
              Public Notes
            </Form.Label>
            <br />

            <div>
              {publicNotes || <PlaceholderText>No notes</PlaceholderText>}
            </div>
          </Form.Group>

          <hr />
          <Form.Group>
            <Form.Label className="font-weight-bold">Actions</Form.Label>
            <br />

            <Button
              variant="primary"
              size="sm"
              className="mb-3 mr-3 mb-lg-0"
              onClick={() => this.setState({ redirectToBroadcast: true })}
            >
              <FontAwesomeIcon
                icon={['far', 'broadcast-tower']}
                className="mr-2"
              />
              Matching and Broadcasting
            </Button>

            {this.state.animalInfo.fosterParents &&
              this.state.animalInfo.fosterParents.length > 0 && (
                <Button
                  variant="secondary"
                  size="sm"
                  className="mb-3 mr-3 mb-lg-0"
                  onClick={() => {
                    this.setState({ redirectToCheckIns: true });
                    this.props.history.push('checkIns');
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'comment']} className="mr-2" />
                  check-ins
                </Button>
              )}

            <Button
              variant="secondary"
              size="sm"
              className="mb-3 mr-3 mb-lg-0"
              onClick={() => this.setState({ redirectToEdit: true })}
            >
              <FontAwesomeIcon icon={['far', 'pen']} className="mr-2" />
              Edit {PLURALIZATION[amountOfAnimals].Animal}
            </Button>

            <Button
              variant="danger"
              size="sm"
              className="mb-3 mr-3 mb-lg-0 mt-xl-0"
              onClick={this.deleteAnimal}
            >
              <FontAwesomeIcon icon={['far', 'trash-alt']} className="mr-2" />
              Delete {PLURALIZATION[amountOfAnimals].Animal}
            </Button>

            {redirectToCheckIns && (
              <Redirect to={`/rescue/${rescueid}/animals/${id}/checkIns`} />
            )}

            {redirectToBroadcast && (
              <Redirect to={`/rescue/${rescueid}/broadcastAnimal/${id}`} />
            )}

            {redirectToDelete && (
              <Redirect to={`/rescue/${rescueid}/animals/${id}/delete`} />
            )}

            {redirectToEdit && (
              <Redirect to={`/rescue/${rescueid}/animals/${id}/edit`} />
            )}
          </Form.Group>
        </div>
      );
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const { rescueid, animalid } = params;

    const {
      animalInfo,
      shouldRedirect,
      fosterIdForFosterModal,
      animalIdForFosterModal,
    } = this.state;

    const { imageUrl, quantity } = animalInfo;

    const amountOfAnimals = quantity && quantity > 1 ? 'plural' : 'singular';

    return (
      <Container className="my-3 my-md-5 py-3 py-md-3">
        <BackButton url={`/rescue/${rescueid}/animals`}>
          Back To Animals
        </BackButton>

        <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400 mb-5">
          <Row>
            <Col sm="4">
              <Row className="px-3">
                <div className="d-flex align-items-center justify-content-center w-100 mb-5">
                  <img
                    height="100%"
                    width="100%"
                    alt={`${PLURALIZATION[amountOfAnimals].Animal} from rescue or shelter that ${PLURALIZATION[amountOfAnimals].needs} fostering. This helps foster coordinators identify and manage their animals more easily.`}
                    src={imageUrl ? imageUrl : placeholder}
                  />
                </div>
              </Row>
            </Col>
            <Col md="8" className="mx-auto">
              {this.showAnimalInfo()}
            </Col>
          </Row>
        </div>

        {this.state.potentialFostersLoaded ? (
          <AnimalPageFosterList
            fosters={this.state.allFosters}
            rescueId={rescueid}
            placeAnimal={this.placeAnimal}
            animalId={animalid}
          />
        ) : (
          <Loader />
        )}

        <Modal
          className="bg-white p-3 p-md-4 border col-10 col-md-6 mx-auto my-5"
          isOpen={this.state.isAddFosterModalOpen}
          onRequestClose={this.closeFosterModal}
          contentLabel="Foster RelationShip Modal"
          ariaHideApp={false}
        >
          <div>
            <Button
              variant="danger"
              size="sm"
              className="d-flex ml-auto mb-3"
              onClick={this.closeFosterModal}
            >
              Close
            </Button>
          </div>

          <AddAnimalFosterRelationshipModal
            animalId={animalIdForFosterModal}
            rescueId={rescueid}
            onClose={this.closeFosterModal}
            fosterId={fosterIdForFosterModal}
          />
        </Modal>

        {shouldRedirect && (
          <Redirect to={`/rescue/${params.rescueid}/animals`} />
        )}
      </Container>
    );
  }
}

export default AnimalPage;
