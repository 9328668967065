import React from 'react';

import firebase from '../../Firestore';
import './PublicFosterPage.css';

class PublicFosterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fosterDoc: {},
      fosterInfo: {},
      loaded: false,
      success: false,
    };

    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    const db = firebase.firestore();

    const {
      match: { params },
    } = this.props;

    const fosterInfo = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('fosters')
      .doc(params.fosterid);

    fosterInfo.get().then((data) => {
      this.setState({
        fosterDoc: fosterInfo,
        fosterInfo: data.data(),
        loaded: true,
      });
    });
  }

  handleStatusChange(e) {
    this.state.fosterDoc.update({
      ...this.state.fosterInfo,
      status: e.target.value,
    });

    this.setState({ success: true });
  }

  showOptions() {
    return (
      <select
        name="status"
        className="form-control"
        onChange={this.handleStatusChange}
        defaultValue={this.state.fosterInfo.status}
      >
        <option value="available">available</option>

        <option value="unavailable">unavailable</option>
      </select>
    );
  }

  showFosterInfo() {
    if (this.state.success) {
      return <div>You have successfully changed your status</div>;
    }

    if (this.state.loaded) {
      return (
        <div>
          <div className="mb-3">
            <div>
              {this.state.fosterInfo.firstName +
                ' ' +
                this.state.fosterInfo.lastName}
            </div>

            <div className="form-group">
              <label htmlFor="status">Status:</label>

              {this.showOptions()}
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return <div className="foster-page">{this.showFosterInfo()}</div>;
  }
}
export default PublicFosterPage;
