import { Formik } from 'formik';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { requestService } from '../../services/request.service';
import AddUserForm from './AddRescueUserForm';
import { AddRescueUserSchema } from './AddRescueUserSchema';
import { RESCUE_ADMIN_URL } from '../../constants/urls.constant';

const initialValues = {
  displayName: '',
  phoneNumber: '',
  email: '',
  password: '',
  isRescueAdmin: false,
  shouldSendWantsToFosterAnimalNotification: true,
  shouldSendCheckInNotifications: true,
  shouldSendNewFosterParentNotifications: true,
};

function AddUserPage(props) {
  const [success, setSuccess] = useState(false);

  async function addUser(user, actions) {
    const formattedPhoneNumber = user.phoneNumber.replace(/\D/g, '');

    try {
      await requestService.fetch(`${RESCUE_ADMIN_URL}/v1/user`, {
        body: JSON.stringify({ ...user, phoneNumber: formattedPhoneNumber }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      setSuccess(true);
    } catch (err) {
      actions.setError('Error');
      alert(
        'There was an error, please try again. Contact us if the issue continues.',
      );

      setSuccess(false);
    } finally {
      actions.setSubmitting(false);
    }
  }

  if (!success) {
    return (
      <Container className="py-3 py-sm-5">
        <Formik
          onSubmit={addUser}
          initialValues={initialValues}
          validationSchema={AddRescueUserSchema}
          component={AddUserForm}
        />
      </Container>
    );
  } else {
    const {
      match: { params },
    } = props;

    return <Redirect to={`/rescue/${params.rescueid}/users`} />;
  }
}

export default AddUserPage;
