import { CONDITIONSLIST, CONDITIONSMAP } from '../constants/conditions.constant';

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const sortAlphabetically = (a, b) => {
  const aLabel = a.label.toUpperCase();
  const bLabel = b.label.toUpperCase();

  if (aLabel < bLabel) return -1;
  if (aLabel > bLabel) return 1;

  return 0;
};

const sortAlphabeticallyDesc = (a, b) => {
  const aLabel = a.label.toUpperCase();
  const bLabel = b.label.toUpperCase();

  if (aLabel < bLabel) return 1;
  if (aLabel > bLabel) return -1;

  return 0;
};

function transformListRefinements(items) {
  return items
    .map(({ label, ...props }) => ({ ...props, label: capitalize(CONDITIONSMAP[label] || label) }))
    .sort(sortAlphabetically);
};

function transformMenuRefinements(items) {
  return items.sort(sortAlphabeticallyDesc)
}

function getInitialConditions(conditions) {
  return Array.isArray(conditions)
    ? CONDITIONSLIST.filter((condition) => !conditions.includes(condition))
    : CONDITIONSLIST;
}

export {
  getInitialConditions,
  transformListRefinements,
  transformMenuRefinements,
}
