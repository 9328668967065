import React from 'react';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import Table from '../../components/react-table/Table';
import { SendMessageButton } from '.';
import { COMM_URL } from '../../constants/urls.constant';
import firebase from '../../Firestore';
import { requestService } from '../../services/request.service';
import { PLURALIZATION } from '../../constants/pluralization.constant';

import './BroadcastPage.css';
import { CONDITIONSMAPWITHUNKNOWNS } from '../../constants/conditions.constant';
import { Button } from '../../components';

class BroadcastPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fosters: [],
      animalFosterApplication: [],
      animalInfo: {},
      loaded: false,
      recipients: [],
      redirect: false,
      showButtonsAsLoading: false,
    };

    this.updateInput = this.updateInput.bind(this);
    this.confirmSend = this.confirmSend.bind(this);
    this.sendTextBroadcast = this.sendBroadcast.bind(this);
    this.sendSingleMessage = this.sendSingleMessage.bind(this);
    this.confirmSendToPublic = this.confirmSendToPublic.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    const db = firebase.firestore();

    const {
      match: { params },
    } = this.props;

    const animalInfo = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid);

    animalInfo.get().then((data) => {
      this.getMatchingFosterNumbers(
        data.get('conditions'),
        data.get('species'),
      );
      this.setState({
        animalInfo: { ...data.data(), id: data.id },
      });
    });

    const allFosters = [];

    db.collection('rescues')
      .doc(params.rescueid)
      .collection('animalFosterApplication')
      .where('animalId', '==', params.animalid)
      .get()
      .then((data) => {
        if (data.docs.length > 0) {
          const fosterDocs = data.docs.map((doc) => {
            const fosterId = doc.data().fosterId;

            return db
              .collection('rescues')
              .doc(params.rescueid)
              .collection('fosters')
              .doc(fosterId);
          });

          fosterDocs.forEach((doc) => {
            doc.get().then((fosterData) => {
              allFosters.push({ ...fosterData.data(), fosterId: doc.id });
              this.setState({ animalFosterApplication: allFosters });
            });
          });
        }
      });
  }

  getMatchingFosterNumbers(animalsConditions, animalSpecies) {
    const db = firebase.firestore();
    const {
      match: { params },
    } = this.props;

    const rescueDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('fosters');

    rescueDoc.get().then((data) => {
      const allFosters = data.docs.map((doc) => {
        if (doc.data()) {
          return { ...doc.data(), id: doc.id };
        }

        return null;
      });

      const matchingFosters = allFosters.map((foster) => {
        const conditionIntersection = animalsConditions.filter((value) => {
          if (foster.conditions) {
            return foster.conditions.includes(value);
          }

          return null;
        });

        if (!foster.acceptedSpecies) {
          return foster;
        }

        if (
          conditionIntersection.length <= 0 &&
          (foster.acceptedSpecies.includes(animalSpecies) ||
            foster.acceptedSpecies.includes('both'))
        ) {
          return foster;
        }

        return null;
      });

      const existingPhoneNumbers = [];

      let recipients = matchingFosters.map((foster) => {
        if (
          foster &&
          foster.phoneNumber &&
          foster.status &&
          (foster.status === 'available' || foster.status === 'fostering') &&
          !existingPhoneNumbers.includes(foster.phoneNumber)
        ) {
          existingPhoneNumbers.push(foster.phoneNumber);

          return {
            phoneNumber: foster.phoneNumber,
            emailAddress: foster.emailAddress,
            communicationPreference: foster.communicationPreference,
            fosterId: foster.id,
            firstName: foster.firstName,
            lastName: foster.lastName,
          };
        }

        return null;
      });

      recipients = recipients.filter((el) => {
        return el !== null;
      });

      this.setState({
        recipients,
        loaded: true,
      });
    });
  }

  confirmSend() {
    this.setState({ showButtonsAsLoading: true });

    const r = window.confirm('Are you sure you want to broadcast this?');
    if (r === true) {
      this.sendBroadcast();
    } else {
      this.setState({ showButtonsAsLoading: false });
    }
  }

  buildConditionStatement() {
    const amountOfAnimals =
      this.state.animalInfo.quantity > 1 ? 'plural' : 'singular';

    let conditions = this.state.animalInfo.conditions.map((condition) => {
      return CONDITIONSMAPWITHUNKNOWNS[condition];
    });

    let conditionStatement;

    if (conditions.length > 0) {
      if (conditions.length > 1) {
        conditions[conditions.length - 1] = `and ${
          conditions[conditions.length - 1]
        }.`;
      }

      conditions = conditions.join(', ');

      conditionStatement = `${PLURALIZATION[amountOfAnimals].ThisFosterAnimal} will need a home that can support animals that ${conditions}.\n\n`;
    } else {
      conditionStatement = `${PLURALIZATION[amountOfAnimals].ThisFosterAnimal} has no known special needs other than needing a good temporary home.\n\n`;
    }

    return conditionStatement;
  }

  showRedirect() {
    const {
      match: { params },
    } = this.props;

    if (this.state.redirect) {
      return (
        <Redirect
          to={`/rescue/${params.rescueid}/animals/${params.animalid}`}
        />
      );
    }
  }

  formattedAgeInfo(ageMonths, ageYears) {
    if (!ageMonths && !ageYears) {
      return '';
    } else if (ageMonths && !ageYears) {
      return `${ageMonths} month(s) old`;
    } else if (ageYears && !ageMonths) {
      return `${ageYears} year(s) old`;
    }

    return `${ageYears} year(s) and ${ageMonths} month(s) old`;
  }

  confirmSendToPublic() {
    this.setState({ showButtonsAsLoading: true });

    const r = window.confirm('Are you sure you want to broadcast this?');
    if (r === true) {
      const {
        match: { params },
      } = this.props;

      return requestService
        .fetch(`${COMM_URL}/v1/messageToPublic`, {
          body: JSON.stringify({
            publicAnimalId: this.state.animalInfo.publicId,
            rescueId: params.rescueid,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
        .then(() => {
          alert('successfully sent');
          this.setState({ showButtonsAsLoading: false });
        });
    } else {
      this.setState({ showButtonsAsLoading: false });
    }
  }

  sendMessage(recipients) {
    const conditionStatement = this.buildConditionStatement();
    const {
      match: { params },
    } = this.props;

    const amountOfAnimals =
      this.state.animalInfo.quantity > 1 ? 'plural' : 'singular';

    return requestService.fetch(`${COMM_URL}/v1/message`, {
      body: JSON.stringify({
        messages: [
          {
            message: ` we have ${
              this.state.animalInfo.quantity > 1
                ? this.state.animalInfo.quantity
                : PLURALIZATION[amountOfAnimals].a
            } ${this.state.animalInfo.gender} ${this.formattedAgeInfo(
              this.state.animalInfo.ageMonths,
              this.state.animalInfo.ageYears,
            )}
${this.state.animalInfo.breed} ${this.state.animalInfo.species}${
              PLURALIZATION[amountOfAnimals].s
            } that ${PLURALIZATION[amountOfAnimals].needs} a foster home!\n
${conditionStatement}You can view their picture and sign up to foster at
http://app.911fosterpets.com/${params.rescueid}/fosterAnimal/${
              params.animalid
            }`,
            recipients,
            rescueId: params.rescueid,
          },
        ],
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
  }

  sendSingleMessage(foster) {
    return this.sendMessage([
      {
        phoneNumber: foster.phoneNumber,
        emailAddress: foster.emailAddress,
        communicationPreference: foster.communicationPreference,
        fosterId: foster.fosterId,
        firstName: foster.firstName,
        lastName: foster.lastName,
      },
    ]);
  }

  sendBroadcast() {
    this.setState({ loaded: false });

    this.sendMessage(this.state.recipients)
      .then(() => {
        alert('successfully sent');
        this.setState({ redirect: true, showButtonsAsLoading: false });
      })
      .catch((error) => {
        this.setState({ redirect: true });

        alert('broadcast failed: ' + error);
      });
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const { rescueid: rescueId } = params;
    const { recipients, loaded } = this.state;

    return (
      <div className="broadcast-page">
        {this.showRedirect()}
        <div className="md-3">
          {`Below are the matching fosters in your network that can support ${this.state.animalInfo.name}
          based on their conditions and capacity. Click send broadcast to send a
          plea to the matching fosters.`}
        </div>
        <Button
          loading={this.state.showButtonsAsLoading}
          disabled={!this.state.loaded}
          color="primary"
          className="btn btn-primary text-white mb-3"
          onClick={this.confirmSend}
        >
          Send Broadcast to All Below
        </Button>

        <div className="md-3 mb-3">
          Alternatively, if this pet is available for foster, you can send to
          any potential fosters that have signed up as willing to foster for the
          public in your surrounding area. If you get matches, you will need to
          put them through your normal foster onboarding process.
        </div>
        <Button
          loading={this.state.showButtonsAsLoading}
          disabled={!this.state.loaded || !this.state.animalInfo.publicId}
          className="btn btn-primary text-white mb-3"
          color="primary"
          onClick={this.confirmSendToPublic}
        >
          Send Broadcast to out of network fosters in the area
        </Button>

        <Table
          data={recipients}
          columns={[
            {
              Cell: ({ original: foster }) => {
                return (
                  <NavLink
                    to={`/rescue/${rescueId}/fosters/${foster.fosterId}`}
                  >
                    {foster.firstName} {foster.lastName}
                  </NavLink>
                );
              },
              Header: () => <b>Name</b>,
              accessor: 'firstName',
              width: 200,
            },
            {
              Cell: ({ original: foster }) => {
                if (
                  foster.communicationPreference &&
                  foster.communicationPreference === 'phoneCall'
                ) {
                  return (
                    <div>
                      <div className="text-danger">Needs Phone Call</div>
                      <div>{foster.phoneNumber}</div>
                    </div>
                  );
                } else if (
                  foster.communicationPreference &&
                  foster.communicationPreference === 'email'
                ) {
                  return (
                    <div>
                      <div>{foster.communicationPreference}</div>
                      <div>{foster.emailAddress}</div>
                    </div>
                  );
                } else if (
                  foster.communicationPreference &&
                  foster.communicationPreference === 'textAndEmail'
                ) {
                  return (
                    <div>
                      <div>text</div>
                      <div>{foster.phoneNumber}</div>
                      <div>and email</div>
                      <div>{foster.emailAddress}</div>
                    </div>
                  );
                }
                return (
                  <div>
                    <div>{foster.communicationPreference}</div>
                    <div>{foster.phoneNumber}</div>
                  </div>
                );
              },
              Header: () => <b>Communication Preference</b>,
              accessor: 'communicationPreference',
              width: 250,
            },
            {
              Cell: ({ original: foster }) => {
                if (foster.communicationPreference === 'phoneCall') {
                  return '';
                }
                return (
                  <SendMessageButton
                    foster={foster}
                    action={this.sendSingleMessage}
                  />
                );
              },
              Header: () => <b>Actions</b>,
              accessor: 'firstName',
              width: 200,
            },
          ]}
          loading={!loaded}
          showPagination={false}
          pageSize={recipients.length}
        />
      </div>
    );
  }
}
export default BroadcastPage;
