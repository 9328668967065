import * as yup from 'yup';

const EditUserSchema = yup.object().shape({
  displayName: yup.string(),
  phoneNumber: yup
    .string()
    .required('Required')
    .max(11, 'Phone number cannot be more than 11 digits')
    .min(10, 'Phone number cannot be less than 10 digits'),
  email: yup
    .string()
    .email()
    .required('Required'),
  isRescueAdmin: yup.boolean(),
});

export { EditUserSchema };
