import React from 'react';
import { Container } from 'react-bootstrap';
import CheckInsSettings from '../../components/CheckInsSettings';
import PublicSettings from '../../components/PublicSettings';

const SettingsPage = (props) => {
  return (
    <Container className="py-3 py-sm-5">
      <h3 className="mb-3">Settings</h3>

      <CheckInsSettings {...props} />

      <PublicSettings {...props} />
    </Container>
  );
};

export default SettingsPage;
