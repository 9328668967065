const CONDITIONSARRAY = [
  { label: 'are sick', value: 'issick' },
  {
    value: 'needsmedical',
    label: 'has a medical condition or needs medicine',
  },
  {
    label: 'will need bottle feeding',
    value: 'needsbottlefeeding',
  },
  {
    value: 'seniors',
    label: 'are seniors',
  },
  {
    value: 'babies',
    label: 'are puppies/kittens or less than one year old',
  },
  {
    label: 'would do best in a home without cats',
    value: 'cataggressive',
  },
  {
    label: 'would do best in a home without dogs',
    value: 'dogaggressive',
  },
  {
    label: 'cannot be around small children (younger than 12)',
    value: 'noyoungchildren',
  },
  {
    label: 'are protective of food',
    value: 'foodaggressive',
  },
  {
    label: 'have separation anxiety',
    value: 'separationanxiety',
  },
  {
    label: 'have restricted movement',
    value: 'restrictedmovement',
  },
  {
    label: 'may not be kennel-trained',
    value: 'needskenneltraining',
  },
  {
    label: 'are pregnant and will need help birthing',
    value: 'ispregnant',
  },
  {
    label: 'are very high energy',
    value: 'highenergy',
  },
  {
    value: 'behavioralissues',
    label: 'have behavioral issues',
  },
  {
    value: 'nosmallanimals',
    label: 'cannot be around small animals',
  },
  {
    value: 'needshousetraining',
    label: 'may not be house trained',
  },
  {
    value: 'sizebig',
    label: 'are more than 55 lbs',
  },
  {
    value: 'sizesmall',
    label: 'are less than 22 lbs',
  },
];

const CONDITIONSLIST = [
  'issick',
  'needsmedical',
  'needsbottlefeeding',
  'seniors',
  'babies',
  'cataggressive',
  'dogaggressive',
  'noyoungchildren',
  'foodaggressive',
  'separationanxiety',
  'restrictedmovement',
  'needskenneltraining',
  'ispregnant',
  'highenergy',
  'behavioralissues',
  'nosmallanimals',
  'needshousetraining',
  'sizebig',
  'sizesmall',
];

const CONDITIONSMAP = {
  behavioralissues: 'have behavioral issues',
  cataggressive: 'would do best in a home without cats',
  dogaggressive: 'would do best in a home without dogs',
  seniors: 'is a senior',
  babies: 'is a puppy or kitten or less than one year old',
  foodaggressive: 'is protective around food',
  highenergy: 'is very high energy',
  ispregnant: 'is pregnant and will need help birthing',
  issick: 'is sick',
  needsmedical: 'has a medical condition or needs medicine',
  needsbottlefeeding: 'will need bottle feeding',
  needshousetraining: 'may not be house trained',
  needskenneltraining: 'may not be kennel-trained',
  nosmallanimals: 'cannot be around small animals',
  noyoungchildren: 'cannot be around small children (younger than 12)',
  restrictedmovement: 'have restricted movement',
  separationanxiety: 'have separation anxiety',
  sizebig: 'is more than 55 lbs',
  sizesmall: 'is less than 22 lbs',
};

const CONDITIONSMAPWITHUNKNOWNS = {
  ...CONDITIONSMAP,
  maybecataggressive: 'is unknown if okay around cats',
  maybedogaggressive: 'is unknown if okay around dogs',
  maybehousetraining: 'is unknown if house trained',
  maybekenneltraining: 'is unknown if kennel-trained',
  maybeyoungchildren: 'is unknown if comfortable around children',
};

export {
  CONDITIONSARRAY,
  CONDITIONSMAP,
  CONDITIONSMAPWITHUNKNOWNS,
  CONDITIONSLIST,
};
