import React, { useState } from 'react';
import Loader from './Loader';
import { API_URL } from '../constants/urls.constant';
import { requestService } from '../services/request.service';
import { CSVReader } from 'react-papaparse';
import { Button } from '.';
import SimpleLink from './SimpleLink';

const PetpointImportCard = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const fileInput = React.createRef();

  const handleReadCSV = (data, fileName) => {
    if (fileName.split('.')[1] !== 'csv') {
      alert('File must be CSV');
      return;
    }

    setLoading(true);

    data.data.splice(0, 4);

    requestService
      .fetch(`${API_URL}/v1/uploadAnimals`, {
        body: JSON.stringify(data.data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        alert('There was an error, please try again');
        setLoading(false);
      });
  };

  const handleOnError = (err, file, inputElem, reason) => {
    alert(reason);
  };

  const handleImportOffer = () => {
    fileInput.current.click();
  };

  const showAddForm = () => {
    if (loading) {
      return <Loader />;
    }

    if (success) {
      return <div>Success, may take a few minutes to take effect</div>;
    }

    return (
      <div>
        <CSVReader
          onFileLoaded={handleReadCSV}
          inputRef={fileInput}
          style={{ display: 'none' }}
          onError={handleOnError}
        />

        <Button loading={loading} color="primary" onClick={handleImportOffer}>
          Import
        </Button>
      </div>
    );
  };

  return (
    <div className="border border-width-2 border-gray-400 p-3">
      <h5 className="mb-3">
        From CSV (PetPoint Format){' '}
        <SimpleLink href={`/ExampleCSV.csv`} targetBlank={true}>
          Download Example CSV
        </SimpleLink>
      </h5>

      {showAddForm()}
    </div>
  );
};

export default PetpointImportCard;
