import { Form as FormikForm, Field } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Button, SimpleLink } from '../../../components';
import { InputField, LabelField } from '../../../components/formik';
import { Alert } from 'reactstrap';

export default function RescueSignUpForm({
  errors,
  touched,
  handleChange,
  isValid,
  isSubmitting,
  values,
  status,
  setFieldValue,
  isRehoming,
}) {
  return (
    <FormikForm>
      <fieldset disabled={isSubmitting}>
        {!isRehoming && (
          <InputField
            label="Rescue or Shelter Name"
            errors={errors}
            name="rescue"
            required
            onChange={handleChange}
            placeholder="Enter your rescue/shelter name"
            touched={touched}
            type="text"
          />
        )}
        {!isRehoming && (
          <InputField
            label="Website"
            errors={errors}
            name="website"
            required
            onChange={handleChange}
            placeholder="Enter your rescue or shelter's website"
            touched={touched}
            type="text"
          />
        )}
        <InputField
          label="First Name"
          errors={errors}
          name="firstName"
          required
          onChange={handleChange}
          placeholder="Enter your first name"
          touched={touched}
          type="text"
        />
        <InputField
          label="Last Name"
          errors={errors}
          name="lastName"
          required
          onChange={handleChange}
          placeholder="Enter your last name"
          touched={touched}
          type="text"
        />
        <InputField
          label="Address Line 1"
          errors={errors}
          name="address1"
          required
          onChange={handleChange}
          placeholder="Enter address line one"
          touched={touched}
          type="text"
        />
        <InputField
          label="Address Line 2"
          errors={errors}
          name="address2"
          onChange={handleChange}
          placeholder="Enter address line two"
          touched={touched}
          type="text"
        />
        <InputField
          label="City"
          errors={errors}
          name="city"
          required
          onChange={handleChange}
          placeholder="Enter your primary city"
          touched={touched}
          type="text"
        />
        <InputField
          label="State / Province / Region"
          errors={errors}
          name="state"
          required
          onChange={handleChange}
          placeholder="Enter your state (2 characters)"
          maxLength="2"
          touched={touched}
          type="text"
        />
        <InputField
          label="Postal Code/ZIP"
          errors={errors}
          name="zipCode"
          required
          onChange={handleChange}
          placeholder="Enter your primary Postal Code/ZIP"
          touched={touched}
          type="text"
        />
        <InputField
          label="Phone Number"
          errors={errors}
          name="phoneNumber"
          required
          onChange={handleChange}
          placeholder="Enter your phone number"
          touched={touched}
          type="text"
        />
        <InputField
          label="Email Address"
          errors={errors}
          name="email"
          required
          onChange={handleChange}
          placeholder="Enter your email address"
          touched={touched}
          type="text"
        />
        <InputField
          label="Password (Must be at least 6 characters long)"
          errors={errors}
          name="password"
          required
          onChange={handleChange}
          placeholder="Choose a password"
          touched={touched}
          type="password"
        />
        {!isRehoming && (
          <div>
            <h6>How did you hear about us?</h6>
            <Field
              component="textarea"
              className="form-control mb-3"
              label="Notes"
              errors={errors}
              name="notes"
              onChange={handleChange}
              placeholder="We love hearing how you found us, so please let us know!"
              touched={touched}
              type="textarea"
            />
          </div>
        )}
        {isRehoming && (
          <div>
            <h6>Why do you need to have your pet fostered?</h6>
            <Field
              component="textarea"
              className="form-control mb-3"
              label="Notes"
              errors={errors}
              name="notes"
              onChange={handleChange}
              placeholder="My temporary residence does not allow pets, and I need help until we can move into a pet-friendly place."
              touched={touched}
              type="textarea"
            />
          </div>
        )}
        {isRehoming && (
          <div>
            <LabelField
              labelName="Are you able to transport the pet to new home?"
              htmlFor="canTransport"
              fieldName="canTransport"
              required
            />

            <Form.Group>
              <Form.Check
                inline
                type="radio"
                name="canTransport"
                label="Yes"
                onChange={() => {
                  setFieldValue('canTransport', true);
                }}
                checked={values.canTransport}
                id="canTransportYes"
              />

              <Form.Check
                inline
                type="radio"
                name="canTransport"
                label="No"
                onChange={() => {
                  setFieldValue('canTransport', false);
                }}
                checked={!values.canTransport}
                id="canTransportNo"
              />
            </Form.Group>

            <LabelField
              labelName="Are you looking to find homes for multiple pets together?"
              htmlFor="forMultiple"
              fieldName="forMultiple"
              required
            />

            <Form.Group>
              <Form.Check
                inline
                type="radio"
                name="forMultiple"
                label="Yes"
                onChange={() => {
                  setFieldValue('forMultiple', true);
                }}
                checked={values.forMultiple}
                id="forMultipleYes"
              />

              <Form.Check
                inline
                type="radio"
                name="forMultiple"
                label="No"
                onChange={() => {
                  setFieldValue('forMultiple', false);
                }}
                checked={!values.forMultiple}
                id="forMultiple"
              />
            </Form.Group>
          </div>
        )}
        {!isRehoming && (
          <InputField
            label="Enter an estimate of your rescue/shelter yearly animal intake"
            errors={errors}
            name="intake"
            required
            className="w-100"
            onChange={handleChange}
            placeholder="Enter your approximate yearly intake"
            touched={touched}
            type="number"
          />
        )}

        {status &&
          (status === 'EmailAlreadyFound' || status === 'UserAlreadyFound') && (
            <Alert color="warning">
              Looks like you already have an account with us. Please login{' '}
              <SimpleLink href={`/login`} targetBlank={true}>
                here
              </SimpleLink>
            </Alert>
          )}

        <Button
          loading={isSubmitting}
          color="primary"
          disabled={!isValid}
          type="submit"
        >
          Sign up
        </Button>
      </fieldset>
    </FormikForm>
  );
}
