import React, { useState } from 'react';
import Loader from './Loader';
import { Button, Form } from 'react-bootstrap';
import { useMountEffect } from '../hooks';
import firebase from '../Firestore';

const PublicSettings = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [showRescueToPublic, setShowRescueToPublic] = useState(true);

  useMountEffect(() => {
    const db = firebase.firestore();
    const { rescueid } = props.match.params;

    db.collection('rescues')
      .doc(rescueid)
      .collection('settings')
      .where('name', '==', 'publicSettings')
      .get()
      .then((data) => {
        if (!data.empty) {
          const { showRescueToPublic } = data.docs[0].data();
          console.log(showRescueToPublic);
          setShowRescueToPublic(showRescueToPublic);
        }

        setLoading(false);
      });
  });

  function updateShowLink(e) {
    setShowRescueToPublic(!showRescueToPublic);
  }

  function submit() {
    setLoading(true);

    const db = firebase.firestore();
    const { rescueid } = props.match.params;

    db.collection('rescues')
      .doc(rescueid)
      .collection('settings')
      .where('name', '==', 'publicSettings')
      .get()
      .then((data) => {
        if (!data.empty) {
          data.docs[0].ref.update({ showRescueToPublic }).then(() => {
            setLoading(false);
            setSuccess(true);
          });
        } else {
          db.collection('rescues')
            .doc(rescueid)
            .collection('settings')
            .add({ name: 'publicSettings', showRescueToPublic })
            .then(() => {
              setLoading(false);
              setSuccess(true);
            });
        }
      });
  }

  const showAddForm = () => {
    if (loading) {
      return <Loader />;
    }

    if (success) {
      return <div>Success!</div>;
    }

    return (
      <div>
        <Form className="mb-3">
          <Form.Check
            inline
            type="checkbox"
            name="showRescueToPublic"
            label="Show rescue to public to get new foster sign ups."
            onChange={updateShowLink}
            checked={showRescueToPublic}
            id="showRescueToPublic"
          />
        </Form>

        <Button className="primary" onClick={submit}>
          Save
        </Button>
      </div>
    );
  };

  return (
    <div className="border border-width-2 border-gray-400 p-3 my-3">
      <h5 className="mb-3">Public Settings</h5>

      {showAddForm()}
    </div>
  );
};

export default PublicSettings;
