import { Formik } from 'formik';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import AddFosterForm from './AddFosterForm';
import { AddFosterFormSchema } from './AddFosterFormSchema';
import firebase from '../../Firestore';
import { useMountEffect } from '../../hooks';
import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard';
import { WEBSITE_URL } from '../../constants/urls.constant';
import {
  CONDITIONSARRAY,
  CONDITIONSLIST,
} from '../../constants/conditions.constant';

const AddFoster = (props) => {
  const {
    match: { params },
  } = props;

  const [
    redirectToAdditionalQuestions,
    setRedirectToAdditionalQuestions,
  ] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [redirectToFosters, setRedirectToFosters] = useState(false);
  const [rescueName, setRescueName] = useState('');
  const [fosterId, setFosterId] = useState('');
  const [alreadyAFoster, setAlreadyAFoster] = useState(false);
  const [rescueNotFound, setRescueNotFound] = useState(false);
  const [animal, setAnimal] = useState(null);
  const [loading, setLoading] = useState(true);

  const { rescueid } = props.match.params;

  const showMessage = () => {
    if (localStorage.getItem('user')) {
      return (
        <CopyToClipboard
          description="Want a potential foster parent to sign themselves up? Copy this link"
          value={`${WEBSITE_URL}/${rescueid}/fosterSignUp`}
        />
      );
    } else if (animal) {
      return <h6>Send a message to the pet owner/caretaker</h6>;
    }

    const animalId = new URL(window.location.href).searchParams.get(
      'fosterAnimal',
    );

    if (animalId) {
      return (
        <div className="mb-3">
          Already a foster with {rescueName || 'us'}? Click{' '}
          <button
            className="btn btn-link m-0 p-0"
            onClick={() => setAlreadyAFoster(true)}
          >
            here
          </button>
        </div>
      );
    }
  };

  const showRedirect = () => {
    if (redirectToFosters) {
      return <Redirect to={`/rescue/${rescueid}/fosters`} />;
    }

    if (redirectToAdditionalQuestions) {
      const animalId = new URL(window.location.href).searchParams.get(
        'fosterAnimal',
      );

      if (animalId) {
        return (
          <Redirect
            to={`/${rescueid}/additionalFosterQuestions/${fosterId}?fosterAnimal=${animalId}`}
          />
        );
      }

      return (
        <Redirect to={`/${rescueid}/additionalFosterQuestions/${fosterId}`} />
      );
    }
  };

  useMountEffect(() => {
    const animalId = new URL(window.location.href).searchParams.get(
      'fosterAnimal',
    );
    const db = firebase.firestore();

    db.collection('rescues')
      .doc(rescueid)
      .get()
      .then((data) => {
        if (data.exists && data.get('name')) {
          setRescueName(data.get('name'));
        }

        if (!data.exists) {
          setRescueNotFound(true);
        }

        if (animalId) {
          db.collection('publicAnimals')
            .doc(animalId)
            .get()
            .then((data) => {
              setAnimal(data.data());
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
  });

  if (showThankYou) {
    return (
      <div>
        <div>Thank you for signing up to foster with us!</div>
        <CopyToClipboard
          description="Want your friend to be a foster parent too? Copy and send them this link."
          value={`${WEBSITE_URL}/4/fosterSignUp`}
        />
      </div>
    );
  }

  if (rescueNotFound) {
    return (
      <Container className="py-3 py-sm-5">
        Rescue was not found, please check to make sure your url is correct
      </Container>
    );
  }

  if (alreadyAFoster) {
    const animalId = new URL(window.location.href).searchParams.get(
      'fosterAnimal',
    );

    return (
      <Redirect to={`/${rescueid}/alreadyAFoster?fosterAnimal=${animalId}`} />
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="py-3 py-sm-5">
      <Row>
        <Col md="8" className="mx-auto">
          <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
            <h5>
              {animal
                ? `Ask about ${animal.name}`
                : `${rescueName} Foster Signup`}
            </h5>
            {showMessage()}
            <br />
            <Formik
              onSubmit={(values, actions) => {
                const db = firebase.firestore();

                let negativeConditionIds = JSON.parse(
                  JSON.stringify(CONDITIONSLIST),
                );

                const positiveConditionIds = values.conditions.map(
                  (condition) => {
                    return condition.value;
                  },
                );

                positiveConditionIds.forEach((condition) => {
                  negativeConditionIds.splice(
                    negativeConditionIds.indexOf(condition),
                    1,
                  );
                });

                const formattedPhoneNumber = values.phoneNumber.replace(
                  /\D/g,
                  '',
                );

                db.collection(`rescues/${params.rescueid}/fosters`)
                  .add({
                    acceptedSpecies: values.acceptedSpecies,
                    address1: values.address1,
                    address2: values.address2,
                    city: values.city,
                    conditions: negativeConditionIds,
                    emailAddress: values.emailAddress,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    maxAnimals: values.maxAnimals,
                    phoneNumber: formattedPhoneNumber,
                    communicationPreference: values.communicationPreference,
                    state: values.state,
                    zip: values.zip,
                    notes: values.notes,
                    createdBy: localStorage.getItem('user')
                      ? JSON.parse(localStorage.getItem('user')).uid
                      : null,
                    status: localStorage.getItem('user')
                      ? 'available'
                      : 'unverified',
                  })
                  .then((data) => {
                    const animalId = new URL(
                      window.location.href,
                    ).searchParams.get('fosterAnimal');

                    setFosterId(data.id);

                    if (animalId) {
                      db.collection(
                        `rescues/${params.rescueid}/animalFosterApplication`,
                      ).add({
                        animalId,
                        fosterId: data.id,
                        status: 'pending',
                      });
                    }

                    db.collection('rescues')
                      .doc(params.rescueid)
                      .collection('additionalFosterQuestions')
                      .get()
                      .then((data) => {
                        if (data.docs.length > 0) {
                          setRedirectToAdditionalQuestions(true);
                        } else if (localStorage.getItem('user')) {
                          setRedirectToFosters(true);
                        } else {
                          setShowThankYou(true);
                        }
                      });
                  });
                actions.setSubmitting(false);
              }}
              initialValues={{
                acceptedSpecies: ['dog'],
                address1: '',
                address2: '',
                city: '',
                conditions: CONDITIONSARRAY,
                emailAddress: '',
                firstName: '',
                lastName: '',
                maxAnimals: 1,
                phoneNumber: '',
                state: '',
                zip: '',
                notes: '',
                rescueId: params.rescueid,
                rescueName: rescueName,
                communicationPreference: 'text',
                animal: animal,
              }}
              validationSchema={AddFosterFormSchema}
              component={AddFosterForm}
            />
            {showRedirect()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AddFoster;
