// Default to production
const IS_INTEGRATION = process.env.REACT_APP_DEV;
const IS_DEV = true; // if you want to point to localhost

let ADMIN_URL = 'https://us-central1-foster-app-7dcfe.cloudfunctions.net/admin';
let API_URL = 'https://us-central1-foster-app-7dcfe.cloudfunctions.net/api';
let COMM_URL = 'https://us-central1-foster-app-7dcfe.cloudfunctions.net/comm';
let DATABASE_URL = 'https://foster-app-7dcfe.firebaseio.com';
let PUBLIC_API_URL =
  'https://us-central1-foster-app-7dcfe.cloudfunctions.net/publicApi';
let RESCUE_ADMIN_URL =
  'https://us-central1-foster-app-7dcfe.cloudfunctions.net/rescueAdmin';
let WEBSITE_URL = 'https://app.911fosterpets.com';

// if (IS_INTEGRATION && IS_DEV) {
//   ADMIN_URL = 'http://localhost:5000/foster-app-dev/us-central1/admin';
//   API_URL = 'http://localhost:5000/foster-app-dev/us-central1/api';
//   COMM_URL = 'http://localhost:5000/foster-app-dev/us-central1/comm';
//   DATABASE_URL = 'https://foster-app-dev.firebaseio.com';
//   PUBLIC_API_URL = 'http://localhost:5000/foster-app-dev/us-central1/publicApi';
//   RESCUE_ADMIN_URL =
//     'http://localhost:5000/foster-app-dev/us-central1/rescueAdmin';
//   WEBSITE_URL = 'https://foster-app-dev.web.app/';
// } else if (IS_INTEGRATION) {
//   ADMIN_URL = 'https://us-central1-foster-app-dev.cloudfunctions.net/admin';
//   API_URL = 'https://us-central1-foster-app-dev.cloudfunctions.net/api';
//   COMM_URL = 'https://us-central1-foster-app-dev.cloudfunctions.net/comm';
//   DATABASE_URL = 'https://foster-app-dev.firebaseio.com';
//   PUBLIC_API_URL =
//     'https://us-central1-foster-app-dev.cloudfunctions.net/publicApi';
//   RESCUE_ADMIN_URL =
//     'https://us-central1-foster-app-dev.cloudfunctions.net/rescueAdmin';
//   WEBSITE_URL = 'https://foster-app-dev.web.app/';
// }

export {
  ADMIN_URL,
  API_URL,
  COMM_URL,
  DATABASE_URL,
  PUBLIC_API_URL,
  RESCUE_ADMIN_URL,
  WEBSITE_URL,
};
