import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import firebase from '../../Firestore';
import AddFosterParentDropdown from '../AddFosterParentDropdown/AddFosterParentDropdown';

const FosterParentList = (props) => {
  async function removeFoster(foster) {
    const db = firebase.firestore();

    db.collection('rescues')
      .doc(props.rescueId)
      .collection('animalFosterApplication')
      .where('animalId', '==', props.animalId)
      .where('fosterId', '==', foster.fosterId)
      .get()
      .then((data) => {
        data.docs.forEach((animalFosterApplicationDoc) => {
          animalFosterApplicationDoc.ref.delete();
        });
      });

    db.collection('rescues')
      .doc(props.rescueId)
      .collection('animalFosterRelationships')
      .where('animalId', '==', props.animalId)
      .where('fosterId', '==', foster.fosterId)
      .get()
      .then((data) => {
        data.docs.forEach((animalFosterRelationShipDoc) => {
          animalFosterRelationShipDoc.ref.delete();
        });
      });

    const fosterParents = props.fosterParents || [];
    const fosterIndex = fosterParents.indexOf(foster);
    fosterParents.splice(fosterIndex, 1);

    db.collection('rescues')
      .doc(props.rescueId)
      .collection('animals')
      .doc(props.animalId)
      .update({
        fosterParents,
        status: 'unavailable',
        estimatedReturnDate: null,
      });

    const fosterParentDoc = await db
      .collection('rescues')
      .doc(props.rescueId)
      .collection('fosters')
      .doc(foster.fosterId)
      .get();

    const fosterAnimals = fosterParentDoc.get('fosterAnimals') || [];
    const fosterAnimalInFoster = fosterAnimals.find((fosterAnimal) => {
      return fosterAnimal.name === props.animalName;
    });

    const animalIndex = fosterAnimals.indexOf(fosterAnimalInFoster);
    fosterAnimals.splice(animalIndex, 1);

    db.collection('rescues')
      .doc(props.rescueId)
      .collection('fosters')
      .doc(foster.fosterId)
      .update({
        fosterAnimals,
        status: fosterAnimals.length > 1 ? 'fostering' : 'available',
      });

    props.afterRemove();
  }

  const rows = props.fosterParents.map((fosterParent) => {
    return (
      <div key={fosterParent.fosterId} className="mb-1">
        <Row>
          <Col sm="4">
            <NavLink
              to={`/rescue/${props.rescueId}/fosters/${fosterParent.fosterId}`}
            >
              {fosterParent.name}
            </NavLink>
          </Col>

          <Col>
            <Button
              onClick={() => removeFoster(fosterParent)}
              variant="danger"
              size="sm"
              className="ml-3"
            >
              Remove Foster
            </Button>
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <div>
      {rows}
      <div className="mt-2">
        <AddFosterParentDropdown
          rescueId={props.rescueId}
          animalId={props.animalId}
          onClickAdd={(animalId, fosterId) =>
            props.onClickAdd(animalId, fosterId)
          }
        />
      </div>
    </div>
  );
};

export default FosterParentList;
