import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SocialIcons({ className }) {
  return (
    <div
      className={`${className} d-flex justify-content-between align-items-center px-5 pt-5 pb-4 py-md-5 pl-md-5 pr-md-0`}
      style={{ height: '100%' }}
    >
      <SocialLink
        url="https://www.instagram.com/911fosterpets/"
        icon="instagram"
      />
      <SocialLink
        url="https://www.facebook.com/911fosterpets/"
        icon="facebook-f"
      />
      <SocialLink url="https://twitter.com/911fosterpets" icon="twitter" />
      <SocialLink
        url="https://www.youtube.com/channel/UCz7ggF0VMffttOA1Xto_7pw/videos"
        icon="youtube"
      />
    </div>
  );
}

const SocialLink = ({ icon, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={['fab', icon]} size="2x" />
  </a>
);
