import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import AddAnimalForm from '../AddAnimalPage/AddAnimalForm';
import { AddAnimalFormSchema } from '../AddAnimalPage/AddAnimalFormSchema';
import firebase from '../../Firestore';
import Loader from '../../components/Loader';

const EditAnimal = (props) => {
  const { rescueid } = props.match.params;
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(true);
  const [animalDoc, setAnimalDoc] = useState(false);

  useMountEffect(() => {
    const db = firebase.firestore();

    const {
      match: { params },
    } = props;

    const animalDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid);

    setAnimalDoc(animalDoc);

    animalDoc.get().then((data) => {
      setInitialValues({
        ...data.data(),
        editting: true,
        status: data.get('status') || 'unavailable',
      });
      setLoading(false);
    });
  });

  if (redirect) {
    return <Redirect to={`/rescue/${rescueid}/animals`} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="py-3 py-sm-5">
      <Formik
        onSubmit={(values, actions) => {
          const { image_url } = values;

          delete values.editting;

          const normalizedValues = {
            ...values,
            lastUpdated: new Date(),
            imageUrl: image_url || null,
          };

          animalDoc.update(normalizedValues).then((data) => {
            if (data) {
              actions.setSubmitting(false);
            }

            setRedirect(true);
          });
        }}
        initialValues={initialValues}
        validationSchema={AddAnimalFormSchema}
        component={AddAnimalForm}
      />
    </Container>
  );
};

export default EditAnimal;
