import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { FosterSignUpForm, useFosterSignUp } from '.';
import { PageHeaderHalfBackground } from '../../../components/pages';
import { FormContainer } from '../components';

export default function AddFoster() {
  const params = useParams();

  const { initialValues, handleSubmit, validationSchema } = useFosterSignUp(
    params.rescueid,
  );

  const [showThankYou, setShowThankYou] = useState(
    localStorage.getItem('hasCompletedCovid19Panel') === 'true',
  );

  useEffect(() => {
    if (localStorage.getItem('hasCompletedCovid19Panel') === 'true') {
      setShowThankYou(true);
    }

    const cameFromOptin = new URL(window.location.href).searchParams.get(
      'cameFromOptin',
    );

    if (cameFromOptin) {
      setShowThankYou(true);
    }
  }, [setShowThankYou]);

  return (
    <div>
      <PageHeaderHalfBackground
        title="Become a Foster"
        subtitle="
          Enter your information below to be notified as soon as an animal is
          ready for fostering"
      >
        <FormContainer>
          {showThankYou && <ThankYou />}
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            component={FosterSignUpForm}
          />
        </FormContainer>
      </PageHeaderHalfBackground>
    </div>
  );
}

const ThankYou = () => {
  return (
    <Alert color="success">
      Thank you for signing up to help foster! To be better matched to a foster
      pet for rescues and shelters, fill out this form, and we will notify you
      as soon as one is a good fit.
    </Alert>
  );
};
