import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

function GATracker(props: P): React.ReactElement {
  useEffect(() => {
    const page = props.location.pathname;

    ReactGA.set({ page, ...props.options });
    ReactGA.pageview(page);
  }, [props.location.pathname, props.options]);

  return null;
}

export default GATracker;
