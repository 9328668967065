import React from 'react';
import { Alert, Row } from 'react-bootstrap';
import styled from 'styled-components';

import './ErrorPage.scss';

const ContentPanel = styled.div`
  height: 100%;
`;
interface Props {
  error: Error;
}

function ErrorPage({ error }: Props): React.ReactElement {
  return (
    <ContentPanel className="d-flex flex-column justify-content-center">
      <Row className="justify-content-center">
        <strong>Oops...Houston, we've had a problem</strong>
      </Row>

      <Row className="justify-content-center mt-3">
        We're terrible sorry. We seem to have run into a problem.
      </Row>

      <Row className="justify-content-center mt-3">
        This is never good, and we want to do our best to fix it.
      </Row>

      <Row className="justify-content-center mt-3">
        If you have a second, please try:
      </Row>

      <Row className="justify-content-center mt-3">1) Reloading the page</Row>

      <Row className="justify-content-center mt-3">
        2) Logging out and back in again
      </Row>

      <Row className="justify-content-center mt-3">
        If all else fails, give us a call, and we will put our best technicians
        on it
      </Row>

      <Row className="preserve-space justify-content-center mt-3">
        Phone:{' '}
        <a href="tel:4806053652" className="">
          (480) 605-3652
        </a>
      </Row>

      <Row className="preserve-space justify-content-center mt-3">
        Email:{' '}
        <a href="mailto:outreach@911FosterPets.com" className="">
          outreach@911FosterPets.com
        </a>
      </Row>

      <Alert className="mx-5 mt-3" variant={'dark'}>
        <Alert.Heading>{error.name}</Alert.Heading>

        <p>{error.message}</p>

        <hr />

        <p className="mb-0">{error.stack}</p>
      </Alert>
    </ContentPanel>
  );
}

export default ErrorPage;
