import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

const RescueCard = (props) => {
  const rescue = props.rescue || {};

  return (
    <Container className="my-3 my-md-5 py-3 py-md-5">
      <Row>
        <Col md="12" className="mx-auto">
          <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
            <div>
              <div className="mb-3">
                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Name
                  </Form.Label>
                  <br />
                  {rescue.firstName} {rescue.lastName}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Email Address
                  </Form.Label>
                  <br />
                  {rescue.email || ''}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Can Transport?
                  </Form.Label>
                  <br />
                  {(rescue.canTransport && rescue.canTransport.toString()) ||
                    ''}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Phone
                  </Form.Label>
                  <br />
                  {rescue.phoneNumber || ''}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Address
                  </Form.Label>
                  <br />{' '}
                  {' ' + rescue.address2
                    ? rescue.address1 + '\n   ' + rescue.address2
                    : rescue.address1}
                  {rescue.city} {rescue.state}
                  {' ' + rescue.zipCode}
                </Form.Group>

                <hr />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export { RescueCard };
