import authService from './auth.service';

class RequestService {
  async fetch(url, init = {}) {
    return fetch(url, {
      ...init,
      headers: {
        Authorization: authService.idToken,
        'X-IS-RESCUE-ID': authService.user && authService.user.rescue,
        ...init.headers,
      },
    });
  }
}

const requestService = new RequestService();

export { requestService };
