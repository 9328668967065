import React, { useState } from 'react';
import Loader from './Loader';
import { Button, Form } from 'react-bootstrap';
import { useMountEffect } from '../hooks';
import firebase from '../Firestore';

const CheckInsSettings = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(
    'Please let us know how #AnimalName# is doing by clicking here',
  );
  const [showLink, setShowLink] = useState(true);

  useMountEffect(() => {
    const db = firebase.firestore();
    const { rescueid } = props.match.params;

    db.collection('rescues')
      .doc(rescueid)
      .collection('settings')
      .where('name', '==', 'animalCheckIns')
      .get()
      .then((data) => {
        if (!data.empty) {
          const { message, showLink } = data.docs[0].data();
          setMessage(message);
          setShowLink(showLink);
        }

        setLoading(false);
      });
  });

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  function updateShowLink(e) {
    setShowLink(!showLink);
  }

  function submit() {
    setLoading(true);

    const db = firebase.firestore();
    const { rescueid } = props.match.params;

    db.collection('rescues')
      .doc(rescueid)
      .collection('settings')
      .where('name', '==', 'animalCheckIns')
      .get()
      .then((data) => {
        if (!data.empty) {
          data.docs[0].ref.update({ message, showLink }).then(() => {
            setLoading(false);
            setSuccess(true);
          });
        } else {
          db.collection('rescues')
            .doc(rescueid)
            .collection('settings')
            .add({ name: 'animalCheckIns', message, showLink })
            .then(() => {
              setLoading(false);
              setSuccess(true);
            });
        }
      });
  }

  const showAddForm = () => {
    if (loading) {
      return <Loader />;
    }

    if (success) {
      return <div>Success!</div>;
    }

    return (
      <div>
        Hi #PersonName#,
        <Form className="mb-3">
          <textarea
            className="form-control"
            placeholder="Please let us know how #AnimalName# is doing by clicking here"
            value={message}
            focus="true"
            onChange={updateMessage}
            rows="3"
            id="check-in"
            autoFocus
          />

          <div className="mb-2">
            <small>
              ‘#PersonName#’ and ‘#AnimalName#’ will be automatically customized
              to the respective foster parent and foster pet when submitted.
            </small>
          </div>

          <Form.Check
            inline
            type="checkbox"
            name="showLink"
            label="Show check-in link (this is a link a foster parent can use to follow and respond to your check-in message(s) through the website. When a check-in is submitted we will notify you through email. If you’d prefer to receive responses directly, include an email or phone number for foster parents to use in your default message.)"
            onChange={updateShowLink}
            checked={showLink}
            id="showLink"
          />
        </Form>
        <Button className="primary" onClick={submit}>
          Save
        </Button>
      </div>
    );
  };

  return (
    <div className="border border-width-2 border-gray-400 p-3">
      <h5 className="mb-3">Check-in Messages</h5>
      <h6 className="mb-3">
        Set a default check-in message to send your active fosters here.
        Check-in messages can be triggered from an animal's page when adding a
        foster.
      </h6>

      {showAddForm()}
    </div>
  );
};

export default CheckInsSettings;
