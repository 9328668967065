import React, { PureComponent } from 'react';
import { Accordion, Card, Col } from 'react-bootstrap';
import { PUBLIC_API_URL } from '../../constants/urls.constant';
import authService from '../../services/auth.service';
import Loader from '../../components/Loader';

import './AdminStatisticsPage.css';

class AdminPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statistics: {},
      loading: true,
    };

    this.fetchStatistics = this.fetchStatistics.bind(this);
  }

  componentDidMount() {
    this.fetchStatistics();
  }

  async fetchStatistics() {
    const statistics = await fetch(
      `${PUBLIC_API_URL}/v1/application-statistics`,
      {
        headers: new Headers({
          Authorization: authService.idToken,
        }),
      },
    ).then((res) => res.json());

    console.log(statistics);

    this.setState({ statistics, loading: false });
  }

  renderUsers() {
    const { statistics } = this.state;

    return (
      <div>
        {this.state.loading ? (
          <Loader />
        ) : (
          <Accordion>
            {Object.keys(statistics).map((statKey) => (
              <Card key={statKey}>
                <Card.Header>
                  <Col xs="auto">
                    <Card.Title>{statKey}</Card.Title>
                  </Col>

                  <Col xs="auto">{statistics[statKey]}</Col>
                </Card.Header>
              </Card>
            ))}
          </Accordion>
        )}
      </div>
    );
  }

  render() {
    return <div className="mx-5 mb-5">{this.renderUsers()}</div>;
  }
}

export default AdminPage;
