import React from 'react';
import { Form } from 'react-bootstrap';
import { Form as FormikForm, Field } from 'formik';
import { InputField, LabelField } from '../../../components/formik';
import Select from 'react-select';
import { SimpleLink, Button } from '../../../components';
import { CONDITIONSARRAY } from '../../../constants/conditions.constant';

export default function FosterSignUpForm({ ...formikProps }) {
  const {
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    setFieldTouched,
  } = formikProps;

  const options = CONDITIONSARRAY;

  return (
    <FormikForm>
      <fieldset disabled="">
        <InputField
          label="First Name"
          errors={errors}
          name="firstName"
          required
          onChange={handleChange}
          placeholder="Enter first name"
          touched={touched}
          type="text"
        />
        <InputField
          label="Last Name"
          errors={errors}
          name="lastName"
          required
          onChange={handleChange}
          placeholder="Enter last name"
          touched={touched}
          type="text"
        />
        <InputField
          label="Phone Number"
          errors={errors}
          name="phoneNumber"
          required
          onChange={handleChange}
          placeholder="Enter phone number"
          touched={touched}
          type="text"
        />
        <InputField
          label="Email Address"
          errors={errors}
          name="emailAddress"
          required
          onChange={handleChange}
          placeholder="Enter email address"
          touched={touched}
          type="email"
        />
        {values.animal ? (
          <div />
        ) : (
          <div>
            <InputField
              label="Address Line 1"
              errors={errors}
              name="address1"
              required
              onChange={handleChange}
              placeholder="Enter address line one"
              touched={touched}
              type="text"
            />
            <InputField
              label="Address Line 2"
              errors={errors}
              name="address2"
              onChange={handleChange}
              placeholder="Enter address line two"
              touched={touched}
              type="text"
            />
            <InputField
              label="City"
              errors={errors}
              name="city"
              required
              onChange={handleChange}
              placeholder="Enter address city"
              touched={touched}
              type="text"
            />
            <InputField
              label="State"
              errors={errors}
              name="state"
              required
              onChange={handleChange}
              placeholder="Enter address state"
              touched={touched}
              type="text"
            />
          </div>
        )}
        <InputField
          label="ZIP Code"
          errors={errors}
          name="zip"
          required
          onChange={handleChange}
          placeholder="Enter address ZIP code"
          touched={touched}
          type="text"
        />
        <InputField
          label="Max Animals Willing to Foster"
          errors={errors}
          name="maxAnimals"
          required
          onChange={handleChange}
          placeholder="Max animals willing to foster"
          touched={touched}
          type="number"
        />
        <div className="mb-3">
          <LabelField
            labelName="Communication Preference"
            htmlFor="pref"
            fieldName="communicationPreference"
            required
          />

          <div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="communicationPreference"
                value="text"
                onChange={handleChange}
                checked={values.communicationPreference === 'text'}
              />
              <label className="form-check-label">Text</label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="communicationPreference"
                value="email"
                onChange={handleChange}
                checked={values.communicationPreference === 'email'}
              />
              <label className="form-check-label">Email</label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="communicationPreference"
                value="textAndEmail"
                onChange={handleChange}
                checked={values.communicationPreference === 'textAndEmail'}
              />
              <label className="form-check-label">Text and Email</label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="communicationPreference"
                value="phoneCall"
                onChange={handleChange}
                checked={values.communicationPreference === 'phoneCall'}
              />
              <label className="form-check-label">Phone Call</label>
            </div>
          </div>
        </div>
        <LabelField
          labelName="Species willing to foster"
          htmlFor="pref"
          fieldName="acceptedSpecies"
          required
        />
        <Form.Group>
          <Form.Check
            inline
            type="checkbox"
            name="acceptedSpecies"
            label="Dog"
            onChange={() => {
              if (values.acceptedSpecies.includes('dog')) {
                values.acceptedSpecies.splice(
                  values.acceptedSpecies.indexOf('dog'),
                  1,
                );
              } else {
                values.acceptedSpecies.push('dog');
              }
              setFieldTouched('acceptedSpecies', true);
            }}
            checked={values.acceptedSpecies.includes('dog')}
            id="speciesCat"
          />
          <Form.Check
            inline
            type="checkbox"
            name="acceptedSpecies"
            label="Cat"
            onChange={() => {
              if (values.acceptedSpecies.includes('cat')) {
                values.acceptedSpecies.splice(
                  values.acceptedSpecies.indexOf('cat'),
                  1,
                );
              } else {
                values.acceptedSpecies.push('cat');
              }
              setFieldTouched('acceptedSpecies', true);
            }}
            checked={values.acceptedSpecies.includes('cat')}
            id="speciesCat"
          />

          <Form.Check
            inline
            type="checkbox"
            name="acceptedSpecies"
            label="Other"
            onChange={() => {
              if (values.acceptedSpecies.includes('other')) {
                values.acceptedSpecies.splice(
                  values.acceptedSpecies.indexOf('other'),
                  1,
                );
              } else {
                values.acceptedSpecies.push('other');
              }
              setFieldTouched('acceptedSpecies', true);
            }}
            checked={values.acceptedSpecies.includes('other')}
            id="speciesOther"
          />
        </Form.Group>
        <div>
          <LabelField
            labelName="How long are you willing to foster?"
            htmlFor="pref"
            fieldName="lengthOfFostering"
          />
          <Form.Group>
            <Form.Check
              inline
              type="checkbox"
              name="lengthOfFostering"
              label="2 weeks max"
              onChange={() => {
                if (values.lengthOfFostering.includes('2 weeks max')) {
                  values.lengthOfFostering.splice(
                    values.lengthOfFostering.indexOf('2 weeks max'),
                    1,
                  );
                } else {
                  values.lengthOfFostering.push('2 weeks max');
                }
                setFieldTouched('lengthOfFostering', true);
              }}
              checked={values.lengthOfFostering.includes('2 weeks max')}
            />
            <Form.Check
              inline
              type="checkbox"
              name="lengthOfFostering"
              label="2-4 weeks"
              onChange={() => {
                if (values.lengthOfFostering.includes('2-4 weeks')) {
                  values.lengthOfFostering.splice(
                    values.lengthOfFostering.indexOf('2-4 weeks'),
                    1,
                  );
                } else {
                  values.lengthOfFostering.push('2-4 weeks');
                }
                setFieldTouched('lengthOfFostering', true);
              }}
              checked={values.lengthOfFostering.includes('2-4 weeks')}
            />

            <Form.Check
              inline
              type="checkbox"
              name="lengthOfFostering"
              label="1-2 months"
              onChange={() => {
                if (values.lengthOfFostering.includes('1-2 months')) {
                  values.lengthOfFostering.splice(
                    values.lengthOfFostering.indexOf('1-2 months'),
                    1,
                  );
                } else {
                  values.lengthOfFostering.push('1-2 months');
                }
                setFieldTouched('lengthOfFostering', true);
              }}
              checked={values.lengthOfFostering.includes('1-2 months')}
            />

            <Form.Check
              inline
              type="checkbox"
              name="lengthOfFostering"
              label="Until the COVID-19 national emergency is over"
              onChange={() => {
                if (
                  values.lengthOfFostering.includes(
                    'Until the COVID-19 national emergency is over',
                  )
                ) {
                  values.lengthOfFostering.splice(
                    values.lengthOfFostering.indexOf(
                      'Until the COVID-19 national emergency is over',
                    ),
                    1,
                  );
                } else {
                  values.lengthOfFostering.push(
                    'Until the COVID-19 national emergency is over',
                  );
                }
                setFieldTouched('lengthOfFostering', true);
              }}
              checked={values.lengthOfFostering.includes(
                'Until the COVID-19 national emergency is over',
              )}
            />

            <Form.Check
              inline
              type="checkbox"
              name="lengthOfFostering"
              label="Long term fostering is an option (3+ months)"
              onChange={() => {
                if (
                  values.lengthOfFostering.includes(
                    'Long term fostering is an option (3+ months)',
                  )
                ) {
                  values.lengthOfFostering.splice(
                    values.lengthOfFostering.indexOf(
                      'Long term fostering is an option (3+ months)',
                    ),
                    1,
                  );
                } else {
                  values.lengthOfFostering.push(
                    'Long term fostering is an option (3+ months)',
                  );
                }
                setFieldTouched('lengthOfFostering', true);
              }}
              checked={values.lengthOfFostering.includes(
                'Long term fostering is an option (3+ months)',
              )}
            />
          </Form.Group>
        </div>
        <LabelField
          labelName="Do you have access to a vehicle?"
          htmlFor="pref"
          fieldName="lengthOfFostering"
          required
        />
        <Form.Group>
          <Form.Check
            inline
            type="radio"
            name="hasVehicle"
            label="Yes"
            onChange={() => {
              setFieldValue('hasVehicle', true);
            }}
            checked={values.hasVehicle}
          />

          <Form.Check
            inline
            type="radio"
            name="hasVehicle"
            label="No"
            onChange={() => {
              setFieldValue('hasVehicle', false);
            }}
            checked={!values.hasVehicle}
          />
        </Form.Group>
        {values.hasVehicle && (
          <div>
            <div className="mb-3">
              <LabelField
                labelName="How far are you willing to drive to transport a pet"
                htmlFor="pref"
                fieldName="transportDistance"
                required
              />

              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="transportDistance"
                    value="5"
                    onChange={handleChange}
                    checked={values.transportDistance === '5'}
                  />
                  <label className="form-check-label">Within 5 miles</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="transportDistance"
                    value="10"
                    onChange={handleChange}
                    checked={values.transportDistance === '10'}
                  />
                  <label className="form-check-label">Within 10 miles</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="transportDistance"
                    value="20"
                    onChange={handleChange}
                    checked={values.transportDistance === '20'}
                  />
                  <label className="form-check-label">Within 20 miles</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="transportDistance"
                    value="50"
                    onChange={handleChange}
                    checked={values.transportDistance === '50'}
                  />
                  <label className="form-check-label">Within 50 miles</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="transportDistance"
                    value="100"
                    onChange={handleChange}
                    checked={values.transportDistance === '100'}
                  />
                  <label className="form-check-label">Within 100 miles</label>
                </div>
              </div>
            </div>

            <LabelField
              labelName="Are you willing to transport pets for others?"
              htmlFor="pref"
              fieldName="willingToTransportForOthers"
              required
            />

            <Form.Group>
              <Form.Check
                inline
                type="radio"
                name="willingToTransportForOthers"
                label="Yes"
                onChange={() => {
                  setFieldValue('willingToTransportForOthers', true);
                }}
                checked={values.willingToTransportForOthers}
              />

              <Form.Check
                inline
                type="radio"
                name="willingToTransportForOthers"
                label="No"
                onChange={() => {
                  setFieldValue('willingToTransportForOthers', false);
                }}
                checked={!values.willingToTransportForOthers}
              />
            </Form.Group>
          </div>
        )}
        <h6>
          What else would you like rescues, shelters, or individuals looking to
          help by fostering know about you?
        </h6>
        <Field
          component="textarea"
          className="form-control mb-3"
          label="Notes"
          errors={errors}
          name="notes"
          onChange={handleChange}
          placeholder="Miscellaneous info goes here"
          touched={touched}
          type="textarea"
        />
        <h6>I'd be willing to foster an animal that...</h6>
        <Form.Group>
          <Select
            onChange={(values) =>
              setFieldValue('conditions', values ? values : [])
            }
            value={values.conditions}
            onBlur={() => setFieldTouched('conditions', true, true)}
            name="conditions"
            options={options}
            isMulti
            isSearchableHow
            long
            are
            you
            willing
            to
            foster
          />
          {errors.conditions && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {errors.conditions}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="mb-3">
          <small className="text-muted">
            By submitting, you confirm you are 18 years of age or older and
            agree to the terms outlined{' '}
            <SimpleLink
              href={`/${values.rescueId}/fosterAgreement`}
              targetBlank={true}
            >
              here
            </SimpleLink>
            , plus any additional fostering terms provided by the
            organization(s) or individual(s) you’re connected with. By signing
            up you are also opting in to email and text communications from
            911FosterPets/RescueFoster. We send messages when a rescue or
            shelter asks you to foster an animal for them or when a rescue or
            shelter needs a check-in. We only send text messages if you have
            selected a communication preference including text.
          </small>
        </div>
        <Button color="primary" type="submit">
          Next
        </Button>
      </fieldset>
    </FormikForm>
  );
}
