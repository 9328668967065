import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { catHome } from '../../../assets/images';
import { KickerText } from '../../../components/text';
import { Button } from '../../../components';

export default function RehomePanel() {
  const history = useHistory();

  return (
    <Container className="px-4 px-md-3">
      <div className="py-4 py-md-5 border-bottom">
        <Row className="d-flex align-items-center">
          {/* <Col xs="12" md="6" className="mb-4 mb-md-0">
            <img
              src={catHome}
              className="img-fluid"
              alt="Placeholder"
              width="100%"
            />
          </Col> */}
          <hr color="black" />
          <Col xs="12" md="4" className="mb-3 p-3">
            {/* <KickerText>Let Us Help You</KickerText> */}
            <h1>
              I need help caring for my pet
              {/* Find a temporary home <br className="d-none d-md-block" /> for a
              pet */}
            </h1>
            <p>
              We'll connect you to our fosters so that you can get a pet in a
              safe home right away.
            </p>
            <Button
              color="primary"
              size="lg"
              onClick={() => history.push('/temporary-rehome-signup')}
            >
              Find a Foster Home
            </Button>
          </Col>
          <hr />

          <Col xs="12" md="4" className="mb-3 p-3">
            {/* <KickerText>Our Vision</KickerText> */}
            <h1>I can foster a pet in need</h1>
            <p>
              Prospective foster parents can see at-risk pets that fit their
              lifestyle and sign up for new pet alerts.
            </p>
            <Button
              color="primary"
              size="lg"
              className="mr-3 mb-3 mb-md-0"
              onClick={() => history.push('/foster-signup')}
            >
              Become a Foster
            </Button>
            {/* <p>
          Shelters try to find homes for pets they receive, but many
          become overcrowded. They are forced to euthanize pets in their
          care simply because of space, timing, or age.
        </p> */}
          </Col>
          <hr />

          <Col xs="12" md="4" className="mb-3 p-3">
            <div>
              {/* <KickerText>Our Vision</KickerText> */}
              <h1>My shelter needs help managing fosters</h1>
              <p>
                911FosterPets empowers fosters by providing shelters with the
                tools they need to manage foster programs and volunteers.
              </p>
              <Button
                color="primary"
                size="lg"
                // outline
                onClick={() => history.push('/shelter-signup')}
                className="mb-3 mb-md-0"
              >
                Manage Your Fosters
              </Button>
            </div>

            {/* <p>
          Shelters try to find homes for pets they receive, but many
          become overcrowded. They are forced to euthanize pets in their
          care simply because of space, timing, or age.
        </p> */}
          </Col>
        </Row>
      </div>
    </Container>
  );
}
