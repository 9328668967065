import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import './HelpOutPage.scss';

const ContentPanel = styled.div`
  height: 80vh;
`;

function HelpOutPage() {
  return (
    <ContentPanel className="d-flex flex-column justify-content-center align-content-center align-items-center h-75">
      <Col
        md="8"
        className="d-flex flex-column d-flex flex-column align-items-center justify-content-center text-center"
      >
        <h2>Help Us Out</h2>
        <p>
          We are free for this rescue or shelter and survive on funds from
          people like you. We are an LLC and donations can not be used as a tax
          write-off.
        </p>

        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="8UFVBMZVGXMQ6" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
            border="0"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          <img
            alt=""
            border="0"
            src="https://www.paypal.com/en_US/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </Col>
    </ContentPanel>
  );
}

export default HelpOutPage;
