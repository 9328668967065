import React from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Table from '../../components/react-table/Table';
import './PublicAnimalsAdminPage.css';
import { Button } from '../../components';
import { requestService } from '../../services/request.service';
import { ADMIN_URL } from '../../constants/urls.constant';
import { Form } from 'react-bootstrap';

class AnimalListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animals: [],
      allAnimals: [],
      loaded: false,
      isFromRehomingUser: true,
      animalIdToRedirectTo: null,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this);
    this.handleShowRehomingUsersChange = this.handleShowRehomingUsersChange.bind(this);

    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async load() {
    const response = await requestService.fetch(`${ADMIN_URL}/v1/animals`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    const { animals: sortedList } = await response.json();

    const searchResults = sortedList.filter((animal) => {
      if (!!animal.isFromRehomingUser === true) {
        return animal;
      }

      return false;
    });

    this.setState({
      animals: searchResults,
      allAnimals: sortedList,
      filteredAnimals: sortedList,
      loaded: true,
    });

    return;
  }

  componentDidMount() {
    this.load(false);
  }

  handleStatusFilterChange(e) {
    const { allAnimals } = this.state;

    if (e.target.value === 'all') {
      this.setState({ animals: allAnimals });
    } else {
      const filteredAnimals = allAnimals.filter((animal) => {
        if (animal.status === e.target.value) {
          return animal;
        }

        return false;
      });

      this.setState({ animals: filteredAnimals, filteredAnimals });
    }
  }

  handleSearchChange(e) {
    const { filteredAnimals } = this.state;

    if (e.target.value) {
      const searchResults = filteredAnimals.filter((animal) => {
        if (animal.name.toLowerCase().includes(e.target.value.toLowerCase())) {
          return animal;
        }

        return false;
      });

      this.setState({ animals: searchResults });
    } else {
      this.setState({ animals: filteredAnimals });
    }
  }

  deleteAnimal(animalId) {
    const r = window.confirm('Are you sure you want to delete this animal?');
    if (r === true) {
      requestService
        .fetch(`${ADMIN_URL}/v1/animals:deleteAnimal?animalId=${animalId}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
        .then(() => {
          this.load(false);
        });
    }
  }

  handleShowRehomingUsersChange(e) {
    const { filteredAnimals } = this.state;
    const checked = !this.state.isFromRehomingUser;
    this.setState({ isFromRehomingUser: checked });
    if (e.target.value) {
      const searchResults = filteredAnimals.filter((animal) => {
        if (!!animal.isFromRehomingUser === checked) {
          return animal;
        }

        return false;
      });

      this.setState({ animals: searchResults });
    } else {
      this.setState({ animals: filteredAnimals });
    }
  }

  showRedirect() {
    if (this.state.animalIdToRedirectTo) {
      this.props.history.push(
        `/admin/animals/${this.state.animalIdToRedirectTo}/matching`,
      );
    }
  }

  render() {
    const { animals, loaded } = this.state;

    return (
      <div>
        <Container className="pb-3 pb-sm-5 mb-5">
          {this.showRedirect()}
          <div className="justify-content-between row">
            <div className="row col-12 col-xl-8 pr-0 pl-xl-1">
              <div className="col-12 col-xl-3">Pet Name Search</div>
              <div className="mb-3 col-12 col-xl-3 pr-0 pl-xl-4">
                <input
                  className="form-control"
                  name="search"
                  onChange={this.handleSearchChange}
                  placeholder="Search..."
                  type="text"
                />
              </div>
            </div>
          </div>

          <Form.Check
            inline
            type="checkbox"
            name="showLink"
            label="From Rehoming Users Only"
            onChange={this.handleShowRehomingUsersChange}
            checked={this.state.isFromRehomingUser}
            id="showLink"
          />
          {animals.length}
          <Table
            data={animals}
            columns={[
              {
                Cell: ({ original: animal, value }) => {
                  let t;

                  if(!value) {
                    t = animal.createdAt
                    if(!t) {
                      return null;
                    } else {
                      t = new Date(animal.createdAt._seconds * 1000);
                    }
                  } else {
                    t = new Date(value);
                  }
                  

                  return (
                    <div>
                      {t.toLocaleDateString()},{' '}
                      {t.toLocaleTimeString()}
                    </div>
                  );
                },
                Header: () => <b>Date Listed</b>,
                accessor: 'timeStamp',
                width: 140,
              },
              {
                Cell: ({ original: animal, value }) => {
                  let t = value;

                  if (!t) {
                    t = animal.createdAt
                    if(!t) {
                      return null;
                    } else {
                      console.log(t)
                      t = new Date(animal.createdAt._seconds * 1000).getTime();
                    }
                  } 
                  const dateListed = t;
                  const today = new Date().getTime();
                  const diff = today - dateListed;
                  return <div>{Math.ceil(diff / (1000 * 3600 * 24))} days</div>;
                },
                Header: () => <b>Days Listed</b>,
                accessor: 'timeStamp',
                width: 90,
              },
              {
                Cell: ({ original: animal, value }) => {
                  return (
                    <NavLink target="_blank" to={`/animals/${animal.id}`}>
                      {animal.name}
                    </NavLink>
                  );
                },
                Header: () => <b>Pet Name</b>,
                accessor: 'petName',
                width: 150,
              },
              {
                Cell: ({ _, value }) => {
                  return (
                    <NavLink target="_blank" to={`/admin/rescues/${value}`}>
                      {value}
                    </NavLink>
                  );
                },
                Header: () => <b>Person Name</b>,
                accessor: 'rescueId',
                width: 200,
              },
              {
                Cell: ({ original: animal }) => {
                  return (
                    <div>
                      {animal.city}, {animal.state}
                    </div>
                  );
                },
                Header: () => <b>Location</b>,
                accessor: 'name',
                width: 150,
              },
              {
                Cell: ({ original: animal }) => {
                  return <div>{animal.fosteringApplications}</div>;
                },
                Header: () => (
                  <b>Number of people who have applied to foster</b>
                ),
                accessor: 'fosteringApplications',
                width: 150,
              },
                            {
                Cell: ({ _, value }) => {
                  return <div>{value}</div>;
                },
                Header: () => (
                  <b>Text Messages Sent</b>
                ),
                accessor: 'messagesSent',
                width: 150,
              },
              {
                Cell: ({ _, value }) => {
                  return <div>{value}</div>;
                },
                Header: () => (
                  <b>Emails Sent</b>
                ),
                accessor: 'emailsSent',
                width: 150,
              },
              {
                Cell: ({ original: animal }) => {
                  return (
                    <Button
                      color="danger"
                      onClick={() => this.deleteAnimal(animal.id)}
                    >
                      Delete
                    </Button>
                  );
                },
                Header: () => <b>Delete</b>,
                accessor: 'name',
                width: 100,
              },
              {
                Cell: ({ original: animal }) => {
                  return (
                    <Button
                      color="primary"
                      outline
                      onClick={() =>
                        this.setState({ animalIdToRedirectTo: animal.id })
                      }
                    >
                      Matching
                    </Button>
                  );
                },
                Header: () => <b>Matching</b>,
                accessor: 'name',
                width: 120,
              },
            ]}
            loading={!loaded}
            showPagination={animals.length > 20}
            pageSize={animals.length > 20 ? 20 : animals.length}
          />
        </Container>
      </div>
    );
  }
}
export default AnimalListPage;
