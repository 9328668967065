import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
  flex-shrink: none !important;
`;

export default function Footer({ className = '' }) {
  return (
    <footer display="block">
      <StyledNavbar
        color="light"
        className={`bg-gray-200 text-muted small ${className}`}
      >
        <Container className="px-3">
          <span className="mr-4">
            &copy; RescueFoster, LLC {new Date().getFullYear()}
          </span>
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to="/contact-us">
              Contact Us
            </Nav.Link>

            <Nav.Link target="_blank" as={NavLink} to="/TermsOfUse.pdf">
              Terms of Use
            </Nav.Link>

            <Nav.Link
              target="_blank"
              as={NavLink}
              className="pr-0"
              to="/privacy-policy"
            >
              Privacy Policy
            </Nav.Link>
          </Nav>
        </Container>
      </StyledNavbar>
    </footer>
  );
}
