import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import firebase from '../../Firestore';
import SearchDropdownMenu from '../SearchDropdown/SearchDropdownMenu';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import { useMountEffect } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddFosterAnimalDropdown = (props) => {
  const [animals, setAnimals] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useMountEffect(() => {
    const db = firebase.firestore();

    db.collection('rescues')
      .doc(props.rescueId)
      .collection('animals')
      .get()
      .then((data) => {
        const animals = data.docs.map((animalDoc) => {
          return {
            name: animalDoc.get('name'),
            id: animalDoc.id,
          };
        });

        setAnimals(animals);
      });
  });

  const onSearch = (searchTermToSet) => {
    setSearchTerm(searchTermToSet);
  };

  const showDropdownItems = () => {
    let count = 0;
    let dropDownItems = [];
    animals.forEach((animal, i) => {
      if (
        count < 6 &&
        animal.name &&
        animal.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        count++;

        dropDownItems.push(
          <Dropdown.Item
            key={i}
            eventKey={i}
            onClick={() => props.onClickAdd(animal.id, props.fosterId)}
          >
            {animal.name}
          </Dropdown.Item>,
        );
      }
    });

    return <>{dropDownItems}</>;
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={SearchDropdown} id="dropdown-custom-components">
        <Button size="sm">
          <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
          Add Foster Animal
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={SearchDropdownMenu}
        onChange={(event) => onSearch(event.currentTarget.value)}
      >
        {/* generated dropdown items go here */}
        {showDropdownItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddFosterAnimalDropdown;
