import * as yup from 'yup';
import firebase from '../../../Firestore';
import { useHistory } from 'react-router-dom';

import {
  CONDITIONSARRAY,
  CONDITIONSLIST,
} from '../../../constants/conditions.constant';

export const useFosterSignUp = (rescueId) => {
  const history = useHistory();
  console.log('signup');
  const handleSubmit = (values, actions) => {
    const db = firebase.firestore();

    let negativeConditionIds = JSON.parse(JSON.stringify(CONDITIONSLIST));

    const positiveConditionIds = values.conditions.map((condition) => {
      return condition.value;
    });

    positiveConditionIds.forEach((condition) => {
      negativeConditionIds.splice(negativeConditionIds.indexOf(condition), 1);
    });

    const formattedPhoneNumber = values.phoneNumber.replace(/\D/g, '');

    db.collection(`publicFosters`)
      .add({
        acceptedSpecies: values.acceptedSpecies,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        conditions: negativeConditionIds,
        emailAddress: values.emailAddress,
        firstName: values.firstName,
        lastName: values.lastName,
        maxAnimals: values.maxAnimals,
        phoneNumber: formattedPhoneNumber,
        communicationPreference: values.communicationPreference,
        state: values.state.toUpperCase(),
        hasVehicle: values.hasVehicle,
        willFosterForIndividuals: false,
        willFosterForRescuesAndShelters: false,
        willingToTransportForOthers: values.willingToTransportForOthers,
        lengthOfFostering: values.lengthOfFostering,
        zip: values.zip,
        first3OfZip: values.zip.substring(0, 3),
        notes: values.notes,
        createdBy: localStorage.getItem('user')
          ? JSON.parse(localStorage.getItem('user')).uid
          : null,
        status: 'available',
        timeStamp: new Date().getTime(),
      })
      .then((data) => {
        history.push(
          `/foster-signup-step-2?id=${
            data.id
          }&state=${values.state.toUpperCase()}`,
        );
      });

    actions.setSubmitting(false);
  };

  const initialValues = {
    acceptedSpecies: ['dog'],
    address1: '',
    address2: '',
    city: '',
    hasVehicle: false,
    lengthOfFostering: [],
    conditions: CONDITIONSARRAY,
    emailAddress: '',
    firstName: '',
    lastName: '',
    maxAnimals: 1,
    willingToTransportForOthers: false,
    phoneNumber: '',
    state: '',
    zip: '',
    notes: '',
    rescueId: rescueId,
    communicationPreference: 'text',
  };

  const validationSchema = yup.object().shape({
    acceptedSpecies: yup.string().required('Required'),
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    conditions: yup.array().required('Required'),
    emailAddress: yup.string().required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    phoneNumber: yup
      .string()
      .required('Required')
      .max(11, 'Phone number cannot be more than 11 digits')
      .min(10, 'Phone number cannot be less than 10 digits'),
    state: yup
      .string()
      .max(2, 'State can only be two characters longs')
      .required('Required'),
    communicationPreference: yup.string().required('Required'),
    zip: yup.string().required('Required'),
    notes: yup.string(),
  });

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
