import { Formik } from 'formik';
import React from 'react';
import firebase from '../../Firestore';

import AddAnimalFosterRelationshipForm from './AddAnimalFosterRelationshipForm';
import { AddAnimalFosterRelationshipSchema } from './AddAnimalFosterRelationshipSchema';

function AddAnimalFosterRelationshipModal(props) {
  const dateAsTimeStamp = `${new Date().getFullYear()}-${`${new Date().getMonth() +
    1}`.padStart(2, 0)}-${`${new Date().getDate()}`.padStart(
    2,
    0,
  )}T${`${new Date().getHours()}`.padStart(
    2,
    0,
  )}:${`${new Date().getMinutes()}`.padStart(2, 0)}`;

  const initialValues = {
    animalId: props.animalId || '',
    rescueId: props.rescueId || '',
    fosterId: props.fosterId || '',
    checkInFrequency: '14',
    startDate: dateAsTimeStamp,
    estimatedReturnDate: dateAsTimeStamp,
    shouldSendNotification: false,
  };

  async function addRelationShip(values, actions) {
    try {
      const db = firebase.firestore();
      const fosterId = values.fosterId;
      const animalId = values.animalId;
      const rescueId = props.rescueId;

      const animalDoc = await db
        .collection('rescues')
        .doc(rescueId)
        .collection('animals')
        .doc(animalId)
        .get();

      const fosterDoc = await db
        .collection('rescues')
        .doc(rescueId)
        .collection('fosters')
        .doc(fosterId)
        .get();

      if (!animalDoc.exists || !fosterDoc.exists) {
        return;
      }

      await db
        .collection('rescues')
        .doc(rescueId)
        .collection('animalFosterRelationships')
        .add({
          animalId,
          fosterId,
          lastCheckInDate: values.startDate || null,
          checkInFrequency: values.checkInFrequency || null,
          shouldSendNotification: values.shouldSendNotification || null,
          primaryContact: values.primaryContact || null,
        });

      const fosterName = `${fosterDoc.get('firstName')} ${fosterDoc.get(
        'lastName',
      )}`;

      const animalName = animalDoc.get('name');

      const fosterParents = animalDoc.get('fosterParents') || [];

      fosterParents.push({
        fosterId,
        name: fosterName,
      });

      const fosterAnimals = fosterDoc.get('fosterAnimals') || [];

      fosterAnimals.push({
        animalId,
        name: animalName,
      });

      let fosterStatus = 'fostering';

      if (fosterDoc.get('maxAnimals') <= fosterAnimals.length) {
        fosterStatus = 'fosteringatmax';
      }

      fosterDoc.ref.update({
        fosterAnimals,
        status: fosterStatus,
      });

      animalDoc.ref.update({
        fosterParents,
        status: 'fostering',
        estimatedReturnDate: values.estimatedReturnDate,
        primaryContact: values.primaryContact,
      });

      props.onClose();
    } catch (err) {
      actions.setError('Error');
    } finally {
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      onSubmit={addRelationShip}
      initialValues={initialValues}
      validationSchema={AddAnimalFosterRelationshipSchema}
      component={AddAnimalFosterRelationshipForm}
    />
  );
}

export default AddAnimalFosterRelationshipModal;
