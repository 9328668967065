import React, { Fragment, PureComponent } from 'react';
import { Route } from 'react-router-dom';

import AddUserPage from '../pages/AddUserPage/AddUserPage';
import AdminPage from '../pages/AdminPage/AdminPage';
import PotentialRescuesPage from '../pages/PotentialRescuesPage/PotentialRescuesPage';
import AdminRescuePage from '../pages/AdminRescuePage/AdminRescuePage';
import PublicAnimalsAdminPage from '../pages/PublicAnimalsAdminPage/PublicAnimalsAdminPage';
import PublicAnimalMatchingPage from '../pages/admin/PublicAnimalMatchingPage/PublicAnimalMatchingPage';
import ScriptsPage from '../pages/admin/ScriptsPage/ScriptsPage';

import AdminStatisticsPage from '../pages/AdminStatisticsPage/AdminStatisticsPage';

import authService from '../services/auth.service';
import AdminNavigation from '../components/admin/AdminNavigation';

class RescueApp extends PureComponent {
  render() {
    const { match } = this.props;

    if (!authService.user || (authService.user && !authService.user.isAdmin)) {
      return <Fragment />;
    }

    return (
      <div>
        <AdminNavigation />
        <Route path={`${match.path}/general`} component={AdminPage} />
        <Route
          path={`${match.path}/potential-rescues`}
          component={PotentialRescuesPage}
        />
        <Route
          path={`${match.path}/statistics`}
          component={AdminStatisticsPage}
        />
        <Route
          path={`${match.path}/rescues/:rescueId`}
          component={AdminRescuePage}
        />
        <Route
          path={`${match.path}/public-animals`}
          component={PublicAnimalsAdminPage}
        />
        <Route path={`${match.path}/scripts`} component={ScriptsPage} />
        <Route
          path={`${match.path}/animals/:animalid/matching`}
          exact
          component={PublicAnimalMatchingPage}
        />
        <Route path={`${match.path}/add-user`} component={AddUserPage} />
      </div>
    );
  }
}

export default RescueApp;
