import React from 'react';
import { Button as BsButton } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Button({
  loading,
  loadingText = '',
  className = '',
  children,
  ...rest
}) {
  const extraPadding = loading && loadingText.length === 0;

  return (
    <BsButton
      {...rest}
      className={`${className} ${extraPadding ? 'px-5' : ''}`}
    >
      {loading ? (
        <>
          <FontAwesomeIcon
            icon="spinner"
            spin
            className={loadingText && 'mr-2'}
          />
          {loadingText}
        </>
      ) : (
        children
      )}
    </BsButton>
  );
}
