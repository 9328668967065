import firebase from 'firebase';
import 'firebase/auth';
import { Field, Form as FormikForm, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { PUBLIC_API_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';

import SimpleLink from '../../components/SimpleLink';
import authService from '../../services/auth.service';
import { Button } from '../../components';
import Loader from '../../components/Loader';

class PublicFosterUnsubscribePage extends PureComponent {
  errorTimeout;

  constructor(props) {
    super(props);

    this.state = {
      success: false,
    };
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    requestService
      .fetch(
        `${PUBLIC_API_URL}/v1/publicFosterUnsubscribe?publicFosterId=${params.publicFosterId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      )
      .catch((res) => {
        this.alert('failed');
      })
      .then((res) => {
        if (res.status !== 200) {
          alert('failed');
        } else {
          this.setState({ success: true });
        }
      });
  }

  render() {
    return (
      <div>
        <div className="login-page mt-2 mb-5 text-center">
          {this.state.success ? (
            <div>You have been successfully ununsubscribed</div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
}

export default PublicFosterUnsubscribePage;
