import moment from 'moment';
import React from 'react';
import { Redirect } from 'react-router';
import firebase from '../../Firestore';
import { PLURALIZATION } from '../../constants/pluralization.constant';
import './FosterAnimalPage.css';
import { CONDITIONSMAPWITHUNKNOWNS } from '../../constants/conditions.constant';

class FosterAnimalPage extends React.Component {
  constructor(props) {
    super(props);

    const rightNow = moment(new Date());
    const formattedDate = rightNow.format('YYYY-MM-DDTHH:mm');

    this.state = {
      animalDocumentReference: null,
      animalInfo: {},
      emailAddress: '',
      firstName: '',
      fosterAlreadyApplied: false,
      lastName: '',
      loaded: false,
      phoneNumber: '',
      pickupDateTime: formattedDate,
      shouldRedirectToFosterSignup: false,
      success: false,
    };

    this.signup = this.signup.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  componentDidMount() {
    const db = firebase.firestore();

    const {
      match: { params },
    } = this.props;

    const animalInfo = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid);

    db.collection('rescues')
      .doc(params.rescueid)
      .collection('animalFosterApplication')
      .where('animalId', '==', params.animalid)
      .get()
      .then((data) => {
        if (data.docs.length > 0) {
          this.setState({ fosterAlreadyApplied: true });
        }
      });

    animalInfo.get().then((data) => {
      this.setState({
        animalDocumentReference: animalInfo,
        animalInfo: data.data(),
        loaded: true,
        pluralization: data.data().quantity > 1 ? 'plural' : 'singular',
      });
    });
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  signup(e) {
    e.preventDefault();

    const {
      match: { params },
    } = this.props;
    const db = firebase.firestore();
    const fosterId = new URL(window.location.href).searchParams.get('fosterId');

    if (!fosterId) {
      db.collection('rescues')
        .doc(params.rescueid)
        .collection('fosters')
        .where('firstName', '==', this.state.firstName)
        .where('lastName', '==', this.state.lastName)
        .get()
        .then((data) => {
          if (data.docs[0] && data.docs[0].id) {
            db.collection(`rescues/${params.rescueid}/animalFosterApplication`)
              .add({
                animalId: this.state.animalDocumentReference.id,
                fosterId: data.docs[0].id,
                pickupDateTime: this.state.pickupDateTime,
                status: 'pending',
              })
              .then(() => {
                this.setState({ success: true });
              });
          } else {
            this.setState({ shouldRedirectToFosterSignup: true });
          }
        });
    } else {
      db.collection(`rescues/${params.rescueid}/animalFosterApplication`)
        .add({
          animalId: this.state.animalDocumentReference.id,
          fosterId,
          pickupDateTime: this.state.pickupDateTime,
          status: 'pending',
        })
        .then(() => {
          this.setState({ success: true });
        });
    }
  }

  showSuccess() {
    if (this.state.success) {
      return (
        <div>
          Thank you for fostering with us! We will get back to you shortly.
        </div>
      );
    }
  }

  fosterSignUpRedirect() {
    if (this.state.shouldRedirectToFosterSignup) {
      const {
        match: { params },
      } = this.props;

      return (
        <Redirect
          to={`/${params.rescueid}/fosterSignUp?fosterAnimal=${params.animalid}`}
        />
      );
    }
  }

  showAlreadyHasFoster() {
    if (this.state.fosterAlreadyApplied) {
      return (
        <span className="already-applied">
          Someone has already applied to foster this animal, if you would like
          to apply anyway please sign up below
        </span>
      );
    }
  }

  showImage() {
    if (this.state.animalInfo.imageUrl) {
      return (
        <img
          className="animal-image"
          alt="Animal from rescue or shelter that needs fostering. This helps
            foster coordinators identify and manage their animals more easily."
          src={this.state.animalInfo.imageUrl}
        />
      );
    }
  }

  showSignUpFields() {
    const fosterId = new URL(window.location.href).searchParams.get('fosterId');

    if (!fosterId) {
      return (
        <div>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              name="firstName"
              className="form-control"
              placeholder="First Name"
              onChange={this.updateInput}
              value={this.state.firstName}
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Last Name</label>
            <input
              name="lastName"
              className="form-control"
              placeholder="Last Name"
              onChange={this.updateInput}
              value={this.state.lastName}
            />
          </div>
        </div>
      );
    }
  }

  buildConditionStatement() {
    let conditions = this.state.animalInfo.conditions.map((condition) => {
      return CONDITIONSMAPWITHUNKNOWNS[condition];
    });

    let conditionStatement;

    if (conditions.length > 0) {
      if (conditions.length > 1) {
        conditions[conditions.length - 1] = `and ${
          conditions[conditions.length - 1]
        }.`;
      }

      conditions = conditions.join(', ');

      conditionStatement = `${PLURALIZATION[this.state.pluralization].ThisFosterAnimal} will need a home that can support animals that ${conditions}.\n\n`;
    } else {
      conditionStatement = `${PLURALIZATION[this.state.pluralization].ThisFosterAnimal} has no known special needs other than needing a good temporary home.\n\n`;
    }

    return conditionStatement;
  }

  showAnimalInfo() {
    if (!this.state.success) {
      return (
        <div>
          {this.showAlreadyHasFoster()}

          <p>
            We have{' '}
            {this.state.animalInfo.quantity > 1
              ? this.state.animalInfo.quantity
              : PLURALIZATION[this.state.pluralization].a}{' '}
            {this.state.animalInfo.gender + ' '}
            {this.state.animalInfo.ageYears
              ? `${this.state.animalInfo.ageYears} year(s) `
              : null}
            {this.state.animalInfo.ageYears && this.state.animalInfo.ageMonths
              ? `and `
              : null}
            {this.state.animalInfo.ageMonths
              ? `${this.state.animalInfo.ageMonths} month(s) old `
              : null}
            {this.state.animalInfo.breed} {this.state.animalInfo.species}
            {PLURALIZATION[this.state.pluralization].s} that{' '}
            {PLURALIZATION[this.state.pluralization].needs} fostering!
          </p>

          <p>{this.buildConditionStatement()}</p>

          <p>
            {this.state.animalInfo.publicNotes
              ? `Notes: ${this.state.animalInfo.publicNotes}`
              : null}
          </p>
        </div>
      );
    }
  }

  showAddAnimalForm() {
    const amountOfAnimals =
      this.state.animalInfo.quantity > 1 ? 'plural' : 'singular';

    if (!this.state.success) {
      return (
        <form onSubmit={this.signup}>
          {this.showSignUpFields()}

          <div className="form-group">
            <label>
              {`What time would you prefer to pick up ${PLURALIZATION[amountOfAnimals].thisFosterAnimal}?`}
            </label>

            <input
              className="form-control date-time"
              type="datetime-local"
              name="pickupDateTime"
              defaultValue={this.state.pickupDateTime}
              onChange={this.updateInput}
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Foster this animal
          </button>
        </form>
      );
    }
  }

  showForm() {
    if (this.state.loaded) {
      return (
        <div>
          <h1>Foster {this.state.animalInfo.name}</h1>
          {this.showImage()}
          {this.showAnimalInfo()}
          {this.showAddAnimalForm()}
          {this.showSuccess()}

          {this.fosterSignUpRedirect()}
        </div>
      );
    }
  }

  render() {
    return <div className="foster-animal-page">{this.showForm()}</div>;
  }
}
export default FosterAnimalPage;
