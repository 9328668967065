import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import firebase from '../../Firestore';
import AddFosterAnimalDropdown from '../AddFosterAnimalDropdown/AddFosterAnimalDropdown';

const FosterAnimalList = (props) => {
  const { rescueId, fosterId, fosterName, fosterAnimals, afterRemove } = props;

  async function removeFosterAnimal(fosterAnimal) {
    const db = firebase.firestore();

    db.collection('rescues')
      .doc(rescueId)
      .collection('animalFosterApplication')
      .where('animalId', '==', fosterAnimal.animalId)
      .where('fosterId', '==', fosterId)
      .get()
      .then((data) => {
        data.docs.forEach((animalFosterApplicationDoc) => {
          animalFosterApplicationDoc.ref.delete();
        });
      });

    const existingAnimalFosterRelationships = await db
      .collection('rescues')
      .doc(rescueId)
      .collection('animalFosterRelationships')
      .where('animalId', '==', fosterAnimal.animalId)
      .where('fosterId', '==', fosterId)
      .get();

    existingAnimalFosterRelationships.docs.forEach(
      (animalFosterRelationshipDoc) => {
        animalFosterRelationshipDoc.ref.delete();
      },
    );

    const fosterAnimalList = fosterAnimals || [];
    const fosterAnimalIndex = fosterAnimalList.indexOf(fosterAnimal);
    fosterAnimalList.splice(fosterAnimalIndex, 1);

    db.collection('rescues')
      .doc(rescueId)
      .collection('fosters')
      .doc(fosterId)
      .update({
        fosterAnimals: fosterAnimalList,
        status: fosterAnimals.length > 1 ? 'fostering' : 'available',
      });

    const fosterAnimalDoc = await db
      .collection('rescues')
      .doc(rescueId)
      .collection('fosters')
      .doc(fosterAnimal.animalId)
      .get();

    const fosterParents = fosterAnimalDoc.get('fosterParents') || [];
    const fosterParentActivelyFostering = fosterParents.find((fosterParent) => {
      return fosterParent.name === fosterName;
    });

    const fosterParentIndex = fosterParents.indexOf(
      fosterParentActivelyFostering,
    );
    fosterParents.splice(fosterParentIndex, 1);

    db.collection('rescues')
      .doc(rescueId)
      .collection('animals')
      .doc(fosterAnimal.animalId)
      .update({
        fosterParents,
        status: 'unavailable',
        estimatedReturnDate: null,
      });

    afterRemove();
  }

  const rows = fosterAnimals.map((fosterAnimal) => {
    return (
      <div key={fosterAnimal.animalId} className="mb-1">
        <Row>
          <Col sm="2">
            <NavLink
              to={`/rescue/${rescueId}/animals/${fosterAnimal.animalId}`}
            >
              {fosterAnimal.name}
            </NavLink>
          </Col>

          <Col>
            <Button
              onClick={() => removeFosterAnimal(fosterAnimal)}
              variant="danger"
              size="sm"
              className="ml-3"
            >
              Remove Animal
            </Button>
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <div>
      {rows}
      <div className="mt-2">
        <AddFosterAnimalDropdown
          rescueId={props.rescueId}
          fosterId={props.fosterId}
          onClickAdd={(animalId, fosterId) =>
            props.onClickAdd(animalId, fosterId)
          }
        />
      </div>
    </div>
  );
};
export default FosterAnimalList;
