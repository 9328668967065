import firebase from 'firebase';
import 'firebase/auth';
import { Field, Form as FormikForm, Formik } from 'formik';
import jwtDecode from 'jwt-decode';
import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { PUBLIC_API_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';

import SimpleLink from '../../components/SimpleLink';
import authService from '../../services/auth.service';
import './LoginPage.css';
import { Button } from '../../components';

const ERROR_TIMEOUT = 1000;

const initiaValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Required'),
  password: yup
    .string()
    .min(1)
    .required('Required'),
});

class LoginPage extends PureComponent {
  errorTimeout;

  constructor(props) {
    super(props);

    this.state = {
      hasLegacyAccount: false,
      passwordsDoNotMatch: false,
      loading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
  }

  onSubmit({ email, password, confirmPassword }, { setStatus, setSubmitting }) {
    this.setState({ loading: true });

    if (this.state.hasLegacyAccount) {
      this.createAccountFromLegacyAccount(
        email,
        password,
        confirmPassword,
        setSubmitting,
        setStatus,
      );
    } else {
      requestService
        .fetch(
          `${PUBLIC_API_URL}/v1/legacyAccount:exists?email_address=${email}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'GET',
          },
        )
        .then((res) => res.json())
        .then((hasLegacyAccount) => {
          if (hasLegacyAccount) {
            setSubmitting(false);
            this.setState({ hasLegacyAccount: true, loading: false });
          } else {
            this.login(email, password, setStatus, setSubmitting);
          }
        })
        .catch(() => {
          this.login(email, password, setStatus, setSubmitting);
        });
    }
  }

  createAccountFromLegacyAccount(
    email,
    password,
    confirmPassword,
    setSubmitting,
    setStatus,
  ) {
    if (password !== confirmPassword) {
      setSubmitting(false);
      this.setState({ passwordsDoNotMatch: true, loading: false });
    } else if (confirmPassword.length < 6) {
      setSubmitting(false);
      this.setState({ passwordNotLongEnough: true, loading: false });
    } else {
      requestService
        .fetch(`${PUBLIC_API_URL}/v1/legacyAccount:port`, {
          body: JSON.stringify({
            email,
            password,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
        .then(() => {
          this.login(email, password, setStatus, setSubmitting);
        })
        .catch(() => {
          setStatus('Failed to create account from legacy account');

          this.errorTimeout = setTimeout(() => {
            setSubmitting(false);
          }, ERROR_TIMEOUT);
        });
    }
  }

  login(email, password, setStatus, setSubmitting) {
    const { history } = this.props;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        user.user.getIdToken().then((idtoken) => {
          const decoded = jwtDecode(idtoken);

          history.push(`/rescue/${decoded.rescue}/fosters`);

          this.setState({ loading: false });
          setSubmitting(false);
        });
      })
      .catch(() => {
        setStatus('Failed to log in, please try again.');

        this.errorTimeout = setTimeout(() => {
          this.setState({ loading: false });
          setSubmitting(false);
        }, ERROR_TIMEOUT);
      });
  }

  showLegacyAccountCreate() {
    if (this.state.hasLegacyAccount) {
      let alertText =
        'We found a legacy account matching your login, please confirm your password to transfer to a new account';

      if (this.state.passwordsDoNotMatch) {
        alertText = 'Passwords do not match';
      }

      if (this.state.passwordNotLongEnough) {
        alertText = 'Password must be at least 6 characters long';
      }

      return (
        <div className="form-group">
          <div className="alert mb-3">{alertText}</div>

          <Field
            className="form-control"
            name="confirmPassword"
            placeholder="Confirm Password"
            type="password"
          />
        </div>
      );
    }
  }

  render() {
    if (authService.user) {
      return <Redirect to={`/rescue/${authService.user.rescue}/animals`} />;
    }

    return (
      <div>
        <div className="login-page mt-2 mb-5 text-center">
          <h1>Sign In</h1>
          <h3 className="mb-3">
            (For shelter users and individuals in need of temporary care for
            their pet)
          </h3>
          <Formik
            initialValues={initiaValues}
            onSubmit={this.onSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, status }) => (
              <div>
                {status}
                <FormikForm className="mb-3">
                  <div className="form-group">
                    <Field
                      className="mr-3 form-control"
                      name="email"
                      placeholder="Email"
                      type="email"
                    />
                  </div>

                  <div className="form-group">
                    <Field
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                  </div>

                  {this.showLegacyAccountCreate()}

                  <Button
                    type="submit"
                    block
                    color="primary"
                    loading={this.state.loading}
                    className="btn btn-primary text-white"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </Button>
                </FormikForm>

                <SimpleLink href={`/forgot-password`}>
                  Forgot Password?
                </SimpleLink>
              </div>
            )}
          </Formik>
        </div>

        <div className="bg-primary py-3 py-md-5">
          <Container className="text-white">
            <div className="text-center">
              <strong>
                Interested in signing up for your rescue or shelter?{' '}
              </strong>
              Click{' '}
              <u>
                <Link to="/shelter-signup" className="text-white">
                  here
                </Link>
              </u>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default LoginPage;
