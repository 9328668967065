import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container } from 'react-bootstrap';
import { Formik } from 'formik';
import EditAdditionalFosterQuestionsForm from './EditAdditionalFosterQuestionsForm';
import { EditAdditionalFosterQuestionsFormSchema } from './EditAdditionalFosterQuestionsFormSchema';
import Table from '../../components/react-table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import firebase from '../../Firestore';

const AddQuestion = (props) => {
  const [questions, setQuestions] = useState(false);
  const [questionsDoc, setQuestionsDoc] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useMountEffect(() => {
    loadQuestions();
  });

  const loadQuestions = () => {
    const db = firebase.firestore();

    const {
      match: { params },
    } = props;

    const questionsDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('additionalFosterQuestions');

    setQuestionsDoc(questionsDoc);

    questionsDoc.get().then((data) => {
      let questions = [];

      data.forEach((question) => {
        questions.push({ question: question.get('question'), id: question.id });
      });

      setLoaded(true);
      setQuestions(questions);
    });
  };

  const deleteQuestion = (question) => {
    const {
      match: { params },
    } = props;

    const r = window.confirm('Are you sure you want to delete this question?');
    if (r === true) {
      const db = firebase.firestore();

      db.collection('rescues')
        .doc(params.rescueid)
        .collection('additionalFosterQuestions')
        .doc(question.id)
        .delete()
        .then(() => {
          loadQuestions();
        });
    }
  };

  const showList = () => {
    if (questions) {
      return (
        <Table
          data={questions}
          columns={[
            {
              Cell: ({ original: question }) => {
                return <span>{question.question}</span>;
              },
              Header: () => <b>Questions</b>,
              accessor: 'question',
            },
            {
              Cell: ({ original: question }) => {
                return (
                  <button
                    className="btn btn-danger float-right"
                    onClick={() => {
                      deleteQuestion(question);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'trash-alt']}
                      className="mr-2"
                    />
                    Delete
                  </button>
                );
              },
            },
          ]}
          loading={!loaded}
          showPagination={questions.length > 100}
          pageSize={questions.length > 100 ? 100 : questions.length}
        />
      );
    }
  };

  return (
    <Container className="py-2 py-sm-3">
      <Formik
        onSubmit={(values, actions) => {
          const newQuestion = { question: values.question, type: 'text' };
          questionsDoc.add(newQuestion).then(() => {
            setQuestions([...questions, newQuestion]);
            actions.setSubmitting(false);
          });
        }}
        initialValues={{
          question: '',
        }}
        validationSchema={EditAdditionalFosterQuestionsFormSchema}
        component={EditAdditionalFosterQuestionsForm}
      />

      {showList()}
    </Container>
  );
};

export default AddQuestion;
