import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container } from 'react-bootstrap';
import { Formik } from 'formik';
import AddFosterForm from '../AddFosterPage/AddFosterForm';
import { AddFosterFormSchema } from '../AddFosterPage/AddFosterFormSchema';
import firebase from '../../Firestore';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader';
import {
  CONDITIONSMAP,
  CONDITIONSLIST,
} from '../../constants/conditions.constant';

let fosterId;

const EditFoster = (props) => {
  const [
    redirectToAdditionalQuestions,
    setRedirectToAdditionalQuestions,
  ] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [loading, setLoading] = useState(true);
  const [redirectToFosters, setRedirectToFosters] = useState(false);
  const [initialValues, setInitialValues] = useState(true);
  const [fosterDoc, setFosterDoc] = useState(false);

  const { rescueid } = props.match.params;

  useMountEffect(() => {
    const db = firebase.firestore();

    const {
      match: { params },
    } = props;

    const fosterDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('fosters')
      .doc(params.fosterid);

    setFosterDoc(fosterDoc);

    fosterDoc.get().then((data) => {
      const fosterData = data.data();

      let positiveConditionIds = JSON.parse(JSON.stringify(CONDITIONSLIST));

      const negativeConditionIds = fosterData.conditions.map((condition) => {
        return condition.value;
      });

      negativeConditionIds.forEach((condition) => {
        positiveConditionIds.splice(positiveConditionIds.indexOf(condition), 1);
      });

      const conditionsToSet = positiveConditionIds.map((condition) => {
        return { value: condition, label: CONDITIONSMAP[condition] };
      });

      setInitialValues({
        ...fosterData,
        acceptedSpecies: fosterData.acceptedSpecies || ['dog'],
        communicationPreference:
          fosterData.communicationPreference || 'phoneCall',
        conditions: conditionsToSet,
        editting: true,
      });
      setLoading(false);
    });
  });

  if (redirectToAdditionalQuestions) {
    return (
      <Redirect to={`/${rescueid}/additionalFosterQuestions/${fosterId}`} />
    );
  }

  if (redirectToFosters) {
    return <Redirect to={`/rescue/${rescueid}/fosters`} />;
  }

  if (showThankYou) {
    return <div>Thank you for signing up to foster with us!</div>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="py-3 py-sm-5">
      <Formik
        onSubmit={(values, actions) => {
          const {
            match: { params },
          } = props;

          const db = firebase.firestore();

          let negativeConditionIds = JSON.parse(JSON.stringify(CONDITIONSLIST));

          const positiveConditionIds = values.conditions.map((condition) => {
            return condition.value;
          });

          positiveConditionIds.forEach((condition) => {
            negativeConditionIds.splice(
              negativeConditionIds.indexOf(condition),
              1,
            );
          });

          fosterDoc
            .update({ ...values, conditions: negativeConditionIds })
            .then((data) => {
              const animalId = new URL(window.location.href).searchParams.get(
                'fosterAnimal',
              );

              fosterId = fosterDoc.id;

              if (animalId) {
                db.collection(
                  `rescues/${params.rescueid}/animalFosterApplication`,
                ).add({
                  animalId,
                  fosterId: data.id,
                  status: 'pending',
                });
              }

              db.collection('rescues')
                .doc(params.rescueid)
                .collection('additionalFosterQuestions')
                .get()
                .then((data) => {
                  if (data.docs.length > 0) {
                    setRedirectToAdditionalQuestions(true);
                  } else if (localStorage.getItem('user')) {
                    setRedirectToFosters(true);
                  } else {
                    setShowThankYou(true);
                  }
                });
            });
          actions.setSubmitting(false);
        }}
        initialValues={initialValues}
        validationSchema={AddFosterFormSchema}
        component={AddFosterForm}
      />
    </Container>
  );
};

export default EditFoster;
