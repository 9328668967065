import React from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { Form as FormikForm, Field } from 'formik';
import { InputField, LabelField } from '../../components/formik';
import Select from 'react-select';
import SimpleLink from '../../components/SimpleLink';
import { CONDITIONSARRAY } from '../../constants/conditions.constant';
import { Link } from 'react-router-dom';

const AddFosterForm = ({ ...formikProps }) => {
  const {
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    setFieldTouched,
  } = formikProps;

  return (
    <FormikForm>
      <fieldset disabled="">
        <Row form="true">
          <Col className="mx-auto">
            <InputField
              label="First Name"
              errors={errors}
              name="firstName"
              required
              onChange={handleChange}
              placeholder="Enter first name"
              touched={touched}
              type="text"
            />

            <InputField
              label="Last Name"
              errors={errors}
              name="lastName"
              required
              onChange={handleChange}
              placeholder="Enter last name"
              touched={touched}
              type="text"
            />

            <InputField
              label="Phone Number"
              errors={errors}
              name="phoneNumber"
              required={!values.animal}
              onChange={handleChange}
              placeholder="Enter phone number"
              touched={touched}
              type="text"
            />

            <InputField
              label="Email Address"
              errors={errors}
              name="emailAddress"
              required
              onChange={handleChange}
              placeholder="Enter email address"
              touched={touched}
              type="email"
            />
            {values.animal ? (
              <div />
            ) : (
              <div>
                <InputField
                  label="Address Line 1"
                  errors={errors}
                  name="address1"
                  required
                  onChange={handleChange}
                  placeholder="Enter address line one"
                  touched={touched}
                  type="text"
                />

                <InputField
                  label="Address Line 2"
                  errors={errors}
                  name="address2"
                  onChange={handleChange}
                  placeholder="Enter address line two"
                  touched={touched}
                  type="text"
                />

                <InputField
                  label="City"
                  errors={errors}
                  name="city"
                  required
                  onChange={handleChange}
                  placeholder="Enter address city"
                  touched={touched}
                  type="text"
                />

                <InputField
                  label="State"
                  errors={errors}
                  name="state"
                  required
                  onChange={handleChange}
                  placeholder="Enter address state"
                  touched={touched}
                  type="text"
                />
              </div>
            )}

            <InputField
              label="ZIP Code"
              errors={errors}
              name="zip"
              required
              onChange={handleChange}
              placeholder="Enter address ZIP code"
              touched={touched}
              type="text"
            />

            {values.animal ? (
              <div />
            ) : (
              <InputField
                label="Max Animals Willing to Foster"
                errors={errors}
                name="maxAnimals"
                required
                onChange={handleChange}
                placeholder="Max animals willing to foster"
                touched={touched}
                type="number"
              />
            )}

            <div className="mb-3">
              <LabelField
                labelName="Communication Preference"
                htmlFor="pref"
                fieldName="communicationPreference"
                required
              />

              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="communicationPreference"
                    value="text"
                    onChange={handleChange}
                    checked={values.communicationPreference === 'text'}
                  />
                  <label className="form-check-label">Text</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="communicationPreference"
                    value="email"
                    onChange={handleChange}
                    checked={values.communicationPreference === 'email'}
                  />
                  <label className="form-check-label">Email</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="communicationPreference"
                    value="textAndEmail"
                    onChange={handleChange}
                    checked={values.communicationPreference === 'textAndEmail'}
                  />
                  <label className="form-check-label">Text and Email</label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="communicationPreference"
                    value="phoneCall"
                    onChange={handleChange}
                    checked={values.communicationPreference === 'phoneCall'}
                  />
                  <label className="form-check-label">Phone Call</label>
                </div>
              </div>
            </div>

            {values.animal ? (
              <div />
            ) : (
              <div>
                <LabelField
                  labelName="Species willing to foster"
                  htmlFor="pref"
                  fieldName="acceptedSpecies"
                  required
                />

                <Form.Group>
                  <Form.Check
                    inline
                    type="checkbox"
                    name="acceptedSpecies"
                    label="Dog"
                    onChange={() => {
                      if (values.acceptedSpecies.includes('dog')) {
                        values.acceptedSpecies.splice(
                          values.acceptedSpecies.indexOf('dog'),
                          1,
                        );
                      } else {
                        values.acceptedSpecies.push('dog');
                      }
                      setFieldTouched('acceptedSpecies', true);
                    }}
                    checked={values.acceptedSpecies.includes('dog')}
                    id="speciesCat"
                  />
                  <Form.Check
                    inline
                    type="checkbox"
                    name="acceptedSpecies"
                    label="Cat"
                    onChange={() => {
                      if (values.acceptedSpecies.includes('cat')) {
                        values.acceptedSpecies.splice(
                          values.acceptedSpecies.indexOf('cat'),
                          1,
                        );
                      } else {
                        values.acceptedSpecies.push('cat');
                      }
                      setFieldTouched('acceptedSpecies', true);
                    }}
                    checked={values.acceptedSpecies.includes('cat')}
                    id="speciesCat"
                  />

                  <Form.Check
                    inline
                    type="checkbox"
                    name="acceptedSpecies"
                    label="Other"
                    onChange={() => {
                      if (values.acceptedSpecies.includes('other')) {
                        values.acceptedSpecies.splice(
                          values.acceptedSpecies.indexOf('other'),
                          1,
                        );
                      } else {
                        values.acceptedSpecies.push('other');
                      }
                      setFieldTouched('acceptedSpecies', true);
                    }}
                    checked={values.acceptedSpecies.includes('other')}
                    id="speciesOther"
                  />
                </Form.Group>
              </div>
            )}

            <h6>
              {values.animal
                ? 'Add your message/question about this pet'
                : 'Notes'}
            </h6>
            <Field
              component="textarea"
              className="form-control mb-3"
              label="Notes"
              errors={errors}
              name="notes"
              onChange={handleChange}
              placeholder={
                values.animal
                  ? 'Add a few sentences about why you are interested in this pet and any questions you may have. '
                  : 'Add a few sentences about why you are interested in fostering and any questions you may have. '
              }
              touched={touched}
              type="textarea"
            />

            {values.animal ? (
              <div />
            ) : (
              <div>
                <h6>I'd be willing to foster an animal that...</h6>
                <Form.Group>
                  <Select
                    onChange={(values) =>
                      setFieldValue('conditions', values ? values : [])
                    }
                    value={values.conditions}
                    onBlur={() => setFieldTouched('conditions', true, true)}
                    name="conditions"
                    options={CONDITIONSARRAY}
                    isMulti
                    isSearchable
                  />
                  {errors.conditions && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.conditions}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
            )}

            <div className="mb-3">
              <small className="text-muted">
                By submitting, I agree to receive email alerts from
                911fosterpets.com; I confirm that I am 18 years of age or older,
                and agree to the terms of the{' '}
                <Link target="_blank" to="/privacy-policy">
                  Privacy Policy
                </Link>
                . I understand that my contact info will be shared directly with
                an animal welfare organization, or individual pet guardian, in
                order to help a pet in need. By submitting you agree to the
                fostering terms{' '}
                <SimpleLink
                  href={`/${values.rescueId}/fosterAgreement`}
                  targetBlank={true}
                >
                  Here
                </SimpleLink>
              </small>
            </div>

            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AddFosterForm;
