import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container } from 'react-bootstrap';
import { RescueCard } from '../../components/RescueCard';
import Loader from '../../components/Loader';
import { ADMIN_URL } from '../../constants/urls.constant';
import authService from '../../services/auth.service';

import '../../styles/ShareButton.css';

const PublicAnimalPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [rescue, setRescue] = useState(false);

  const {
    match: { params },
  } = props;

  useMountEffect(() => {
    fetchRescue(params.rescueId);
  });

  const fetchRescue = async (rescueId) => {
    const rescue = await fetch(`${ADMIN_URL}/v1/rescues/${rescueId}`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
    }).then((res) => res.json());

    setRescue(rescue);
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="py-3 py-sm-5">
      <RescueCard rescue={rescue} />
    </Container>
  );
};

export default PublicAnimalPage;
