import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import statusMap from '../../utils/fosterStatusMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from '../../Firestore';

function showButton(
  rescueId,
  animalFosterApplicationId,
  placeAnimal,
  currentStatus,
) {
  if (currentStatus === 'approved') {
    return <span>Approved</span>;
  }

  return (
    <Button
      color="primary"
      outline
      size="sm"
      onClick={() =>
        placeAnimal(rescueId, animalFosterApplicationId, 'approved')
      }
    >
      <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
      Add as foster parent
    </Button>
  );
}

function confirmDeleteRelationship(rescue, animalFosterApplicationId) {
  const r = window.confirm('Are you sure you want to delete this application?');
  if (r === true) {
    deleteApplication(rescue, animalFosterApplicationId);
  }
}

function deleteApplication(rescue, animalFosterApplicationId) {
  const db = firebase.firestore();

  db.collection('rescues')
    .doc(rescue)
    .collection('animalFosterApplication')
    .doc(animalFosterApplicationId)
    .delete()
    .then(() => {
      window.location.reload();
    });
}

const FosterListRow = ({ foster, rescueId, placeAnimal }) => {
  const {
    animalFosterApplicationId,
    firstName,
    lastName,
    applicationStatus,
    fosterId,
  } = foster;

  return (
    <div className="d-flex align-items-center justify-content-between mb-2 border-bottom border-width-1 border-color-gray-400 pb-1">
      <span>
        <NavLink to={`/rescue/${rescueId}/fosters/${fosterId}`}>
          {`${firstName} ${lastName}`}
        </NavLink>
        ({statusMap[foster.status] || foster.status})
      </span>

      <div>
        <Button
          color="danger"
          outline
          size="sm"
          className="mr-2"
          onClick={() =>
            confirmDeleteRelationship(rescueId, animalFosterApplicationId)
          }
        >
          <FontAwesomeIcon icon={['far', 'trash-alt']} className="mr-2" />
          Remove
        </Button>

        {showButton(
          rescueId,
          animalFosterApplicationId,
          placeAnimal,
          applicationStatus,
        )}
      </div>
    </div>
  );
};

export default FosterListRow;
