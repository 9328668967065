import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './App';

import { ThemeProvider } from 'styled-components';
import { theme } from '@911fosterpets/global-styles';
import './assets/fonts/fontawesome';
import './styles/index.scss';
import { trackException } from './utils/analytics';

const GA_ID = !process.env.REACT_APP_DEV ? 'UA-118815028-2' : '';

// Setting debug to true will output a lot of console logs
ReactGA.initialize(GA_ID, {
  debug: false && Boolean(process.env.REACT_APP_DEV),
});

try {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.getElementById('root'),
  );
} catch (err) {
  // Track exception
  trackException(err);
}
