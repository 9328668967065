import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { KickerText } from '../../../components/text';
import {
  BestFriendsLogo,
  MaddiesFundLogo,
  ModernCatLogo,
  ModernDogLogo,
  PetParentLogo,
} from '../../../assets/logos/partners';
import styled from 'styled-components';

const StyledImageLink = styled.a`
  opacity: 0.6;
  transition: opacity ease-in-out 0.15s;
  &:hover {
    opacity: 1;
    img {
      filter: none;
    }
  }
  img {
    filter: grayscale(100%);
    transition: filter ease-in-out 0.15s;
  }
`;

export default function PartnersPanel() {
  return (
    <div className="bg-white py-5">
      <Container className="px-4 px-md-3">
        <Row>
          <Col xs="12" className="mx-auto text-center">
            <KickerText>Meet</KickerText>
            <h1>Our Partners</h1>
            <Row className="d-flex justify-content-center align-items-center px-4 px-md-5">
              <PartnerImage
                alt="Best Friends | Save Them All"
                image={BestFriendsLogo}
                href="https://support.bestfriends.org/"
              />
              <PartnerImage
                alt="Maddie's Fund | #ThanksToMaddie"
                image={MaddiesFundLogo}
                href="https://www.maddiesfund.org/index.htm"
              />
              <PartnerImage
                alt="Pet Parent"
                href="https://petparenthub.com/"
                image={PetParentLogo}
              />
              <PartnerImage
                alt="ModernDog Magazine"
                image={ModernDogLogo}
                href="https://moderndogmagazine.com/"
              />
              <PartnerImage
                alt="ModernCat Magazine"
                image={ModernCatLogo}
                href="https://moderncat.com/"
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const PartnerImage = ({ alt, image, href }) => (
  <Col xs="6" sm="4" md="3" lg className="mb-4">
    <StyledImageLink href={href} rel="noopener noreferrer" target="_blank">
      <img src={image} alt={alt} className="img-fluid" />
    </StyledImageLink>
  </Col>
);
