import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import firebase from '../Firestore';
import Loader from './Loader';

const CheckInBox = (props) => {
  const { rescueId, animalId } = props;

  const [value, setValue] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  async function submitCheckIn() {
    setLoading(true);
    const db = firebase.firestore();
    const timeStamp = new Date().getTime();

    const fosterId = new URL(window.location.href).searchParams.get('fosterId');

    const fosterDoc = await db
      .collection('rescues')
      .doc(rescueId)
      .collection('fosters')
      .doc(fosterId)
      .get();

    const fosterName = `${fosterDoc.get('firstName')} ${fosterDoc.get(
      'lastName',
    )}`;

    db.collection('rescues')
      .doc(rescueId)
      .collection('animals')
      .doc(animalId)
      .collection('checkIns')
      .add({
        text: value,
        timeStamp,
        fosterId,
        animalId,
        fosterName,
        imageUrl,
      })
      .then(() => {
        setLoading(false);
        setSuccess(true);
      });
  }

  function updateInput(e) {
    setValue(e.target.value);
  }

  const uploadImage = (e) => {
    setLoading(true);
    const tgt = e.target || window.event.srcElement;
    const files = tgt.files;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      const imageFile = tgt.files[0];

      const formData = new FormData();
      formData.append('image', imageFile);

      fr.onload = () => {
        const imageFile = tgt.files[0];
        let imageName = 'image' + Date.now();
        const storageRef = firebase
          .storage()
          .ref('/checkInImages/' + imageName);

        storageRef.put(imageFile).then((uploadTaskSnapshot) => {
          uploadTaskSnapshot.ref.getDownloadURL().then((downloadUrl) => {
            setImageUrl(downloadUrl);
            setLoading(false);
          });
        });
      };

      fr.readAsDataURL(files[0]);
    }
  };

  if (success) {
    return 'Success! Thank you for checking in';
  }

  if (loading) {
    return (
      <div className="text-center">
        <Loader />
        Please be patient, uploading may take a while
      </div>
    );
  }

  return (
    <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
      <h3 className="mb-2">Check-in</h3>

      <Form>
        <textarea
          className="form-control mb-2"
          placeholder="How are they, anything new or interesting?"
          value={value}
          focus="true"
          onChange={updateInput}
          rows="3"
          id="check-in"
          autoFocus
        ></textarea>
      </Form>

      <Form.Group>
        <h3 className="mb-2">Image Upload</h3>
        <div>Uploaded URL: {imageUrl}</div>
        <input
          id="image_url"
          className="w-100 p-3 border rounded border-gray-400"
          name="image_url"
          type="file"
          accept="image/*"
          onChange={(e) => uploadImage(e)}
        />
      </Form.Group>

      <Button className="primary" onClick={submitCheckIn}>
        Post
      </Button>
    </div>
  );
};

export default CheckInBox;
