import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Form as FormikForm } from 'formik';
import { InputField } from '../../components/formik';

const AdditionalFosterQuestionsForm = ({ ...formikProps }) => {
  const { errors, touched, handleChange, values } = formikProps;

  const questions = () => {
    let questionsToReturn = [];
    values.questions.forEach((question) => {
      const questionToAdd = (
        <div key={question.question}>
          <InputField
            label={question.question}
            errors={errors}
            name={question.question}
            required
            onChange={handleChange}
            touched={touched}
            type={question.type}
          />
        </div>
      );

      questionsToReturn.push(questionToAdd);
    });

    return <div>{questionsToReturn}</div>;
  };

  return (
    <FormikForm>
      <fieldset disabled="">
        <Row form="true">
          <Col className="mx-auto">
            <h5>Additional Questions</h5>

            {questions()}

            <Button color="primary" type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AdditionalFosterQuestionsForm;
