import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useMountEffect } from '../../hooks';
import firebase from '../../Firestore';
import Loader from '../../components/Loader';

const FosterAgreementPage = (props) => {
  const [agreement, setAgreement] = useState(false);
  const [loading, setLoading] = useState(true);

  useMountEffect(() => {
    loadAgreement();
  });

  const loadAgreement = () => {
    const db = firebase.firestore();

    const {
      match: { params },
    } = props;

    const agreementDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('legalAgreement');

    agreementDoc.get().then((data) => {
      setAgreement(data.docs[0] ? data.docs[0].data().agreement : '');
      setLoading(false);
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Row>
        <Col md="8" className="mx-auto mt-3">
          <h3 className="mb-0">Fostering Agreement</h3>

          <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400 mt-3">
            <div>
              {agreement ||
                'By submitting you agree that the rescue, shelter, or owner retains all rights and ownership of any animals in the foster program. The rescue or shelter has the right to recall a fostered animal at any time.'}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FosterAgreementPage;
