import { Formik } from 'formik';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router';

import { ADMIN_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';
import AddUserForm from './AddUserForm';
import { AddUserSchema } from './AddUserSchema';

const initialValues = {
  displayName: '',
  phoneNumber: '',
  email: '',
  password: '',
  rescue: '',
  isRescueAdmin: false,
};

function AddUserPage() {
  const [success, setSuccess] = useState(false);

  async function addUser(user, actions) {
    const formattedPhoneNumber = user.phoneNumber.replace(/\D/g, '');

    try {
      await requestService
        .fetch(`${ADMIN_URL}/v1/user`, {
          body: JSON.stringify({ ...user, phoneNumber: formattedPhoneNumber }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
        .then(() => {
          setTimeout(async () => {
            setSuccess(true);
          }, 1000);
        })
        .catch(() => {});
    } catch (err) {
      actions.setError('Error');
      alert(
        'There was an error, please try again. Contact us if the issue continues.',
      );

      setSuccess(false);
    } finally {
      actions.setSubmitting(false);
    }
  }

  if (!success) {
    return (
      <Container className="py-3 py-sm-5">
        <Formik
          onSubmit={addUser}
          initialValues={initialValues}
          validationSchema={AddUserSchema}
          component={AddUserForm}
        />
      </Container>
    );
  } else {
    return <Redirect to={`/admin/general`} />;
  }
}

export default AddUserPage;
