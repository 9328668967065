import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import AddAnimalForm from './AddAnimalForm';
import { AddAnimalFormSchema } from './AddAnimalFormSchema';
import firebase from '../../Firestore';

const AddAnimal = (props) => {
  const { rescueid } = props.match.params;
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    if (window.location.search.includes('from-rehome-signup=true')) {
      return (
        <Redirect to={`/rescue/${rescueid}/animals?from-rehome-signup=true`} />
      );
    } else {
      return <Redirect to={`/rescue/${rescueid}/animals`} />;
    }
  }

  return (
    <Container className="py-3 py-sm-5">
      <Formik
        onSubmit={async (values, actions) => {
          const { image_url, imageName } = values;

          const user = JSON.parse(localStorage.getItem('user'));

          const normalizedValues = {
            ...values,
            createdAt: new Date(),
            imageUrl: image_url || null,
            imageName: imageName || null,
            isFromRehomingUser: Boolean(user) && Boolean(user.isRehomingUser),
          };

          const db = firebase.firestore();

          db.collection(`rescues/${rescueid}/animals`)
            .add(normalizedValues)
            .then((data) => {
              if (data) {
                actions.setSubmitting(false);
              }

              setRedirect(true);
            });
        }}
        initialValues={{
          ageYears: '',
          ageMonths: '',
          breed: '',
          conditions: [],
          gender: 'male',
          status: 'available',
          name: '',
          notes: '',
          picture: '',
          microChipNumber: '',
          primaryVet: '',
          emergencyContactName: '',
          emergencyContactPhoneNumber: '',
          emergencyContactRelationship: '',
          species: 'dog',
          isInCustody: false,
          hasUrgentNeed: false,
          isSpayedOrNeutered: true,
          isFeral: false,
          typeOfFoster: 'temporary',
          quantity: 1,
          isFromRehomingUser: false,
        }}
        validationSchema={AddAnimalFormSchema}
        component={AddAnimalForm}
      />
    </Container>
  );
};

export default AddAnimal;
