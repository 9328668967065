import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button, VideoPlayer } from '../components';
import { PageHeaderHalfBackground } from '../components/pages';
import { useHistory } from 'react-router-dom';

export default function FosteringFaqPage() {
  const history = useHistory();

  return (
    <PageHeaderHalfBackground
      title="Fostering FAQ"
      subtitle="Here are some frequently asked questions about fostering"
    >
      <Container>
        <div className="bg-white p-3 p-md-5 shadow">
          <section className="mb-5">
            <h4>What does it mean to foster for a shelter or rescue group?</h4>
            <p>
              Fostering is temporarily caring for a pet in need; instead of the
              pet living in an animal shelter or boarding facility, you care for
              the pet in your home until that pet can be/is officially adopted.
              In some cases, you will help the pet get healthy or get better
              trained before it becomes available to the general public for
              adoption — but healthy and already trained pets need foster homes,
              too. Pets of all ages need foster homes because pets of all ages
              end up in shelters. You can choose what type/age/size of pet to
              foster according to what you are comfortable with and/or allowed
              to have in your home.
            </p>
          </section>
          <section className="mb-5">
            <h4>What is peer-to-peer fostering?</h4>
            <p>
              In certain circumstances — e.g., the COVID-19 crisis — otherwise
              responsible pet owners will need help caring for their personal
              pets. Pet owners facing illness, income loss, housing disruption,
              or a sudden change in work schedules may be temporarily unable to
              care for their pet(s). You can help by offering to house and care
              for their pet(s) until they are able to themselves. Doing so
              prevents these pets from having to enter the overcrowded shelter
              system, and prevents their owners from enduring the heartbreak of
              having to permanently give up a pet they love.
            </p>
          </section>
          <section className="mb-5">
            <h4>What does fostering cost?</h4>
            <p>
              Fostering is free. In most cases, the shelter or rescue group you
              foster through will provide all food, supplies, and vet care — you
              are just responsible for giving the pet a place to live (and lots
              of love). Some organizations will even provide dog walking or
              daycare services through volunteers and/or community business
              partners (i.e., the organization is a nonprofit so a local
              for-profit pet care company may donate their services). If you are
              temporarily fostering a pet for an individual, the pet’s owner
              will likely provide all supplies; they may provide a financial
              stipend for the pet’s care as well. These details should be
              discussed and confirmed before entering into a temporary foster
              care agreement.
            </p>
          </section>
          <section className="mb-5">
            <h4>Why should I foster?</h4>
            <p>
              Fostering frees up space in animal shelters by keeping pets housed
              in the community, providing greater exposure for adoptable pets as
              well. When you foster a pet for a shelter or rescue group, you are
              helping both that pet and the pet that can now use the space they
              would have taken up in a shelter. Fostering is also a great way
              for you to ‘test drive’ potential pets before making a lifelong
              commitment to adopt one yourself.
            </p>
          </section>
          <section className="mb-5">
            <h4>What does being a “foster fail” mean? Is that a bad thing?</h4>
            <p>
              In some cases, people who foster are interested in adopting a pet
              but aren’t yet ready to make a commitment. They may foster pets
              until they feel ready to keep one permanently. Adopting your own
              foster pet is affectionately referred to as “foster failing” —
              it’s a good thing! You “failed” at only fostering the pet, but you
              succeeded at meeting the one you wanted to keep.
            </p>
          </section>
          <section className="mb-5">
            <h4>What resources are available for new foster homes?</h4>
            <p>
              Shelters and rescue groups often have their own “foster handbooks”
              to help new foster homes get started, but{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://learn.petcademy.org/collections"
              >
                Petcademy{' '}
              </a>
              offers free online course as well. Explore the following options:
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://learn.petcademy.org/courses/fostering-fundamentals-dogs"
              >
                Fostering fundamentals for dogs
              </a>{' '}
              Provides new foster parents with all the information necessary to
              successfully foster and help the dog find a furever home. The
              course includes lessons on how to create a safe space, read body
              language, and create effective social media posts to help the dog
              get adopted.
            </p>

            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://learn.petcademy.org/courses/fostering-fundamentals-cats"
              >
                Fostering fundamentals for cats
              </a>{' '}
              Provides new foster parents with all the information necessary to
              successfully foster and help the cat find a furever home. The
              course includes lessons on how to create a safe space, provide
              daily care and enrichment, and create effective social media posts
              to help the cat get adopted.
            </p>

            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://learn.petcademy.org/courses/fostering-fundamentals-kittens"
              >
                Fostering fundamentals for kittens
              </a>{' '}
              Provides new foster parents with all the information necessary to
              successfully foster kittens. The course includes lessons on how to
              set up a kitten ready environment, bottle feed, and effectively
              socialize the kittens in preparation for adoption.
            </p>
          </section>

          <Row>
            <Col xs="12" md="6" className="mx-auto mb-3">
              <VideoPlayer url="https://youtu.be/Htud-Pzvtgw" />
            </Col>
          </Row>

          <section className="mb-5">
            <h4>
              I have a question that wasn’t answered here, who can I contact?
            </h4>
            <p>
              Send an email to{' '}
              <a href="mailto:outreach@911fosterpets.com">
                outreach@911fosterpets.com
              </a>{' '}
              and we’ll do our best to help.
            </p>
          </section>

          <Button
            color="primary"
            size="lg"
            className="mr-3"
            onClick={() => history.push('/animals')}
          >
            View Animals Near Me
          </Button>
          <Button
            color="primary"
            size="lg"
            outline
            className="mr-3"
            onClick={() => history.push('/foster-signup')}
          >
            Become A Foster
          </Button>
        </div>
      </Container>
    </PageHeaderHalfBackground>
  );
}
