import React from 'react';
import { Formik } from 'formik';
import { Alert } from 'reactstrap';

import { RescueSignUpForm, useRescueSignUp } from '.';
import { PageHeaderHalfBackground } from '../../../components/pages';
import { FormContainer } from '../components';

export default function RescueSignUpPage() {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    success,
    isRehoming,
  } = useRescueSignUp();

  const title = isRehoming ? 'Find a foster home' : 'Manage Your Fosters';
  const subtitle = isRehoming
    ? 'Help a pet find a temporary home'
    : 'Get connected with more fosters and manage them all in one place';

  return (
    <PageHeaderHalfBackground title={title} subtitle={subtitle}>
      <FormContainer>
        {success && (
          <Alert color="success">
            Thank you! We will contact you shortly to help you get set up.
          </Alert>
        )}

        {!success && (
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <RescueSignUpForm {...formikProps} isRehoming={isRehoming} />
            )}
          </Formik>
        )}
      </FormContainer>
    </PageHeaderHalfBackground>
  );
}
