import React, { useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { PublicAnimalList } from '../../../components/animals';
import { KickerText } from '../../../components/text';
import { useMountEffect } from '../../../hooks';
import { Loader } from '../../../components';
import { PUBLIC_API_URL } from '../../../constants/urls.constant';
import { requestService } from '../../../services/request.service';
import firebase from 'firebase';

export default function AnimalsPanel() {
  const [count, setCount] = useState(0);

  useMountEffect(async () => {
    const db = firebase.firestore();

    const rescuesDocs = await db.collection('rescues').get();
    // console.log(rescuesDocs);
    const arrayToLog = [];

    rescuesDocs.forEach(async (rescuesDoc) => {
      if (!rescuesDoc.data().isRehomingUser) {
        // console.log(rescuesDoc.get('name'));
        // console.log(!rescuesDoc.data().isRehomingUser);
        const userDocs = await rescuesDoc.ref.collection('users').get();
        userDocs.forEach((userDoc) => {
          arrayToLog.push(rescuesDoc.data().name, userDoc.data().emailAddress);
          // console.log(userDoc.data().emailAddress);
          // console.log(arrayToLog);
        });
      }
    });

    requestService
      .fetch(`${PUBLIC_API_URL}/v1/animals:count`)
      .then((res) => res.json())
      .then(({ count }) => {
        setCount(count);
      });
  });

  return (
    <Container className="px-4 px-md-3">
      <div className="py-4 py-md-5 border-bottom">
        <Row>
          <Col xs="12" md="8" className="mx-auto text-center">
            <KickerText>Animals In Need</KickerText>
            <h1>
              {count === 0 ? (
                <Loader />
              ) : (
                count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              )}
            </h1>
            <p>Looking for foster homes</p>
          </Col>
        </Row>
        <PublicAnimalList />
      </div>
    </Container>
  );
}
