import React from 'react';
import Loader from '../Loader';

const TableLoader = ({ loading }) => {
  return loading ? (
    <div className="-loading -active">
      <Loader className="-loading-inner" />
    </div>
  ) : null;
};

export default TableLoader;
