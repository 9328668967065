import React from 'react';
import { RouteComponentProps } from 'react-router';

type Props = RouteComponentProps;

class NotFountPage extends React.PureComponent<Props> {
  render(): React.ReactElement {
    return (
      <div>
        <h1>This is not the page you are looking for</h1>
        <p>{this.props.location.pathname}</p>
      </div>
    );
  }
}

export default NotFountPage;
