import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import EditLegalAgreementForm from './EditLegalAgreementForm';
import { EditLegalAgreementSchema } from './EditLegalAgreementSchema';
import Loader from '../../components/Loader';

import firebase from '../../Firestore';

const AddQuestion = (props) => {
  const [agreement, setAgreement] = useState(false);
  const [agreementDoc, setAgreementDoc] = useState(false);
  const [agreementDocId, setAgreementDocId] = useState(false);

  const [loaded, setLoaded] = useState(false);

  useMountEffect(() => {
    loadAgreement();
  });

  const loadAgreement = () => {
    const db = firebase.firestore();

    const {
      match: { params },
    } = props;

    const agreementDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('legalAgreement');

    setAgreementDoc(agreementDoc);

    agreementDoc.get().then((data) => {
      setAgreement(data.docs[0] ? data.docs[0].data().agreement : '');
      setAgreementDocId(data.docs[0] ? data.docs[0].id : null);
      setLoaded(true);
    });
  };

  const showAgreement = () => {
    if (agreement) {
      return (
        <Row>
          <Col md="8" className="mx-auto">
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              <div>
                {agreement ||
                  'By submitting you agree that the rescue, shelter, or owner retains all rights and ownership of any animals in the foster program. the rescue or shelter has the right to recall a fostered animal at any time.'}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  };

  if (!loaded) {
    return <Loader />;
  }

  return (
    <Container className="py-2 py-sm-3">
      <Formik
        onSubmit={(values, actions) => {
          if (agreementDocId) {
            agreementDoc
              .doc(agreementDocId)
              .update({ agreement: values.agreement })
              .then(() => {
                setAgreement(values.agreement);
                actions.setSubmitting(false);
              });
          } else {
            agreementDoc.add({ agreement: values.agreement }).then((data) => {
              setAgreement(values.agreement);
              setAgreementDocId(data.id);
              actions.setSubmitting(false);
            });
          }
        }}
        initialValues={{
          agreement,
        }}
        validationSchema={EditLegalAgreementSchema}
        component={EditLegalAgreementForm}
      />

      {showAgreement()}
    </Container>
  );
};

export default AddQuestion;
