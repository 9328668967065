import * as yup from 'yup';
import { numbersOnly } from '../../utils/regex.js';

export const AddAnimalFormSchema = yup.object().shape({
  ageYears: yup
    .string()
    .matches(numbersOnly, { message: 'Numbers only' })
    .max(3, 'Cannot be more than 3 digits long'),
  ageMonths: yup
    .string()
    .matches(numbersOnly, { message: 'Numbers only' })
    .max(3, 'Cannot be more than 3 digits long'),
  quantity: yup.number(),
  breed: yup.string().required('Required'),
  conditions: yup.array(),
  gender: yup.string().required('Required'),
  image_url: yup.string().required('Required'),
  name: yup.string().required('Required'),
  notes: yup.string(),
  publicNotes: yup.string(),
  isFeral: yup.boolean(),
  species: yup.string().required('Required'),
  status: yup.string().required('Required'),
  microChipNumber: yup.string(),
  primaryVet: yup.string(),
  emergencyContactName: yup.string(),
  emergencyContactPhoneNumber: yup.string(),
  emergencyContactRelationship: yup.string(),
});
