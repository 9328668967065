import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import placeholder from '../assets/paw.png';
import PlaceholderText from './PlaceholderText';
import { PLURALIZATION } from '../constants/pluralization.constant';
import { CONDITIONSMAPWITHUNKNOWNS } from '../constants/conditions.constant';
import SimpleLink from './SimpleLink';

const BasicAnimalInfo = (props) => {
  const {
    name,
    species,
    breed,
    gender,
    ageMonths,
    rescueName,
    rescueId,
    rescueGroupsId,
    ageYears,
    imageUrl,
    conditions,
    actionLabel,
    action,
    notes,
    quantity,
    location,
  } = props;

  const amountOfAnimals = quantity && quantity > 1 ? 'plural' : 'singular';

  function buildConditionStatement(conditions) {
    if (conditions && Array.isArray(conditions)) {
      let mappedConditions = conditions.map((condition) => {
        return CONDITIONSMAPWITHUNKNOWNS[condition];
      });

      let conditionStatement;

      if (conditions.length > 0) {
        if (conditions.length > 1) {
          mappedConditions[conditions.length - 1] = `and ${
            mappedConditions[conditions.length - 1]
          }.`;
        }

        let readableConditions = mappedConditions.join(', ');

        conditionStatement = `${PLURALIZATION[amountOfAnimals].ThisFosterAnimal} will need a home that can support animals that ${readableConditions}\n\n`;
      } else {
        conditionStatement = `${PLURALIZATION[amountOfAnimals].ThisFosterAnimal} has no known special needs other than needing a good temporary home.\n\n`;
      }

      return conditionStatement;
    }
  }

  function formattedAgeInfo(ageMonths, ageYears) {
    if (!ageMonths && !ageYears) {
      return 'unknown';
    } else if (ageMonths && !ageYears) {
      return `${ageMonths} month(s) old`;
    } else if (ageYears && !ageMonths) {
      return `${ageYears} year(s) old`;
    }

    return `${ageYears} year(s) and ${ageMonths} month(s) old`;
  }

  function navigateToRescueAnimals() {
    const { history } = this.props;

    if (rescueId) {
      history.push('/' + rescueId + '/animals');
    } else if (rescueGroupsId) {
      history.push('/' + rescueGroupsId + '/animals?rescueGroups=true');
    }
  }

  function showButton() {
    if (actionLabel && action) {
      return (
        <Button className="mt-3" onClick={action}>
          {actionLabel}
        </Button>
      );
    }
  }

  var decodeHTML = function(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400 mb-2">
      <Row>
        <Col sm="4">
          <div className="d-flex align-items-center justify-content-center w-100 mb-3">
            <img
              height="100%"
              width="100%"
              alt={`${PLURALIZATION[amountOfAnimals].Animal} from rescue or shelter that needs fostering. This helps
                foster coordinators identify and manage their animals more easily.`}
              src={imageUrl ? imageUrl : placeholder}
            />
          </div>
        </Col>
        <Col md="8" className="mx-auto col-sm-4">
          <div className="col-sm-8">
            <h3 className="mb-0">{name}</h3>
            {rescueName ? (
              <h6 className="mb-0">
                Posted by:{' '}
                <SimpleLink
                  href={
                    rescueId
                      ? `/${rescueId}/animals`
                      : `/${rescueGroupsId}/animals?fromRescueGroups=true`
                  }
                >
                  {rescueName}
                </SimpleLink>
              </h6>
            ) : (
              <div />
            )}
            <div className="text-muted small">{location}</div>
            <div className="text-muted small text-uppercase mb-3">
              {species}
            </div>
            <div className="font-weight-bold mb-1">Breed (or looks like)</div>
            <div className="mb-3">{breed}</div>
            <div className="font-weight-bold mb-1">Gender</div>
            <div className="mb-3">{gender} </div>
            <div className="font-weight-bold mb-1">Age</div>
            <div className="mb-3">{formattedAgeInfo(ageMonths, ageYears)}</div>
            <div className="font-weight-bold mb-1">Details</div>
            <div className="mb-3"> {buildConditionStatement(conditions)} </div>
            <div className="font-weight-bold mb-1">Notes</div>
            {notes ? (
              decodeHTML(notes)
            ) : (
              <PlaceholderText>No notes</PlaceholderText>
            )}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">{showButton()}</Row>
    </div>
  );
};

export default BasicAnimalInfo;
