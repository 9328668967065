import React, { useState } from 'react';
import { Button } from '../../components';

const SendMessageButton = (props) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  if (success) {
    return <div>Sent</div>;
  }
  return (
    <Button
      loading={loading}
      color="primary"
      onClick={() => {
        setLoading(true);

        props.action(props.foster).then(() => {
          setLoading(false);
          setSuccess(true);
        });
      }}
    >
      Send Message
    </Button>
  );
};

export default SendMessageButton;
