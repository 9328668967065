import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackButton = ({ url, children }) => {
  return (
    <NavLink to={url} className="d-inline-block mb-2">
      <FontAwesomeIcon icon={['far', 'long-arrow-alt-left']} className="mr-2" />
      {children}
    </NavLink>
  );
};

export default BackButton;
