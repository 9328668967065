import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import firebase from '../../Firestore';
import BackButton from '../../components/BackButton';

const AlreadyAFosterForm = (props) => {
  const {
    match: { params },
  } = props;

  const animalId = new URL(window.location.href).searchParams.get(
    'fosterAnimal',
  );

  const [notFound, setNotFound] = useState(false);
  const [success, setSuccess] = useState(false);

  const showNotFound = () => {
    if (notFound) {
      return (
        <small className="text-danger">
          This email was not found for this rescue
        </small>
      );
    }
  };

  if (success) {
    return (
      <div>
        Thank you for fostering with us! We will get back to you shortly.
      </div>
    );
  }

  return (
    <Container className="py-3 py-sm-5">
      <BackButton
        url={`/${params.rescueid}/fosterSignUp?fosterAnimal=${animalId}`}
      >
        Go Back
      </BackButton>

      <h5 className="mb-3">Email Address</h5>

      <Formik
        onSubmit={(values) => {
          const db = firebase.firestore();

          db.collection('rescues')
            .doc(params.rescueid)
            .collection('fosters')
            .where('emailAddress', '==', values.emailAddress)
            .get()
            .then((data) => {
              if (data.empty) {
                setNotFound(true);
              } else {
                db.collection(
                  `rescues/${params.rescueid}/animalFosterApplication`,
                )
                  .add({
                    animalId,
                    fosterId: data.docs[0].id,
                    status: 'pending',
                  })
                  .then(() => {
                    setSuccess(true);
                  });
              }
            });
        }}
        initialValues={{ emailAddress: '' }}
      >
        {({ isSubmitting, status }) => (
          <div>
            {status}
            <Form>
              <div className="form-group">
                <Field
                  className="mr-3 form-control w-50"
                  name="emailAddress"
                  placeholder="Enter your email to sign up for this animal"
                  type="email"
                />
                {showNotFound()}
              </div>

              <button
                type="submit"
                className="btn btn-primary text-white"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </Container>
  );
};

export default AlreadyAFosterForm;
