import { Form as FormikForm, Field } from 'formik';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../../components';
import { useMountEffect } from '../../hooks';
import { requestService } from '../../services/request.service';
import { API_URL } from '../../constants/urls.constant';
import Loader from '../../components/Loader';

const AddUserForm = ({ ...formikProps }) => {
  const { errors, touched, handleChange, isValid, isSubmitting } = formikProps;

  const [localOptinsCount, setLocalOptinsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useMountEffect(() => {
    requestService
      .fetch(`${API_URL}/v1/optins:count`)
      .then((res) => res.json())
      .then(({ count }) => {
        setLoading(false);
        setLocalOptinsCount(count);
      });
  });

  return (
    <FormikForm>
      <fieldset disabled={isSubmitting}>
        <Row>
          <Col md="8">
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              {loading ? (
                <Loader />
              ) : (
                <h5>
                  Broadcast to the {localOptinsCount} people looking to help
                  near you
                </h5>
              )}

              <Field
                component="textarea"
                className="form-control mb-3"
                label="Message"
                errors={errors}
                name="message"
                onChange={handleChange}
                placeholder="Hello! This is RescueName! We could use emergency supplies! Please message us at 123-456-7890"
                touched={touched}
                type="textarea"
              />

              <Button
                loading={isSubmitting}
                color="primary"
                disabled={!isValid}
                type="submit"
              >
                Send Message
              </Button>
            </div>
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AddUserForm;
