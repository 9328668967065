import React from 'react';
import { Row } from 'reactstrap';
import { connectHits } from 'react-instantsearch-dom';
import AnimalSearchHit from './AnimalSearchHit';

function AnimalSearchHits({ hits }) {
  return (
    <Row id="animalCards">
      {hits.map((hit) => (
        <AnimalSearchHit {...hit} key={hit.objectID} />
      ))}
    </Row>
  );
}

export default connectHits(AnimalSearchHits);
