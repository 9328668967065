import { Form as FormikForm, Field } from 'formik';
import React, { useState } from 'react';

import { Button } from '..';
import { InputField } from '../formik';
import { useMountEffect } from '../../hooks';
import SimpleLink from '../SimpleLink';

const AddAnimalFosterRelationshipForm = ({ ...formikProps }) => {
  const { errors, touched, handleChange, values } = formikProps;

  const [loading, setLoading] = useState(false);

  useMountEffect(() => {});

  return (
    <FormikForm>
      <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
        <h5>Ask about me</h5>
        <InputField
          label="First Name"
          errors={errors}
          name="firstName"
          required
          onChange={handleChange}
          placeholder="Enter first name"
          touched={touched}
          type="text"
        />

        <InputField
          label="Last Name"
          errors={errors}
          name="lastName"
          required
          onChange={handleChange}
          placeholder="Enter last name"
          touched={touched}
          type="text"
        />

        <InputField
          label="Phone Number"
          errors={errors}
          name="phoneNumber"
          required
          onChange={handleChange}
          placeholder="Enter phone number"
          touched={touched}
          type="text"
        />

        <InputField
          label="Email Address"
          errors={errors}
          name="emailAddress"
          required
          onChange={handleChange}
          placeholder="Enter email address"
          touched={touched}
          type="email"
        />

        <InputField
          label="ZIP Code"
          errors={errors}
          name="zip"
          required
          onChange={handleChange}
          placeholder="Enter address ZIP code"
          touched={touched}
          type="text"
        />

        <h6>Comments</h6>
        <Field
          component="textarea"
          className="form-control mb-3"
          label="Notes"
          errors={errors}
          name="notes"
          onChange={handleChange}
          placeholder="Miscellaneous info goes here"
          touched={touched}
          type="textarea"
        />

        <Button
          onClick={() => setLoading(true)}
          loading={loading}
          color="primary"
          type="submit"
        >
          Submit
        </Button>
        <div className="mb-3">
          <small className="text-muted">
            By submitting you agree you are 18 years of age or older and that
            you agree to the rescue or shelter's fostering terms{' '}
            <SimpleLink
              href={`/${values.rescueId}/fosterAgreement`}
              targetBlank={true}
            >
              Here
            </SimpleLink>
            . By signing up you are also opting in to email and text
            communications from 911FosterPets/RescueFoster. We send messages
            when a rescue or shelter asks you to foster an animal for them or
            when a rescue or shelter needs a check-in. We only send text
            messages if you have selected a communication preference including
            text.
          </small>
        </div>
      </div>
    </FormikForm>
  );
};

export default AddAnimalFosterRelationshipForm;
