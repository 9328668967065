import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

const AdminNavigation = (props) => {
  const history = useHistory();

  const addUser = () => {
    history.push('/admin/add-user');
  };

  return (
    <div className="mx-3 mt-5">
      <ButtonToolbar>
        <NavLink className="ml-auto" to={`/admin/general`}>
          <Button size="med" className="btn-secondary text-white mr-3">
            Rescues
          </Button>
        </NavLink>

        <NavLink to={`/admin/statistics`}>
          <Button size="med" className="btn-secondary text-white mr-3">
            Stats
          </Button>
        </NavLink>

        <NavLink to={`/admin/public-animals`}>
          <Button size="med" className="btn-secondary text-white mr-3">
            Public Animals
          </Button>
        </NavLink>

        <NavLink to={`/admin/potential-rescues`}>
          <Button size="med" className="btn-secondary text-white mr-3">
            Potential Rescues
          </Button>
        </NavLink>

        {
          <NavLink to={`/admin/scripts`}>
            <Button size="med" className="btn-secondary text-white mr-3">
              Scripts
            </Button>
          </NavLink>
        }

        <Button className="mb-3" variant="primary" onClick={addUser}>
          <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
          Add User
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export default AdminNavigation;
