import 'firebase/auth';
import React from 'react';
import { Col } from 'reactstrap';
import placeholder from '../../assets/paw.png';
import styled from 'styled-components';

const StyledImage = styled.div`
  padding-bottom: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
`;

const StyledCard = styled.div.attrs({
  className: 'bg-white rounded border p-2 mb-3 mb-md-0',
})`
  box-shadow: 0 0.15rem 0.25rem rgba(18, 22, 31, 0.15);
  cursor: pointer;
  &:hover {
    box-shadow: 0 0.35rem 0.75rem rgba(18, 22, 31, 0.15);
  }
  &:active {
    box-shadow: 0 0.15rem 0.25rem rgba(18, 22, 31, 0.15);
  }
`;

export default function AnimalCard(props) {
  const {
    name,
    id,
    hasUrgentNeed,
    imageUrl,
    city,
    state,
    rescueName,
    clickAction,
  } = props;

  return (
    <Col className="mb-3" xs="6" sm="4" md="4" lg="3" xl="2">
      <StyledCard onClick={() => clickAction(id)}>
        <StyledImage
          image={imageUrl ? imageUrl : placeholder}
          alt="Public animal from nearby rescue or shelter that needs fostering. This
              is available to potential foster parents who live near"
          className="rounded mb-1"
        />
        <div className="text-center">
          <h6 className="mb-0">{name}</h6>
          <div className="small text-muted">
            {rescueName
              ? `${rescueName} - ${city}, ${state}`
              : `${city}, ${state}`}
          </div>
          {hasUrgentNeed && (
            <div className="text-danger small font-weight-bold">
              Placement needed urgently
            </div>
          )}
        </div>
      </StyledCard>
    </Col>
  );
}
