import { Form as FormikForm } from 'formik';
import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components';
import { InputField } from '../../components/formik';

const AddUserForm = ({ ...formikProps }) => {
  const {
    errors,
    touched,
    handleChange,
    isValid,
    isSubmitting,
    values,
  } = formikProps;

  return (
    <FormikForm>
      <fieldset disabled={isSubmitting}>
        <Row>
          <Col md="8" className="mx-auto">
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              <h5>Add a user</h5>

              <InputField
                label="Display Name"
                errors={errors}
                name="displayName"
                required
                onChange={handleChange}
                placeholder="Enter the user's display name"
                touched={touched}
                type="text"
              />

              <InputField
                label="Phone Number"
                errors={errors}
                name="phoneNumber"
                required
                onChange={handleChange}
                placeholder="Enter the user's phone number"
                touched={touched}
                type="text"
              />

              <InputField
                label="Email"
                errors={errors}
                name="email"
                required
                onChange={handleChange}
                placeholder="Enter the user's email"
                touched={touched}
                type="text"
              />

              <InputField
                label="Password"
                errors={errors}
                name="password"
                required
                onChange={handleChange}
                placeholder="Enter the user's password"
                touched={touched}
                type="password"
              />

              <InputField
                label="Rescue"
                errors={errors}
                name="rescue"
                required
                onChange={handleChange}
                placeholder="Enter the user's rescue"
                touched={touched}
                type="text"
              />

              <Form.Check
                className="mb-3"
                name="isRescueAdmin"
                label="Is Rescue Admin?"
                onChange={handleChange}
                checked={values.isRescueAdmin}
                id="isRescueAdmin"
              />

              <Button
                loading={isSubmitting}
                color="primary"
                disabled={!isValid}
                type="submit"
              >
                <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
                Add User
              </Button>
            </div>
            <div className="my-5 py-5" />
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AddUserForm;
