import * as yup from 'yup';

export const AddFosterFormSchema = yup.object().shape({
  acceptedSpecies: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  city: yup.string(),
  conditions: yup.array(),
  emailAddress: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: yup
    .string()
    .required('Required')
    .max(11, 'Phone number cannot be more than 11 digits')
    .min(10, 'Phone number cannot be less than 10 digits'),
  state: yup.string(),
  communicationPreference: yup.string().required('Required'),
  zip: yup.string().required('Required'),
  notes: yup.string(),
});
