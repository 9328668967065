import React from 'react';
import { Row, Col, Container, Alert } from 'reactstrap';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { useCovid19Panel } from '.';
import { Button } from '../../../components';
import { KickerText } from '../../../components/text';
import { InputField } from '../../../components/formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Covid19Panel() {
  const {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    errors,
    showPanel,
    closePanel,
  } = useCovid19Panel();

  if (!showPanel) return null;

  return (
    <Container className="px-4 px-md-3">
      <div className="py-4 px-3 my-3 pb-md-4 pt-md-3 px-md-4 border rounded shadow-sm">
        <div className="d-flex justify-content-end align-items-top">
          <Button color="link" className="p-0 text-muted" onClick={closePanel}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </Button>
        </div>
        <Row className="d-flex align-items-center">
          <Col xs="12" md="6">
            <KickerText>We Need Your Help</KickerText>
            <h1 className="text-danger">Calling all Foster Heroes!</h1>
            <p className="mb-0">
              When animal shelters fill up or families are displaced, innocent
              pets often pay the ultimate price. These pets need a temporary
              home – sometimes only a few weeks. Together, we can keep families
              from being separated and make sure that shelters do not have to
              euthanize pets because of space, timing, or age. Please join our
              group of Foster Heroes and we’ll let you know when a pet in your
              area needs help.
            </p>
          </Col>
          <Col xs="12" md="6">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <Form>
                  <fieldset disabled={loading}>
                    {errors && (
                      <Alert color="danger" className="fs-sm py-1 px-3">
                        {errors.map((error) => error)}
                      </Alert>
                    )}
                    <Row form>
                      <Col xs="12" md="6">
                        <InputField
                          name="email"
                          label="Email"
                          required
                          placeholder="Enter your email"
                        />
                      </Col>

                      <Col xs="12" md="6">
                        <InputField
                          name="zip"
                          label="ZIP Code"
                          placeholder="Enter your ZIP or postal code"
                          required
                        />
                      </Col>
                    </Row>

                    <Button
                      color="danger"
                      className="mb-3"
                      loading={loading}
                      disabled={loading}
                      loadingText="Submitting..."
                      onClick={formikProps.handleSubmit}
                    >
                      Help Save a Life
                    </Button>
                  </fieldset>

                  <p className="fs-xxs text-muted mb-0">
                    By submitting, I agree to receive email alerts from
                    911fosterpets.com; I confirm that I am 18 years of age or
                    older, and agree to the terms of the{' '}
                    <Link target="_blank" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                    . I understand that my contact info may be shared directly
                    with an animal welfare organization, or individual pet
                    guardian, in order to help a pet in need.
                  </p>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
