import React from 'react';
import { Row, Col } from 'reactstrap';

export default function FormContainer({ children }) {
  return (
    <Row>
      <Col md="6" className="mx-auto" style={{ maxWidth: '800px' }}>
        <div className="bg-white p-3 p-md-4 shadow mb-5">{children}</div>
      </Col>
    </Row>
  );
}
