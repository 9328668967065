import 'firebase/auth';
import React, { useState, useEffect } from 'react';
import { useMountEffect } from '../../hooks';
import { Container, Row, Col } from 'react-bootstrap';
import algoliasearch from 'algoliasearch';
import {
  Configure,
  InstantSearch,
  MenuSelect,
  Pagination,
  Panel,
  RefinementList,
  SearchBox,
} from 'react-instantsearch-dom';
import 'instantsearch.css/themes/algolia.css';

import {
  AnimalSearchHit,
  AnimalSearchHits,
  AnimalSearchStateResults,
} from '../../components/animals';
import { LocationSearch, Loader } from '../../components';
import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  PUBLIC_ANIMALS_INDEX_NAME,
} from '../../constants/search.constant';
import {
  getInitialConditions,
  transformListRefinements,
  transformMenuRefinements,
} from '../../utils/algolia.util';
import './PublicAnimalsPage.css';
import firebase from '../../Firestore';

const PublicAnimalsPage = () => {
  const [fosterId, setFosterId] = useState('');
  const [userGeolocation, setUserGeolocation] = useState();
  const [searchGeoloction, setSearchGeolocation] = useState();
  const [selectedConditions, setSelectedConditions] = useState();
  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFosterData = async (publicFosterId) => {
    if (!publicFosterId) {
      setSelectedConditions(getInitialConditions());
      setLoading(false);
      return;
    }

    const db = firebase.firestore();
    const publicFosterDoc = await db
      .collection('publicFosters')
      .doc(publicFosterId)
      .get();

    const publicFoster = publicFosterDoc.data() || {};

    setSelectedConditions(getInitialConditions(publicFoster.conditions));
    setSelectedSpecies(publicFoster.acceptedSpecies || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchFosterData(fosterId);
  }, [fosterId]);

  const handleMounted = () => {
    const fosterIdToSet = new URL(window.location.href).searchParams.get(
      'fosterId',
    );

    if (fosterIdToSet) {
      setFosterId(fosterIdToSet);
    } else {
      setSelectedConditions(getInitialConditions());
      setLoading(false);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          setUserGeolocation(
            `${position.coords.latitude},${position.coords.longitude}`,
          );
        },
        function() {},
      );
    }
  };

  useMountEffect(handleMounted);

  const showThankYou = () => {
    if (fosterId) {
      return (
        <div className="alert alert-info d-flex justify-content-between mb-3">
          <div className="alert-text">
            Thank you for signing up to help! Below are the pets that match what
            you are looking for!
          </div>
        </div>
      );
    }
  };

  const setUrlParam = (input, paramName) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(paramName, input);
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const nameSearch = urlParams.get('nameSearch');

  const showSearch = () => {
    const geolocation = searchGeoloction || userGeolocation;
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

    return (
      <InstantSearch
        indexName={PUBLIC_ANIMALS_INDEX_NAME}
        refresh
        searchClient={searchClient}
      >
        <Configure
          aroundLatLng={geolocation}
          aroundLatLngViaIP={!geolocation}
          hitsPerPage={24}
        />

        <Row className="search-row">
          <Col md="12" className="search-bar">
            <SearchBox
              showLoadingIndicator
              onInput={(e) => {
                if (e.target) {
                  setUrlParam(e.target.value, 'nameSearch');
                }
              }}
              defaultRefinement={nameSearch}
              translations={{
                placeholder: 'Search by pet name or rescue name...',
              }}
            />
          </Col>
        </Row>

        <Row className="search-row">
          <Col md="12" className="search-bar">
            <LocationSearch
              coordinates={userGeolocation}
              placeholder="Search by address, city, state, region, provice, zip..."
              onLocationCleared={() => {
                setUrlParam('', 'locationSearch');
                setSearchGeolocation();
              }}
              onLocationSelected={(value) => {
                setUrlParam(value, 'locationSearch');
                setSearchGeolocation(value);
              }}
            />
          </Col>
        </Row>

        <Row className="search-wrapper">
          <Col md="4">
            <Panel header="Species" className="refinement-group">
              <RefinementList
                attribute="species"
                defaultRefinement={selectedSpecies}
                limit={10}
                transformItems={transformListRefinements}
              />
            </Panel>

            <Panel header="Gender" className="refinement-group">
              <RefinementList
                attribute="gender"
                limit={10}
                transformItems={transformListRefinements}
              />
            </Panel>

            <Panel
              header="Include animals from individuals in need"
              className="refinement-group"
            >
              <MenuSelect
                attribute="fromRehomingUser"
                limit={3}
                transformItems={transformMenuRefinements}
              />
            </Panel>

            <Panel
              header="Animals from external sources"
              className="refinement-group"
            >
              <MenuSelect attribute="fromExternalSource" limit={3} />
            </Panel>

            <Panel
              header="Include animals with the following conditions"
              className="conditions-list refinement-group"
            >
              <RefinementList
                attribute="conditions"
                defaultRefinement={selectedConditions}
                limit={100}
                transformItems={transformListRefinements}
              />
            </Panel>
          </Col>

          <Col md="8">
            <AnimalSearchHits hitComponent={AnimalSearchHit} />

            <AnimalSearchStateResults fosterId={fosterId} />

            <Pagination />
          </Col>
        </Row>
      </InstantSearch>
    );
  };

  return (
    <Container className="mt-5">
      {showThankYou()}
      <h1 className="search-header ml-2 text-center">
        Animals that need temporary homes near you
      </h1>

      {loading ? <Loader /> : showSearch()}
    </Container>
  );
};

export default PublicAnimalsPage;
