import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Table from '../../components/react-table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import placeholder from '../../assets/paw.png';
import firebase from '../../Firestore';
import statusMap from '../../utils/animalStatusMap';
import SimpleLink from '../../components/SimpleLink';
import './AnimalListPage.css';

class AnimalListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animals: [],
      allAnimals: [],
      loaded: false,
      user: {},
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({ user: user });
    const db = firebase.firestore();
    const {
      match: { params },
    } = this.props;

    const animalsDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .orderBy('createdAt');

    animalsDoc.get().then((data) => {
      const animalList = data.docs.map((doc) => {
        if (doc.data()) {
          const animal = doc.data();
          animal.id = doc.id;
          animal.key = doc.id;
          return animal;
        }

        return null;
      });

      const sortedList = animalList.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return b.createdAt.seconds - a.createdAt.seconds;
        }

        return 0;
      });

      this.setState({
        animals: sortedList,
        allAnimals: sortedList,
        filteredAnimals: sortedList,
        loaded: true,
      });
    });

    return;
  }

  handleStatusFilterChange(e) {
    const { allAnimals } = this.state;

    if (e.target.value === 'all') {
      this.setState({ animals: allAnimals });
    } else {
      const filteredAnimals = allAnimals.filter((animal) => {
        if (animal.status === e.target.value) {
          return animal;
        }

        return false;
      });

      this.setState({ animals: filteredAnimals, filteredAnimals });
    }
  }

  handleSearchChange(e) {
    const { filteredAnimals } = this.state;

    if (e.target.value) {
      const searchResults = filteredAnimals.filter((animal) => {
        if (animal.name.toLowerCase().includes(e.target.value.toLowerCase())) {
          return animal;
        }

        return false;
      });

      this.setState({ animals: searchResults });
    } else {
      this.setState({ animals: filteredAnimals });
    }
  }

  showThankYou() {
    if (window.location.search.includes('from-rehome-signup=true')) {
      return (
        <div className="alert alert-info d-flex justify-content-between mb-3">
          <div className="alert-text">
            All set! We will let you know if someone offers to give the pet a
            temporary home
          </div>
        </div>
      );
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const { rescueid: rescueId } = params;
    const { animals, loaded } = this.state;

    return (
      <div>
        <Container className="py-3 py-sm-5 mb-5">
          <div className="d-flex align-items-center px-3 py-2 text-center mb-3">
            <Container className="px-0 px-md-3">
              <h6 className="mb-0 d-flex justify-content-center align-items-center">
                Transporting a pet?
              </h6>
              <div className="fs-sm">
                Click
                <a
                  target="_blank"
                  href="/blogs/covid-transport-guidelines.html"
                  className="p-0 pl-1"
                >
                  <u className="font-weight-500">here</u>
                </a>{' '}
                to view guidelines for safe transfer of pets during COVID-19
              </div>
            </Container>
          </div>
          {this.showThankYou()}
          <div className="justify-content-between row">
            {this.state.user && !this.state.user.isRehomingUser && (
              <div className="pt-2 mb-3 col-12 col-xl-4 pr-0 pl-3">
                Publicly available animals can be viewed{' '}
                <SimpleLink href={`/${rescueId}/animals`}>here</SimpleLink>
              </div>
            )}

            <div className="row col-12 col-xl-8 pr-0 pl-xl-1">
              <div className="mb-3 col-12 col-xl-3 pr-0 pl-xl-4">
                <input
                  className="form-control"
                  name="search"
                  onChange={this.handleSearchChange}
                  placeholder="Search..."
                  type="text"
                />
              </div>

              <div className="mb-3 col-12 col-xl-3 pr-0 pl-xl-3">
                <select
                  name="status"
                  className="form-control"
                  onChange={this.handleStatusFilterChange}
                  defaultValue={this.state.filterStatus}
                >
                  <option value="all">All Statuses</option>
                  <option value="available">Available for fostering</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="fostering">In Foster Home</option>
                </select>
              </div>

              <div className="mb-3 col-12 col-xl-6 pr-0 pl-4 row">
                <NavLink
                  className="px-1"
                  to={`/rescue/${rescueId}/animals/add`}
                >
                  <Button
                    color="primary"
                    size="med"
                    className="text-white float-right float-xl-none pr-3"
                  >
                    <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
                    Add Animal
                  </Button>
                </NavLink>

                <NavLink
                  className="px-1"
                  to={`/rescue/${rescueId}/animals/add?multiple=true`}
                >
                  <Button
                    color="primary"
                    size="med"
                    className="text-white float-right float-xl-none pr-3"
                  >
                    <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
                    Add Litter/Multiple
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>

          <Table
            data={animals}
            columns={[
              {
                Cell: ({ value }) => {
                  return (
                    <img
                      className="pr-2"
                      src={value ? value : placeholder}
                      style={{ width: '70px' }}
                      alt="Animal from rescue or shelter that needs fostering. This helps
                        foster coordinators identify and manage their animals more easily."
                    />
                  );
                },
                Header: () => <b>Image</b>,
                accessor: 'imageUrl',
                sortable: false,
                width: 75,
              },
              {
                Cell: ({ original: animal, value }) => {
                  return (
                    <NavLink to={`/rescue/${rescueId}/animals/${animal.id}`}>
                      {value}
                    </NavLink>
                  );
                },
                Header: () => <b>Name</b>,
                accessor: 'name',
                width: 150,
              },
              {
                Cell: ({ original: animal, value }) => {
                  const fosterParents = value || [{}];
                  const names = fosterParents.map((fosterParent) => {
                    return (
                      <div key={`${fosterParent.fosterId}${animal.id}`}>
                        <NavLink
                          to={`/rescue/${rescueId}/fosters/${fosterParent.fosterId}`}
                        >
                          {fosterParent.name}
                        </NavLink>
                      </div>
                    );
                  });
                  return <span>{names}</span>;
                },
                Header: () => <b>Foster Parents</b>,
                accessor: 'fosterParents',
                width: 150,
              },
              {
                Cell: ({ _, value }) => {
                  return <span>{value}</span>;
                },
                Header: () => <b>Foster Applications</b>,
                accessor: 'fosterApplicationCount',
                width: 180,
              },
              {
                Cell: ({ _, value }) => {
                  return value
                    ? `${new Date(value).toLocaleDateString()}, ${new Date(
                        value,
                      ).toLocaleTimeString()}`
                    : '';
                },
                Header: () => <b>Last Check-in</b>,
                accessor: 'lastCheckInTime',
                width: 180,
              },
              {
                Cell: ({ original: animal, value }) => {
                  return value
                    ? `${new Date(value).toLocaleDateString()}, ${new Date(
                        value,
                      ).toLocaleTimeString()}`
                    : '';
                },
                Header: () => <b>Estimated Return Date</b>,
                accessor: 'estimatedReturnDate',
                width: 200,
              },
              {
                Cell: ({ _, value }) => {
                  return <span>{statusMap[value] || value}</span>;
                },
                Header: () => <b>Status</b>,
                accessor: 'status',
                width: 200,
              },
            ]}
            loading={!loaded}
            showPagination={animals.length > 20}
            pageSize={animals.length > 20 ? 20 : animals.length}
          />
        </Container>
      </div>
    );
  }
}
export default AnimalListPage;
