import React from 'react';
import { Container } from 'reactstrap';

export default function CovidBanner() {
  return (
    <div className="bg-danger d-flex align-items-center px-3 py-2 text-white text-center">
      <Container className="px-0 px-md-3">
        {/* <h6 className="mb-0 d-flex justify-content-center align-items-center">
          Transporting a pet?
        </h6> */}
        <div className="fs-sm">
          We recommend an abundance of caution with regard to directly fostering
          pets with known COVID-19 exposure. You may have a shelter or boarding
          facility in your area specifically set up to temporarily help with
          these animals. If not, please carefully review the recommendations for
          sheltering these pets
          <a
            href="https://www.uwsheltermedicine.com/library/resources/animal-services-role-in-covid-19-support#foster"
            className="text-danger p-0 pl-1"
          >
            <u className="text-white font-weight-500">here</u>
          </a>
          .
          {/* Click
          <a
            href="/blogs/covid-transport-guidelines.html"
            className="text-danger p-0 pl-1"
          >
            <u className="text-white font-weight-500">here</u>
          </a>{' '}
          to view guidelines for safe transfer of pets during COVID-19 */}
        </div>
      </Container>
    </div>
  );
}
