import React, { PureComponent } from 'react';

import ErrorPage from '../pages/ErrorPage/ErrorPage';

interface Props {
  children: React.ReactElement;
}

interface State {
  error?: Error;
}

class ErrorHandler extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render(): React.ReactElement {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return children;
  }
}

export default ErrorHandler;
