import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import firebase from '../../Firestore';
import BasicAnimalInfo from '../../components/BasicAnimalInfo';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader';
import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard';
import { PUBLIC_API_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  RedditIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import '../../styles/ShareButton.css';
import AskAboutMeModal from '../../components/AskAboutMeModal/AskAboutMeModal';
import { Button } from '../../components';

const PublicAnimalPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [animal, setAnimal] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isAskAboutMeModalOpen, setIsAskAboutMeModalOpen] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const {
    match: { params },
  } = props;

  useMountEffect(() => {
    const db = firebase.firestore();

    const animalDoc = db.collection('publicAnimals').doc(params.animalid);

    animalDoc.get().then((data) => {
      if (data.data().publicUrl) {
        window.location.href = data.data().publicUrl;
      }
      setAnimal({
        ...data.data(),
        id: animalDoc.id,
      });
      setLoading(false);
    });
  });

  const closeFosterModal = (showThankYou2) => {
    if (showThankYou2 === true) {
      setShowThankYou(true);
    }
    setIsAskAboutMeModalOpen(false);
  };

  const showCopyLink = () => {
    return (
      <span className="mb-3 d-flex align-items-baseline">
        <div className="mr-3">
          <CopyToClipboard
            description="Want to help find this pet a temporary home? Copy this link or share"
            value={window.location.href}
          />
        </div>
        <div className="icons-inline">
          <FacebookShareButton
            url={window.location.href}
            quote={`Help us find a temporary home for ${animal.name}!`}
            className="social-media-share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div className="icons-inline">
          <TwitterShareButton
            url={window.location.href}
            quote={`Help us find a temporary home for ${animal.name}!`}
            className="social-media-share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className="icons-inline">
          <RedditShareButton
            url={window.location.href}
            quote={`Help us find a temporary home for ${animal.name}!`}
            className="social-media-share-button"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>
        </div>
      </span>
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (shouldRedirect) {
    return (
      <Redirect
        to={`/${animal.rescueId}/fosterSignUp?fosterAnimal=${animal.sourceId}`}
      />
    );
  }

  const fosterId = new URL(window.location.href).searchParams.get('fosterId');

  let action = () => {
    setShouldRedirect(true);
  };

  if (fosterId) {
    action = () =>
      requestService
        .fetch(`${PUBLIC_API_URL}/v1/signUpForRescues`, {
          body: JSON.stringify({
            potentialFosterId: fosterId,
            rescueIds: [animal.rescueId],
            animalId: animal.sourceId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
        .then(() => {
          setSuccess(true);
        });
  }

  if (animal.rescueGroupsOrgId) {
    action = () => {
      setIsAskAboutMeModalOpen(true);
    };
  }

  return (
    <Container className="py-3 py-sm-5">
      {showCopyLink()}
      {showThankYou ? (
        <div className="alert alert-info d-flex justify-content-between mb-3">
          <div className="alert-text">Thank you for your interest!</div>
        </div>
      ) : (
        <div />
      )}

      {!success && (
        <BasicAnimalInfo
          name={animal.name}
          species={animal.species}
          gender={animal.gender}
          breed={animal.breed}
          rescueName={animal.rescueName}
          ageMonths={animal.ageMonths}
          ageYears={animal.ageYears}
          imageUrl={animal.imageUrl}
          conditions={animal.conditions}
          rescueId={animal.rescueId}
          rescueGroupsId={animal.rescueGroupsOrgId}
          location={`${animal.city}, ${animal.state}`}
          actionLabel={animal.rescueGroupsOrgId ? 'Ask about me' : 'Foster me'}
          notes={animal.publicNotes}
          action={action}
        />
      )}

      <Modal
        className="bg-white p-3 p-md-4 border col-10 col-md-6 mx-auto my-5"
        isOpen={isAskAboutMeModalOpen}
        onRequestClose={closeFosterModal}
        contentLabel="Ask About Me"
        ariaHideApp={false}
      >
        <div>
          <Button
            variant="danger"
            size="sm"
            className="d-flex ml-auto mb-3"
            onClick={closeFosterModal}
          >
            Close
          </Button>
        </div>

        <AskAboutMeModal
          animal={animal}
          RGOrgId={animal.rescueGroupsOrgId}
          onClose={closeFosterModal}
        />
      </Modal>

      {success && <div>We let them know you are interested, thank you!</div>}
    </Container>
  );
};

export default PublicAnimalPage;
