import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faUserPlus,
  faSpinner,
  faPhone,
  faEnvelope,
  faGlobeAfrica,
  faExclamationTriangle,
  faBell,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowToBottom,
  faPlus,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faTrashAlt,
  faPen,
  faBroadcastTower,
  faSave,
  faComment,
  faFileContract,
  faEye,
  faArrowRight,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { faMobile, faExchange, faCopy } from '@fortawesome/pro-light-svg-icons';

library.add(
  // brands
  faInstagram,
  faFacebookF,
  faTwitter,
  faYoutube,

  // solid
  faUserPlus,
  faSpinner,
  faPhone,
  faEnvelope,
  faGlobeAfrica,
  faPen,
  faFileContract,
  faEye,
  faExclamationTriangle,

  // regular
  faArrowToBottom,
  faPlus,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faTrashAlt,
  faBroadcastTower,
  faSave,
  faComment,
  faCopy,
  faArrowRight,
  faTimes,
  faBell,

  // light
  faMobile,
  faExchange,
);
