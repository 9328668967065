import { Formik } from 'formik';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import { requestService } from '../../services/request.service';
import LocalBroadcastForm from './LocalBroadcastForm';
import { LocalBroadcastFormSchema } from './LocalBroadcastFormSchema';
import optinAreaMap from '../../assets/optinAreaMap.jpg';
import { API_URL } from '../../constants/urls.constant';

const initialValues = {
  message: '',
};

function LocalBroadcastPage() {
  const [success, setSuccess] = useState(false);
  console.log('here');
  async function submit(values, actions) {
    try {
      await requestService
        .fetch(`${API_URL}/v1/localBroadcast`, {
          body: JSON.stringify({ message: values.message }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        })
        .then(() => {
          setTimeout(async () => {
            setSuccess(true);
          }, 1000);
        })
        .catch(() => {
          actions.setError('Error');
          alert(
            'There was an error, please try again. Contact us if the issue continues.',
          );

          setSuccess(false);
        });
    } catch (err) {
      console.log(err);
      actions.setError('Error');
      alert(
        'There was an error, please try again. Contact us if the issue continues.',
      );

      setSuccess(false);
    } finally {
      actions.setSubmitting(false);
    }
  }

  return (
    <Container className="py-3 py-sm-5">
      <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
        <div className="mb-3">
          <div>
            <h3>Broadcast</h3>
            {/* <h5>There are 14,749 people looking to help nationwide</h5> */}
            <img
              src={optinAreaMap}
              width="80%"
              alt="United States Map of all contacts in the area"
            ></img>
          </div>
        </div>
        {success ? (
          <div>
            Your message has been sent for approval, we will notify you when it
            is sent
          </div>
        ) : (
          <Formik
            onSubmit={submit}
            initialValues={initialValues}
            validationSchema={LocalBroadcastFormSchema}
            component={LocalBroadcastForm}
          />
        )}
      </div>
    </Container>
  );
}

export default LocalBroadcastPage;
