import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import firebase from '../Firestore';
import Loader from './Loader';
import { useMountEffect } from '../hooks';
import { Button } from 'react-bootstrap';
import { API_URL } from '../constants/urls.constant';
import { requestService } from '../services/request.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShelterLuvImportCard = (props) => {
  const {
    match: { params },
  } = props;

  const [loading, setLoading] = useState(true);
  const [alreadyIntegrated, setAlreadyIntegrated] = useState(false);
  const [success, setSuccess] = useState(false);

  useMountEffect(() => {
    const db = firebase.firestore();

    db.collection('rescues')
      .doc(params.rescueid)
      .collection('integrations')
      .where('name', '==', 'Shelterluv')
      .get()
      .then((data) => {
        if (!data.empty) {
          setAlreadyIntegrated(true);
        }

        setLoading(false);
      });
  });

  const deleteIntegration = () => {
    setLoading(true);

    const db = firebase.firestore();

    db.collection('rescues')
      .doc(params.rescueid)
      .collection('integrations')
      .where('name', '==', 'Shelterluv')
      .get()
      .then((data) => {
        data.docs.forEach((doc) => {
          doc.ref.delete();
        });

        setAlreadyIntegrated(false);
        setLoading(false);
      });
  };

  const sync = () => {
    setLoading(true);

    requestService
      .fetch(`${API_URL}/v1/syncShelterluv`, {
        method: 'POST',
      })
      .then((data) => {
        setLoading(false);
        if (data.status === 200) {
          setSuccess(true);
        } else {
          alert('there was an error, please check your api key and try again');
        }
      })
      .catch((error) => {
        alert('Upload failed: ' + error);
      });
  };

  const showAddForm = () => {
    if (loading) {
      return <Loader />;
    }

    if (alreadyIntegrated) {
      return (
        <div>
          <div className="d-flex space-between">
            <Button onClick={deleteIntegration} variant="danger" size="sm">
              <FontAwesomeIcon icon={['far', 'trash-alt']} className="mr-2" />
              Delete Integration
            </Button>

            <Button onClick={sync} variant="primary" size="sm" className="ml-3">
              Sync
            </Button>
          </div>

          {success ? <div className="mt-3">Success!</div> : <div />}
        </div>
      );
    }

    return (
      <div>
        <Formik
          onSubmit={(values) => {
            const db = firebase.firestore();

            db.collection('rescues')
              .doc(params.rescueid)
              .collection('integrations')
              .add({ name: 'Shelterluv', apiKey: values.shelterLuvKey })
              .then(() => {
                setAlreadyIntegrated(true);
              });
          }}
          initialValues={{ shelterLuvKey: '' }}
        >
          {({ isSubmitting, status }) => (
            <div>
              {status}
              <Form>
                <div className="form-group">
                  <Field
                    className="mr-3 form-control w-50"
                    name="shelterLuvKey"
                    placeholder="Enter your api key"
                    type="password"
                  />
                </div>

                <Button
                  type="submit"
                  className="btn btn-primary text-white"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="border border-width-2 border-gray-400 p-3 mb-3">
      <h5 className="mb-3">From ShelterLuv ("In Custody" status)</h5>

      {showAddForm()}
    </div>
  );
};

export default ShelterLuvImportCard;
