import React from 'react';

import { requestService } from '../../../services/request.service';
import { ADMIN_URL } from '../../../constants/urls.constant';
import { Button } from '../../../components';
import firebase from '../../../Firestore';

class ScriptsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sucess: false,
      loading: false,
    };

    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async downloadP2PRescuesAsCSV() {
    console.log('here');
    const db = firebase.firestore();
    const publicAnimals = await db.collection('publicAnimals').get();
    console.log('optindo');
    var csv = 'name,city,state,zipCode,timeStamp\n';

    publicAnimals.docs.forEach(async (row, i) => {
      db.collection('rescues')
        .doc(row.get('rescueId'))
        .get()
        .then((rescue) => {
          console.log(rescue);
          csv += `${rescue.get('zipCode')}\n`;
        });
    });

    // optinsDoc.docs.forEach(async (row) => {
    //   row.data()
    // });
    // optinsDoc.docs.forEach(async (row) => {
    //   console.log(row.data());
    //   if (row.data().isRehomingUser) {
    //     console.log(
    //       `${row.get('city')},${row.get('state')}, ${row.get(
    //         'zipCode',
    //       )}, ${row.get('createdAt')}`,
    //     );
    //     csv += `${row.get('rescueName')}, ${row.get('city')}, ${row.get(
    //       'state',
    //     )}, ${row.get('zipCode')}, ${row.get('createdAt')}`;
    //     csv += '\n';
    //     // console.log(rescuesDoc.get('name'));
    //     // console.log(!rescuesDoc.data().isRehomingUser);
    //     // const userDocs = await row.ref.collection('users').get();
    //     // userDocs.forEach((userDoc) => {
    //     //   console.log(`${row.get('name')},${userDoc.data().emailAddress}`);
    //     //   csv += `${row.get('name')},${userDoc.data().emailAddress}`;
    //     //   csv += '\n';
    //     // });
    //   }
    // });
    console.log('awaiting');
    // await Promise.all(promiseList);
    await sleep(10000);

    console.log('here231');

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'petsInNeed.csv';
    hiddenElement.click();
    console.log(csv, 'here42');
  }

  async downloadOptinsAsCSV() {
    console.log('here');
    const db = firebase.firestore();
    const optinsDoc = await db.collection('optins').get();
    console.log('optindo');
    var csv = 'zipCode\n';

    // optinsDoc.docs.forEach(async (row) => {
    //   row.data()
    // });
    console.log(optinsDoc.docs.length, 'optinsDoc.docs.length');

    let zipcodecount = 0;
    let zipcount = 0;
    let notfoundcount = 0;
    for (let i = 0; i < optinsDoc.docs.length; i++) {
      console.log(i);

      if (optinsDoc.docs[i].get('zipCode')) {
        zipcodecount++;
        csv += `${optinsDoc.docs[i].get('zipCode')}, ${i}`;
        csv += '\n';
      } else if (optinsDoc.docs[i].get('zip')) {
        zipcount++;
        csv += `${optinsDoc.docs[i].get('zip')}, ${i}`;
        csv += '\n';
      } else {
        notfoundcount++;
        csv += '\n';
      }
    }

    console.log(
      'zipcodecount',
      zipcodecount,
      'zipcount',
      zipcount,
      'notfoundcount',
      notfoundcount,
    );

    // optinsDoc.docs.forEach(async (row, i) => {
    //   if (row.get('zipCode')) {
    //     // console.log(i);
    //     csv += `${row.get('zipCode')}, ${i}`;
    //     csv += '\n';
    //   } else if (row.get('zip')) {
    //     csv += `${row.get('zip')}, ${i}`;
    //     csv += '\n';
    //   } else {
    //     csv += `${row.id}, ${i}`;
    //     csv += '\n';
    //   }
    // });
    console.log('awaiting');
    // await Promise.all(promiseList);
    await sleep(100000);

    console.log('here231');

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'optins.csv';
    hiddenElement.click();
    // console.log(csv, 'here42');
  }

  async runImportFostersToAlgolia() {
    this.setState({ loading: true });
    requestService
      .fetch(`${ADMIN_URL}/v1/scripts/runImportFostersToAlgolia`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(() => {
        this.setState({ success: true });
      });
  }

  async createWPPostsForEachAnimal() {
    this.setState({ loading: true });
    requestService
      .fetch(`${ADMIN_URL}/v1/createWPPostsForEachAnimal`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(() => {
        this.setState({ success: true });
      });
  }

  async getFosterApplicationsForAnimal() {
    this.setState({ loading: true });
    requestService
      .fetch(`${ADMIN_URL}/v1/getFosterApplicationsForAnimal`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      })
      .then(() => {
        this.setState({ success: true });
      });
  }

  async sendMessageUsingAlgolia() {
    this.setState({ loading: true });
    requestService
      .fetch(`${ADMIN_URL}/v1/sendMessageUsingAlgolia`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lat: this.state.lat,
          lng: this.state.lng,
          distance: this.state.distance,
          message: this.state.message,
          indexName: this.state.indexName,
        }),
        method: 'POST',
      })
      .then(() => {
        this.setState({ success: true });
      });
  }

  render() {
    if (this.state.success) {
      return <div>success</div>;
    } else {
      return (
        <div className="broadcast-page">
          <Button
            loading={this.state.loading}
            onClick={this.runImportFostersToAlgolia.bind(this)}
          >
            runImportFostersToAlgolia
          </Button>
          <Button
            loading={this.state.loading}
            onClick={this.downloadP2PRescuesAsCSV.bind(this)}
          >
            Download people in need as CSV
          </Button>
          <Button
            loading={this.state.loading}
            onClick={this.downloadOptinsAsCSV.bind(this)}
          >
            Download optins as CSV
          </Button>
          <Button
            loading={this.state.loading}
            onClick={this.createWPPostsForEachAnimal.bind(this)}
          >
            createWPPostsForEachAnimal
          </Button>
          <Button
            loading={this.state.loading}
            onClick={this.getFosterApplicationsForAnimal.bind(this)}
          >
            getFosterApplicationsForAnimal
          </Button>

          <div>
            <div className="form-group">
              <label htmlFor="firstName">Lat</label>
              <input
                name="lat"
                className="form-control"
                placeholder="First Name"
                onChange={this.updateInput}
                value={this.state.lat}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Lng</label>
              <input
                name="lng"
                className="form-control"
                placeholder="Last Name"
                onChange={this.updateInput}
                value={this.state.lng}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">distance</label>
              <input
                name="distance"
                className="form-control"
                placeholder="Last Name"
                onChange={this.updateInput}
                value={this.state.distance}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">message</label>
              <input
                name="message"
                className="form-control"
                placeholder="Last Name"
                onChange={this.updateInput}
                value={this.state.message}
              />
            </div>
          </div>
          <Button
            loading={this.state.loading}
            onClick={this.sendMessageUsingAlgolia.bind(this)}
          >
            sendMessageUsingAlgolia
          </Button>
        </div>
      );
    }
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default ScriptsPage;
