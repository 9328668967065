import React from 'react';
import { Row, Col, Container } from 'reactstrap';
// import { KickerText } from './text';
import { SocialIcons } from '.';

export default function SpreadTheWordPanel() {
  return (
    <div className="bg-gray-100 py-4">
      <Container className="px-4 px-md-3">
        <Row>
          <Col xs="12" md="8">
            {/* <KickerText>Help Us</KickerText> */}
            <h1 className="text-left">About 911FosterPets</h1>
            <p className="mb-0">
              Our mission is to help save pets by seamlessly connecting animal
              shelters, rescue groups, and individual pet owners with local
              homes willing to foster a pet in need. You can help by signing up
              or spreading the word.
            </p>
          </Col>
          <Col xs="12" md="4">
            <SocialIcons />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
