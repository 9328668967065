import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PreviousButton = (props) => {
  const { disabled, onClick } = props;
  return (
    <Button
      color="primary"
      size="sm"
      disabled={disabled}
      onClick={onClick}
      className="rounded-0 w-100 h-100"
    >
      <FontAwesomeIcon icon={['far', 'long-arrow-alt-left']} className="mr-2" />
      Previous
    </Button>
  );
};

export default PreviousButton;
