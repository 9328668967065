import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router';
import { RESCUE_ADMIN_URL } from '../../constants/urls.constant';
import Table from '../../components/react-table/Table';
import './UserListPage.css';
import authService from '../../services/auth.service';

class UserListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loaded: false,
      redirectToEdit: false,
      userDocId: '',
    };

    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    const users = await fetch(`${RESCUE_ADMIN_URL}/v1/users`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
    }).then((res) => res.json());

    this.setState({
      users,
      loaded: true,
    });
  }

  confirmDeleteUser(user) {
    const r = window.confirm('Are you sure you want to delete this user?');
    if (r === true) {
      this.deleteUser(user);
    }
  }

  deleteUser(user) {
    fetch(`${RESCUE_ADMIN_URL}/v1/deleteUser`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
      method: 'POST',
      body: JSON.stringify({
        emailAddress: user.emailAddress,
        userId: user.id,
      }),
    }).then(() => {
      setTimeout(() => {
        this.setState({ loaded: false });
        this.fetchUsers();
      }, 1000);
    });
  }

  redirectUserId(user) {
    this.setState({
      userDocId: user.docId,
      redirectToEdit: true,
    });
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const { rescueid } = params;
    const { users, loaded, redirectToEdit, userDocId } = this.state;

    return (
      <div>
        <Container className="py-3 py-sm-5">
          <div className="d-flex justify-content-end mb-3">
            <NavLink to={`/rescue/${rescueid}/add-user`}>
              <Button color="primary" size="sm" className="text-white">
                <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
                Add New User
              </Button>
            </NavLink>
          </div>
          <Table
            data={users}
            columns={[
              {
                Cell: ({ original: user }) => {
                  return user.displayName;
                },
                Header: () => <b>Name</b>,
                accessor: 'displayName',
              },
              {
                Cell: ({ original: user }) => {
                  return user.emailAddress;
                },
                Header: () => <b>Email Address</b>,
                accessor: 'emailAddress',
              },
              {
                Cell: ({ original: user }) => {
                  return (
                    <>
                      <div>
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mr-3 mb-2 px-3"
                          onClick={() => this.redirectUserId(user)}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'pen']}
                            className="mr-2"
                          />
                          Edit User
                        </Button>
                      </div>

                      <div>
                        <Button
                          className="btn btn-danger btn-sm"
                          onClick={() => this.confirmDeleteUser(user)}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'trash-alt']}
                            className="mr-2"
                          />
                          Delete User
                        </Button>
                      </div>

                      {redirectToEdit && (
                        <Redirect
                          to={`/rescue/${rescueid}/users/${userDocId}/edit`}
                        />
                      )}
                    </>
                  );
                },
                width: 200,
                Header: () => <b>Actions</b>,
              },
            ]}
            loading={!loaded}
            showPagination={users.length > 10}
            pageSize={users.length > 10 ? 10 : users.length}
          />
        </Container>
      </div>
    );
  }
}
export default UserListPage;
