import firebase from 'firebase';
import 'firebase/auth';
import { Field, Form as FormikForm, Formik } from 'formik';
import React, { PureComponent } from 'react';
import * as yup from 'yup';

import './ForgotPasswordPage.css';

const initiaValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Required'),
});

class LoginPage extends PureComponent {
  errorTimeout;

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      success: false,
    };
  }

  componentWillUnmount() {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
  }

  async onSubmit({ email }, { setSubmitting }) {
    var auth = firebase.auth();

    await auth.sendPasswordResetEmail(email);
    this.setState({ success: true });

    setSubmitting(false);
  }

  render() {
    if (this.state.success) {
      return (
        <div className="text-center m-5">
          If there is an account with this email address an email has been sent
        </div>
      );
    }
    return (
      <div>
        <div className="login-page mt-2 mb-5 text-center">
          <h1>Forgot Password?</h1>
          <Formik
            initialValues={initiaValues}
            onSubmit={this.onSubmit.bind(this)}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, status }) => (
              <div>
                {status}
                <FormikForm className="mb-3">
                  <div className="form-group">
                    <Field
                      className="mr-3 form-control"
                      name="email"
                      placeholder="Email"
                      type="email"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary text-white"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </FormikForm>

                <div className="text-center">
                  Enter your email above to get help with your password. Not
                  working? Only organizations and individuals looking to place
                  their pet(s) in foster homes have the ability/need to log in
                  on this site.
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default LoginPage;
