import React from 'react';
import { Redirect } from 'react-router';
import Table from '../../../components/react-table/Table';
import firebase from '../../../Firestore';
import { Col, Row } from 'react-bootstrap';
import { Button } from '../../../components';

import './PublicAnimalMatchingPage.css';
import { requestService } from '../../../services/request.service';
import { ADMIN_URL } from '../../../constants/urls.constant';

class BroadcastPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fosters: [],
      optins: [],
      animalFosterApplication: [],
      animalInfo: {},
      loaded: false,
      recipients: [],
      rescueInfo: {},
      redirect: false,
      isSubmitting: false,
      message: '',
      subject: '',
    };

    this.sendMessageToPotentialFosters = this.sendMessageToPotentialFosters.bind(
      this,
    );

    this.sendMessageToCaretaker = this.sendMessageToCaretaker.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const db = firebase.firestore();
    const publicAnimalDoc = await db
      .collection('publicAnimals')
      .doc(params.animalid)
      .get();

    const animalInfo = publicAnimalDoc.data();

    this.setState({ animalInfo });

    requestService
      .fetch(
        `${ADMIN_URL}/v1/animals:getMatchingFosters?animalId=${params.animalid}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const fosters = [];
        console.log('data fsoters', data);
        data.fosters.forEach((foster) => {
          if (
            foster.acceptedSpecies.includes(animalInfo.species) ||
            foster.acceptedSpecies.includes('both')
          ) {
            fosters.push(foster);
          }
        });

        this.setState({ fosters, loaded: true, rescueInfo: data.rescueInfo });
      });

    requestService
      .fetch(`${ADMIN_URL}/v1/getMatchingOptins?animalId=${params.animalid}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log('data optins', data);

        const optins = [];
        data.optins.forEach((foster) => {
          optins.push(foster);
        });

        this.setState({
          optins,
          loaded: true,
          rescueInfo: data.rescueInfo,
        });
      });
  }

  showRedirect() {
    const {
      match: { params },
    } = this.props;

    if (this.state.redirect) {
      return (
        <Redirect
          to={`/rescue/${params.rescueid}/animals/${params.animalid}`}
        />
      );
    }
  }

  sendMessageToPotentialFosters() {
    const { message, subject } = this.state;
    this.setState({ isSubmitting: true });
    const {
      match: { params },
    } = this.props;

    return requestService
      .fetch(`${ADMIN_URL}/v1/sendCustomMessageForAnimal`, {
        body: JSON.stringify({
          subject,
          message,
          animalId: params.animalid,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((data) => {
        if (data.ok) {
          alert('success');
        } else {
          alert('error');
        }
      })
      .catch((e) => {
        alert(e);
      });
  }

  sendMessageToCaretaker() {
    const { message, subject } = this.state;
    this.setState({ isSubmitting: true });
    const {
      match: { params },
    } = this.props;

    return requestService
      .fetch(`${ADMIN_URL}/v1/sendMessage`, {
        body: JSON.stringify({
          subject,
          message,
          email: this.state.rescueInfo.email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((data) => {
        if (data.ok) {
          alert('success');
        } else {
          alert('error');
        }
      })
      .catch((e) => {
        alert(e);
      });
  }

  render() {
    const { fosters, loaded, isSubmitting } = this.state;

    return (
      <div className="broadcast-page">
        {this.showRedirect()}

        <div className="md-3">
          {`Below are the fosters in ${this.state.rescueInfo.state} looking to help a ${this.state.animalInfo.species} that could help ${this.state.animalInfo.name}, the results are sorted by distance`}
        </div>

        <div className="md-3">
          <div>
            <div>{this.state.rescueInfo.address1}</div>
            <div>{this.state.rescueInfo.address2}</div>
            <div>{this.state.rescueInfo.city}</div>
            <div>{this.state.rescueInfo.state}</div>
            <div>{this.state.rescueInfo.zipCode}</div>
          </div>

          <div>
            <div>{this.state.rescueInfo.phoneNumber}</div>{' '}
            <div>{this.state.rescueInfo.email}</div>
          </div>
        </div>

        <div>
          <fieldset disabled={isSubmitting}>
            <Row>
              <Col>
                <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400 mb-3">
                  <h5>Broadcast to those below</h5>
                  Email subject
                  <input
                    className="form-control mb-3 w-100"
                    label="Email Subject"
                    name="emailSubject"
                    onChange={(e) => this.setState({ subject: e.target.value })}
                    placeholder="Hello! We need help finding a home for X"
                  />
                  Message
                  <textarea
                    className="form-control mb-3 w-100"
                    label="Message"
                    name="message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                    placeholder="Tell us your ideas"
                  />
                  <Button
                    loading={isSubmitting}
                    color="primary"
                    type="submit"
                    onClick={this.sendMessageToPotentialFosters}
                  >
                    Send Message
                  </Button>
                </div>
              </Col>

              <Col>
                <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400 mb-3">
                  <h5>Message caretaker</h5>
                  Email subject
                  <input
                    className="form-control mb-3 w-100"
                    label="Email Subject"
                    name="emailSubject"
                    onChange={(e) => this.setState({ subject: e.target.value })}
                    placeholder="Hello! We need help finding a home for X"
                  />
                  Message
                  <textarea
                    className="form-control mb-3 w-100"
                    label="Message"
                    name="message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                    placeholder="Hello! We need help finding a home for X"
                  />
                  <Button
                    loading={isSubmitting}
                    color="primary"
                    type="submit"
                    onClick={this.sendMessageToCaretaker}
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </fieldset>
        </div>

        <Table
          data={fosters}
          columns={[
            {
              Cell: ({ original: foster }) => {
                return (
                  <div>
                    {foster.firstName} {foster.lastName}
                  </div>
                );
              },
              Header: () => <b>Name</b>,
              accessor: 'firstName',
              width: 200,
            },
            {
              Cell: ({ original: foster }) => {
                return (
                  <div>
                    <div>{foster.phoneNumber}</div>{' '}
                    <div>{foster.emailAddress}</div>
                  </div>
                );
              },
              Header: () => <b>Contact Info</b>,
              accessor: 'firstName',
              width: 200,
            },
            {
              Cell: ({ original: foster }) => {
                return (
                  <div>
                    <div>{foster.streetAddress1}</div>
                    <div>{foster.streetAddress2}</div>
                    <div>{foster.city}</div>
                    <div>{foster.state}</div>
                    <div>{foster.zipCode}</div>
                  </div>
                );
              },
              Header: () => <b>Address</b>,
              accessor: 'firstName',
              width: 200,
            },
          ]}
          loading={!loaded}
          showPagination={false}
          pageSize={fosters.length}
        />

        <div>
          {this.state.optins.map((optin) => {
            return <div>{optin.emailAddress}</div>;
          })}
        </div>
      </div>
    );
  }
}
export default BroadcastPage;
