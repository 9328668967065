import React from 'react';
import FosterListRow from './FosterListRow';

const AnimalPageFosterList = (props) => {
  const { fosters, rescueId, placeAnimal } = props;

  if (fosters.length < 1) {
    return <div />;
  }

  return (
    <>
      <h4>Who has applied to foster this animal?</h4>
      <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
        {fosters.map((foster) => {
          if (foster.firstName) {
            return (
              <FosterListRow
                foster={foster}
                key={foster.fosterId}
                rescueId={rescueId}
                placeAnimal={placeAnimal}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
};

export default AnimalPageFosterList;
