import { Formik } from 'formik';
import React from 'react';

import AskAboutMeModalForm from './AskAboutMeModalForm';
import { AskAboutMeModalFormSchema } from './AskAboutMeModalFormSchema';
import { PUBLIC_API_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';

function AskAboutMeModal(props) {
  const initialValues = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    state: '',
    communicationPreference: '',
    zip: '',
    notes: '',
  };
  async function onSubmit(values, actions) {
    actions.setSubmitting(true);
    requestService
      .fetch(`${PUBLIC_API_URL}/v1/ask-about-animal`, {
        body: JSON.stringify({
          animal: props.animal,
          personInfo: values,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((_data) => {
        props.onClose(true);
      })
      .catch((errors) => {
        actions.setErrors(errors);
      });
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={AskAboutMeModalFormSchema}
      component={AskAboutMeModalForm}
    />
  );
}

export default AskAboutMeModal;
