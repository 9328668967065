import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Container } from 'reactstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '.';

import { FosterPetsLogo } from '../assets/logos';

const GlobalNavigation = ({ user }) => {
  const history = useHistory();

  const decodedUser = JSON.parse(localStorage.getItem('user'));
  let rescueId;

  if (decodedUser && decodedUser.rescue) {
    rescueId = decodedUser.rescue;
  }

  const Logo = () => {
    return (
      <img
        src={FosterPetsLogo}
        alt="911FosterPets logo with text, paw and blue background."
        style={{ width: '150px' }}
      />
    );
  };

  return (
    <div>
      <Navbar
        bg="white"
        variant="light"
        className="border-bottom py-2 py-md-3"
        style={{ overflowX: 'scroll' }}
      >
        <Container className="px-0 px-md-3">
          <a className="nav-link" href="https://911FosterPets.com">
            <Logo />
          </a>
          {/* 
            TODO: finish this
          <div className="fs-xs">
            <span className="mr-1">
              RescueFoster is now a part of 911FosterPets!
            </span>
            <Link
              className="fs-xs font-weight-500"
              to="/blog/rescue-foster-joins-911fosterpets"
            >
              <u>Learn more</u>
            </Link>
          </div> */}
          <Nav className="mr-auto">
            {!user && (
              <>
                <Nav.Item>
                  <a className="nav-link" href="https://911FosterPets.com">
                    Home
                  </a>
                </Nav.Item>

                <Nav.Item>
                  <NavLink className="nav-link" exact to="/animals">
                    Pet Search
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <a className="nav-link" href="https://911FosterPets.com/blog">
                    Blog
                  </a>
                </Nav.Item>

                <Nav.Item>
                  <a
                    className="nav-link"
                    href="https://911FosterPets.com/fostering-faq"
                  >
                    Fostering FAQ
                  </a>
                </Nav.Item>
              </>
            )}

            {user && (
              <>
                <Nav.Item>
                  <NavLink
                    className="nav-link"
                    to={`/rescue/${rescueId}/animals`}
                  >
                    Animals
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink
                    className="nav-link"
                    to={`/rescue/${rescueId}/fosters`}
                  >
                    Foster Parents
                  </NavLink>
                </Nav.Item>
                {user && user.isRescueAdmin && (
                  <Nav.Item>
                    <NavLink
                      className="nav-link"
                      to={`/rescue/${rescueId}/users`}
                    >
                      Users
                    </NavLink>
                  </Nav.Item>
                )}
                {user && !user.isRehomingUser && (
                  <Nav.Item>
                    <NavLink
                      className="nav-link"
                      to={`/rescue/${rescueId}/localBroadcast`}
                    >
                      Local Broadcasting
                    </NavLink>
                  </Nav.Item>
                )}
                {user && user.isAdmin && (
                  <Nav.Item>
                    <NavLink className="nav-link" to={'/admin/general'}>
                      Admin
                    </NavLink>
                  </Nav.Item>
                )}
              </>
            )}
          </Nav>

          <Nav className="ml-auto">
            {user ? (
              <>
                {!user.isRehomingUser && (
                  <Nav.Item>
                    <NavLink
                      className="nav-link"
                      to={`/rescue/${rescueId}/import`}
                    >
                      Import
                    </NavLink>
                  </Nav.Item>
                )}

                <Nav.Item>
                  <NavLink
                    className="nav-link"
                    to={`/rescue/${rescueId}/settings`}
                  >
                    Settings
                  </NavLink>
                </Nav.Item>

                <Nav.Item>
                  <NavLink className="nav-link" to={`/logout`}>
                    Log Out
                  </NavLink>
                </Nav.Item>
              </>
            ) : (
              <>
                <Nav.Item>
                  <NavLink className="nav-link mr-2" to={`/login`}>
                    Sign In
                  </NavLink>
                </Nav.Item>

                <Button
                  color="primary"
                  size="sm"
                  onClick={() => history.push('/foster-signup')}
                >
                  Become a Foster
                </Button>
              </>
            )}
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default GlobalNavigation;
