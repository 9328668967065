import * as yup from 'yup';

const AddAnimalFosterRelationshipSchema = yup.object().shape({
  animalId: yup.string().required('Required'),
  fosterId: yup.string().required('Required'),
  startDate: yup.string().required('Required'),
  estimatedReturnDate: yup.string().required('Required'),
  primaryContact: yup.string(),
  checkInFrequency: yup.string().required('Required'),
});

export { AddAnimalFosterRelationshipSchema };
