import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { BlogEntryCard } from '.';
import { PageHeaderHalfBackground } from '../../components/pages';

export default function BlogPage() {
  return (
    <PageHeaderHalfBackground title="Blog">
      <Container>
        <Row className="mb-3">
          <Col className="mb-3">
            <BlogEntryCard
              title="Guidelines for Safe Transfer of Pets During the COVID-19 Crisis"
              author="Randal Brookins"
              authorInfo="CTO and Founder of 911FosterPets"
              preview="We recommend temporary foster homes pick up their foster pets in a mutually agreed upon public space that minimizes contact between people to the greatest extent possible and follows CDC guidelines on how to protect oneself.  According to the CDC, there is no evidence at this time that pets can spread COVID-19. If your foster pet came from a home with a Covid-positive human, the pet should be kept separated from other animals in the foster home to the extent possible for 14 days out of an abundance of caution."
              path="/blogs/covid-transport-guidelines.html"
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="mb-3">
            <BlogEntryCard
              title="Why We Are Here"
              author="Randal Brookins"
              authorInfo="President/Owner, 911fosterpets.com"
              preview="We are here to save lives. Even to myself it sounds absurd sometimes, or at least a little grandiose. Like many I want to do good"
              path="/blogs/why-we-are-here.html"
            />
          </Col>

          <Col className="mb-3">
            <BlogEntryCard
              title="Do More Good with Technology"
              author="Bradley Hartwig"
              authorInfo="Product Manager, Keap | Technology Evangelist"
              preview="Since the first primitive man counted sheep with a stick, technology
              has been helping people solve their problems. For the shepherd, it
              helped him know if any of"
              path="/blogs/do-more-good-with-technology.html"
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col className="mb-3">
            <BlogEntryCard
              title="Fostering Animals is Free! Tax Deductions for Fostering"
              author="Anan Aziz"
              authorInfo="Software Engineering Intern, 911fosterpets.com"
              preview="Thanks to a June 2011 ruling by a U.S. Tax Court Judge, if you foster
              or rescue an animal, your expenses from things like pet supplies, the
              veterinary bills, cleaning supplies"
              path="/blogs/fostering-animals-is-free-tax-deduction-for-fostering.html"
            />
          </Col>
        </Row>
      </Container>
    </PageHeaderHalfBackground>
  );
}
