import { Form as FormikForm, Field } from 'formik';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../../components';

const AddAnimalForm = ({ ...formikProps }) => {
  const { errors, touched, handleChange, isValid, isSubmitting } = formikProps;

  return (
    <FormikForm>
      <fieldset disabled="">
        <Row>
          <Col md="8" className="mx-auto mb-3">
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              <h5 className="mb-3">Fostering Agreement</h5>
              <h6>
                This is where you can customize the linked terms when an
                individual signs up to foster a pet
              </h6>

              <Field
                component="textarea"
                className="form-control mb-3"
                label="Notes"
                errors={errors}
                name="agreement"
                onChange={handleChange}
                placeholder="IE: By submitting you agree that the rescue, shelter, or owner retains all rights and ownership of any animals in the foster program. The rescue or shelter has the right to recall a fostered animal at any time."
                touched={touched}
                type="textarea"
              />

              <Button
                loading={isSubmitting}
                color="primary"
                disabled={!isValid}
                type="submit"
              >
                Update Agreement
              </Button>
            </div>
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AddAnimalForm;
