import { Formik } from 'formik';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';

import { useMountEffect } from '../../hooks';
import AdditionalFosterQuestionsForm from './AdditionalFosterQuestionsForm';
import firebase from '../../Firestore';
import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard';
import { WEBSITE_URL } from '../../constants/urls.constant';

let AdditionalFosterQuestionsFormSchema = yup.object().shape({});

const AdditionalFosterQuestions = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const { rescueid } = props.match.params;

  useMountEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const db = firebase.firestore();

    const {
      match: { params },
    } = props;

    let questions = [];
    let yupSchema = {};
    let initialValuesToSet = {};

    if (user) {
      const fosterDoc = db
        .collection('rescues')
        .doc(params.rescueid)
        .collection('fosters')
        .doc(params.fosterid);

      fosterDoc.get().then((fosterData) => {
        const additionalInfo = fosterData.get('additionalInfo');

        db.collection('rescues')
          .doc(params.rescueid)
          .collection('additionalFosterQuestions')
          .get()
          .then((data) => {
            let index = 0;
            data.forEach((value) => {
              const question = value.get('question');
              const type = value.get('type');
              questions.push({ question, type });
              yupSchema[question] = yup.string().required('Required');

              initialValuesToSet[question] =
                additionalInfo && additionalInfo[index]
                  ? additionalInfo[index].answer
                  : '';

              index++;
            });
            AdditionalFosterQuestionsFormSchema = yup.object().shape(yupSchema);
            initialValuesToSet['questions'] = questions;
            setInitialValues(initialValuesToSet);
          });
      });
    } else {
      db.collection('rescues')
        .doc(params.rescueid)
        .collection('additionalFosterQuestions')
        .get()
        .then((data) => {
          data.forEach((value) => {
            const question = value.get('question');
            const type = value.get('type');
            questions.push({ question, type });
            yupSchema[question] = yup.string().required('Required');

            initialValuesToSet[question] = '';
          });
          AdditionalFosterQuestionsFormSchema = yup.object().shape(yupSchema);
          initialValuesToSet['questions'] = questions;
          setInitialValues(initialValuesToSet);
        });
    }
  });

  if (redirect) {
    return <Redirect to={`/rescue/${rescueid}/fosters`} />;
  }

  if (showThankYou) {
    return (
      <div>
        <div>Thank you for signing up to foster with us!</div>
        <CopyToClipboard
          description="Want your friend to be a foster parent too? Copy and send them this link."
          value={`${WEBSITE_URL}/4/fosterSignUp`}
        />
      </div>
    );
  }

  if (initialValues) {
    return (
      <Container className="py-3 py-sm-5">
        <Formik
          onSubmit={(inputs) => {
            const db = firebase.firestore();

            const {
              match: { params },
            } = props;
            let additionalInfo = [];

            delete inputs.questions;

            Object.keys(inputs).forEach((key) => {
              additionalInfo.push({ question: key, answer: inputs[key] });
            });

            db.collection('rescues')
              .doc(params.rescueid)
              .collection('fosters')
              .doc(params.fosterid)
              .update({ additionalInfo });

            if (localStorage.getItem('user')) {
              setRedirect(true);
            } else {
              setShowThankYou(true);
            }
          }}
          initialValues={initialValues}
          validationSchema={AdditionalFosterQuestionsFormSchema}
          component={AdditionalFosterQuestionsForm}
        />
      </Container>
    );
  } else {
    return <div>loading</div>;
  }
};

export default AdditionalFosterQuestions;
