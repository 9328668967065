import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import ExitSurveyPageImage from '../../assets/ExitSurveyPageImage.png';
import './AnimalDeletePage.css';
import { Button } from 'react-bootstrap';
import { requestService } from '../../services/request.service';
import { API_URL } from '../../constants/urls.constant';
import { useParams } from 'react-router-dom';
import authService from '../../services/auth.service';
import { LabelField } from '../../components/formik';

const AnimalDeletePage = (props) => {
  const [deleteReason, setDeleteReason] = useState(undefined);
  const [showOtherReason, setShowOtherReason] = useState(undefined);
  const [otherReason, setOtherReason] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [showThankYouPage, setShowThankYouPage] = useState(false);

  const { animalid } = useParams();

  const deleteAnimal = () => {
    let localDeleteReason = deleteReason;
    if (deleteReason === 'Other') {
      localDeleteReason = otherReason;
    }

    requestService
      .fetch(`${API_URL}/v1/animals:deleteAnimal`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          animalId: animalid,
          message,
          deleteReason: localDeleteReason,
          rescueId: authService.user.rescue,
          date: new Date().toLocaleDateString(),
        }),
      })
      .then(() => {
        setShowThankYouPage(true);
        console.log('success');
      });
  };

  const handleChange = (e) => {
    setDeleteReason(e.target.value);

    if (e.target.value === 'Other') {
      setShowOtherReason(true);
    } else {
      setShowOtherReason(false);
    }
  };

  return showThankYouPage ? (
    <Container className="mt-3">
      <img
        src={ExitSurveyPageImage}
        alt={'Thank you header'}
        className="img-fluid mb-3"
      />

      <h5 className="mb-3">
        Your pet’s profile has been permanently deleted. Thank you for using
        911FosterPets. Please spread the word so we can help more pets, thank
        you! www.911fosterpets.com
      </h5>
    </Container>
  ) : (
    <Container className="mt-3">
      <div>
        <h5 className="mb-3">
          Please answer the following questions and hit “Submit” to complete
          your pet’s profile deletion.
        </h5>

        <h6 className="mb-3">
          Why are you deleting your pet’s profile? Please choose one answer
          below.<small class="text-danger ml-1">*</small>
        </h6>

        <div className="radio-boxes">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="communicationPreference"
              value="Pet was fostered through 911FosterPets"
              onChange={handleChange}
            />
            <label className="form-check-label">
              Pet was fostered by someone through 911FosterPets
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="communicationPreference"
              value="Pet was fostered/adopted by a shelter/rescue"
              onChange={handleChange}
            />
            <label className="form-check-label">
              Pet was fostered/adopted by a shelter/rescue
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="communicationPreference"
              value="Pet was fostered/adopted by someone else"
              onChange={handleChange}
            />
            <label className="form-check-label">
              Pet was fostered/adopted by someone else
            </label>
          </div>
          {/* <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="communicationPreference"
              value="Was not able to find a foster/adopter"
              onChange={handleChange}
            />
            <label className="form-check-label">
              Was not able to find a foster/adopter
            </label>
          </div> */}
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="communicationPreference"
              value="Other"
              onChange={handleChange}
            />
            <label className="form-check-label mb-3">Other</label>
          </div>
        </div>
        {showOtherReason && (
          <div>
            <h6 className="mb-3">
              Please share the reason for deleting the profile.
            </h6>
            <textarea
              className="form-control mb-3 my-text-area"
              label="Message"
              name="message"
              onChange={(e) => setOtherReason(e.target.value)}
              placeholder=""
            />
          </div>
        )}
        <div>
          <h6 className="mb-3">
            We love feedback! Is there anything else we could have done better?
          </h6>
          <textarea
            className="form-control mb-3 my-text-area"
            label="Message"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Help us improve!"
          />
        </div>
        <Button
          variant="success"
          size="sm"
          className="mb-3 mr-3 mb-lg-0 mt-xl-0"
          onClick={deleteAnimal}
          disabled={!deleteReason}
          style={{ color: 'white' }}
        >
          Submit
        </Button>
      </div>
    </Container>
  );
};

export default AnimalDeletePage;
