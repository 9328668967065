import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export default function LabelField(props) {
  const { labelName, fieldName, required, optional } = props;
  return (
    <Form.Label className="font-weight-500 fs-sm mb-1" htmlFor={fieldName}>
      {labelName}
      {required && <small className="text-danger ml-1">*</small>}
      {optional && <small className="text-muted ml-1">{`(optional)`}</small>}
    </Form.Label>
  );
}

LabelField.propTypes = {
  fieldName: PropTypes.string,
  labelName: PropTypes.string,
  optional: PropTypes.bool,
  required: PropTypes.bool,
};

LabelField.defaultProps = {
  fieldName: '',
  labelName: '',
  optional: false,
  required: false,
};
