import React, { PureComponent } from 'react';
import { Accordion, Badge, Button, Card, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from '../../Firestore';
import { ADMIN_URL } from '../../constants/urls.constant';
import authService from '../../services/auth.service';

import './PotentialRescuesPage.css';

class AdminPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      potentialRescues: [],
    };

    this.fetchPotentialRescues = this.fetchPotentialRescues.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  componentDidMount() {
    this.fetchPotentialRescues();
  }

  async fetchPotentialRescues() {
    const db = firebase.firestore();

    db.collection('potentialRescues')
      .get()
      .then((data) => {
        const potentialRescuesList = data.docs.map((doc) => {
          if (doc.data()) {
            const potentialRescue = doc.data();
            potentialRescue.id = doc.id;
            potentialRescue.key = doc.id;
            return potentialRescue;
          }

          return null;
        });

        this.setState({
          potentialRescues: potentialRescuesList,
        });
      });
  }

  addUser() {
    const { history } = this.props;

    history.push('/admin/add-user');
  }

  confirmAcceptRescue(rescue) {
    const r = window.confirm('Are you sure you want to ACCEPT this rescue?');
    if (r === true) {
      this.acceptRescue(rescue);
    }
  }

  confirmRejectRescue(user) {
    const r = window.confirm('Are you sure you want to REJECT this rescue?');
    if (r === true) {
      this.rejectRescue(user);
    }
  }

  acceptRescue(rescue) {
    fetch(`${ADMIN_URL}/v1/potential-rescues/accept`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
      method: 'POST',
      body: JSON.stringify({
        ...rescue,
      }),
    })
      .catch((e) => {
        console.log(e);
        alert(
          'there was an uncaught error, this may cause issues approving the rescue again, let Randy know',
        );
      })
      .then((res) => {
        if (res) {
          return res.text();
        }
      })
      .then((data) => {
        if (data === 'RescueAlreadyFound') {
          alert('rescue already exists, you should be okay to reject');
        }

        if (data === 'EmailAlreadyFound') {
          alert('user with email already found, let admin(Randy) know');
        }

        this.fetchPotentialRescues();
      });
  }

  rejectRescue(rescue) {
    fetch(`${ADMIN_URL}/v1/potential-rescues/reject`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
      method: 'POST',
      body: JSON.stringify({
        ...rescue,
      }),
    }).then(() => {
      this.fetchPotentialRescues();
    });
  }

  renderUsers() {
    const { potentialRescues } = this.state;

    if (potentialRescues.length === 0) {
      return null;
    }

    return (
      <Accordion>
        {potentialRescues.map((user) => (
          <Card key={user.email}>
            <Card.Header>
              <Col xs="auto">
                <Card.Title>{user.rescue}</Card.Title>
              </Col>

              <Col xs="auto">
                <h4>
                  {user.isAdmin ? (
                    <Badge variant="secondary">Admin</Badge>
                  ) : null}
                  {user.isRescueAdmin ? (
                    <Badge variant="secondary">Rescue Admin</Badge>
                  ) : null}
                  {user.disabled ? (
                    <Badge variant="danger">Disabled</Badge>
                  ) : null}
                </h4>
              </Col>

              <Col xs="auto" id="col-expand">
                <button
                  className="btn btn-primary mr-3"
                  onClick={() => this.confirmAcceptRescue(user)}
                >
                  <FontAwesomeIcon
                    icon={['far', 'trash-alt']}
                    className="mr-2"
                  />
                  Accept
                </button>

                <button
                  className="btn btn-danger mr-3"
                  onClick={() => this.confirmRejectRescue(user)}
                >
                  <FontAwesomeIcon
                    icon={['far', 'trash-alt']}
                    className="mr-2"
                  />
                  Reject
                </button>

                <Accordion.Toggle
                  className="btn btn-secondary"
                  as={Button}
                  eventKey={user.uid}
                >
                  +
                </Accordion.Toggle>
              </Col>
            </Card.Header>

            <Accordion.Collapse eventKey={user.uid}>
              <Card.Body>
                <Card.Subtitle>
                  <p>website: {user.website}</p>
                </Card.Subtitle>

                <Card.Subtitle>
                  <p>email: {user.email}</p>
                </Card.Subtitle>

                <Card.Subtitle>
                  <p>phone number: {user.phoneNumber}</p>
                </Card.Subtitle>

                <Card.Subtitle>
                  <p>state: {user.state}</p>
                </Card.Subtitle>

                <Card.Subtitle>
                  <p>notes: {user.notes}</p>
                </Card.Subtitle>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  }

  render() {
    return <div className="mx-5 mb-5">{this.renderUsers()}</div>;
  }
}

export default AdminPage;
