import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Redirect } from 'react-router';
import Loader from '../../components/Loader';

import PlaceholderText from '../../components/PlaceholderText';
import BackButton from '../../components/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from '../../Firestore';
import Modal from 'react-modal';
import AddAnimalFosterRelationshipModal from '../../components/AddAnimalFosterRelationshipForm/AddAnimalFosterRelationshipModal';
import FosterAnimalList from '../../components/FosterAnimalList/FosterAnimalList';
import { CONDITIONSMAP } from '../../constants/conditions.constant';

class FosterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fosterDoc: {},
      fosterInfo: {},
      loaded: false,
      shouldRedirect: false,
      redirectToEdit: false,
      isAddFosterModalOpen: false,
    };

    this.deleteFoster = this.deleteFoster.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.openFosterModal = this.openFosterModal.bind(this);
    this.closeFosterModal = this.closeFosterModal.bind(this);
    this.load = this.load.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({ loaded: false });
    const db = firebase.firestore();

    const {
      match: { params },
    } = this.props;

    const fosterDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('fosters')
      .doc(params.fosterid);

    await fosterDoc.get().then((data) => {
      this.setState({
        fosterDoc,
        fosterInfo: data.data(),
        loaded: true,
      });
    });
  }

  handleStatusChange(e) {
    this.state.fosterDoc.update({
      ...this.state.fosterInfo,
      status: e.target.value,
    });
  }

  deleteFoster() {
    const r = window.confirm('Are you sure you want to delete this foster?');
    if (r === true) {
      this.state.fosterDoc
        .update({ ...this.state.fosterInfo, status: 'archived' })
        .then(() => {
          this.setState({ shouldRedirect: true });
        });
    }
  }

  openFosterModal(animalId) {
    this.setState({
      isAddFosterModalOpen: true,
      animalIdForFosterModal: animalId,
    });
  }

  closeFosterModal() {
    this.load();
    this.setState({ isAddFosterModalOpen: false });
  }

  showAdditionalInfo() {
    if (
      this.state.fosterInfo.additionalInfo &&
      Array.isArray(this.state.fosterInfo.additionalInfo)
    ) {
      const additionalInfo = this.state.fosterInfo.additionalInfo.map(
        (question) => {
          return (
            <Form.Group key={question.question}>
              <Form.Label className="font-weight-bold mb-0">
                {question.question}
              </Form.Label>
              <br />
              {question.answer}
            </Form.Group>
          );
        },
      );

      return (
        <div>
          {additionalInfo}
          <hr />
        </div>
      );
    }
  }

  render() {
    const {
      loaded,
      shouldRedirect,
      redirectToEdit,
      animalIdForFosterModal,
    } = this.state;
    const {
      firstName,
      lastName,
      conditions: mappedConditions,
      emailAddress,
      phoneNumber,
      address1,
      address2,
      city,
      state,
      zip,
      notes,
      fosterAnimals,
      status,
    } = this.state.fosterInfo;

    const { rescueid, fosterid } = this.props.match.params;
    return (
      <Container className="my-3 my-md-5 py-3 py-md-5">
        <Row>
          <Col md="12" className="mx-auto">
            <BackButton url={`/rescue/${rescueid}/fosters`}>
              Back To Fosters
            </BackButton>
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              {!loaded ? (
                <Loader />
              ) : (
                <div>
                  <div className="mb-3">
                    <div className="mb-4">
                      <h3 className="mb-0">{`${firstName} ${lastName}`}</h3>
                      <div className="text-muted small">
                        Foster ID: {fosterid}
                      </div>
                    </div>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-0">
                        Status
                      </Form.Label>
                      <br />
                      <select
                        name="status"
                        className="form-control"
                        onChange={this.handleStatusChange}
                        defaultValue={status}
                      >
                        <option value="unverified">Unknown/Unverified</option>
                        <option value="available">Available</option>
                        <option value="fostering">
                          Actively Fostering (Not at Capacity)
                        </option>
                        <option value="fosteringatmax">
                          Actively Fostering (At Capacity)
                        </option>
                        <option value="unavailable">Unavailable</option>
                        <option value="archived">Archived</option>
                      </select>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-1">
                        Current Foster Animals
                      </Form.Label>
                      <br />

                      <FosterAnimalList
                        fosterAnimals={fosterAnimals || []}
                        afterRemove={this.load}
                        fosterName={`${firstName} ${lastName}`}
                        rescueId={rescueid}
                        fosterId={fosterid}
                        onClickAdd={(animalId, fosterId) =>
                          this.openFosterModal(animalId, fosterId)
                        }
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-0">
                        Conditions
                      </Form.Label>
                      <br />
                      {firstName} is{' '}
                      <b>
                        <i>NOT</i>
                      </b>{' '}
                      willing to foster animals that:
                      <ul className="small">
                        {mappedConditions.map((condition) => {
                          return (
                            <li key={condition}>{CONDITIONSMAP[condition]}</li>
                          );
                        })}
                      </ul>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-0">
                        Email
                      </Form.Label>
                      <br />
                      {emailAddress}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-0">
                        Phone
                      </Form.Label>
                      <br />
                      {phoneNumber}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-0">
                        Address
                      </Form.Label>
                      <br />{' '}
                      {' ' + address2
                        ? address1 + '\n   ' + address2
                        : address1}
                      {city} {state}
                      {' ' + zip}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="font-weight-bold mb-0">
                        Notes
                      </Form.Label>
                      <br />
                      {notes || <PlaceholderText>No notes</PlaceholderText>}
                    </Form.Group>

                    <hr />

                    {this.showAdditionalInfo()}

                    <Form.Group className="mb-0">
                      <Form.Label className="font-weight-bold">
                        Actions
                      </Form.Label>
                      <br />

                      <Button
                        variant="secondary"
                        size="sm"
                        className="mr-3"
                        onClick={() => this.setState({ redirectToEdit: true })}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'pen']}
                          className="mr-2"
                        />
                        Edit Foster
                      </Button>

                      <Button
                        variant="danger"
                        size="sm"
                        onClick={this.deleteFoster}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'trash-alt']}
                          className="mr-2"
                        />
                        Delete Foster
                      </Button>

                      {shouldRedirect && (
                        <Redirect to={`/rescue/${rescueid}/fosters`} />
                      )}

                      {redirectToEdit && (
                        <Redirect
                          to={`/rescue/${rescueid}/fosters/${fosterid}/edit`}
                        />
                      )}
                    </Form.Group>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Modal
          className="bg-white p-3 p-md-4 border col-10 col-md-6 mx-auto my-5"
          isOpen={this.state.isAddFosterModalOpen}
          onRequestClose={this.closeFosterModal}
          contentLabel="Foster RelationShip Modal"
          ariaHideApp={false}
        >
          <div>
            <Button
              variant="danger"
              size="sm"
              className="d-flex ml-auto mb-3"
              onClick={this.closeFosterModal}
            >
              Close
            </Button>
          </div>

          <AddAnimalFosterRelationshipModal
            fosterId={fosterid}
            animalId={animalIdForFosterModal}
            rescueId={rescueid}
            onClose={this.closeFosterModal}
          />
        </Modal>
      </Container>
    );
  }
}
export default FosterPage;
