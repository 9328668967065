import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import firebase from '../../Firestore';
import SearchDropdownMenu from '../SearchDropdown/SearchDropdownMenu';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import { useMountEffect } from '../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddFosterParentDropdown = (props) => {
  const [fosters, setFosters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useMountEffect(() => {
    const db = firebase.firestore();

    db.collection('rescues')
      .doc(props.rescueId)
      .collection('fosters')
      .get()
      .then((data) => {
        const fosters = data.docs.map((fosterDoc) => {
          return {
            firstName: fosterDoc.get('firstName'),
            lastName: fosterDoc.get('lastName'),
            id: fosterDoc.id,
          };
        });

        setFosters(fosters);
      });
  });

  const onSearch = (searchTermToSet) => {
    setSearchTerm(searchTermToSet);
  };

  const showDropdownItems = () => {
    let count = 0;
    let dropDownItems = [];

    fosters.forEach((foster, i) => {
      const name = `${foster.firstName &&
        foster.firstName.toLowerCase()} ${foster.lastName &&
        foster.lastName.toLowerCase()}`;

      if (count < 6 && name.includes(searchTerm.toLowerCase())) {
        count++;

        dropDownItems.push(
          <Dropdown.Item
            key={i}
            eventKey={i}
            onClick={() => props.onClickAdd(props.animalId, foster.id)}
          >
            {`${foster.firstName} ${foster.lastName}`}
          </Dropdown.Item>,
        );
      }
    });

    return <>{dropDownItems}</>;
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={SearchDropdown} id="dropdown-custom-components">
        <Button size="sm">
          <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
          Add Foster Parent
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={SearchDropdownMenu}
        onChange={(event) => onSearch(event.currentTarget.value)}
      >
        {/* generated dropdown items go here */}
        {showDropdownItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddFosterParentDropdown;
