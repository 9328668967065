import 'firebase/auth';
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { useMountEffect } from '../../hooks';
import { Button } from '../../components';
import { AnimalSearchHit, AnimalSearchHits, AnimalSearchStateResults } from '.';

import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  PUBLIC_ANIMALS_INDEX_NAME,
} from '../../constants/search.constant';
import algoliasearch from 'algoliasearch';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import 'instantsearch.css/themes/reset.css';

function AnimalList() {
  const [userGeolocation, setUserGeolocation] = useState();

  const handleMounted = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          setUserGeolocation(`${position.coords.latitude},${position.coords.longitude}`);
        },
        function() {},
      );
    }
  };

  useMountEffect(handleMounted);

  const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

  return (
    <InstantSearch indexName={PUBLIC_ANIMALS_INDEX_NAME} searchClient={searchClient}>
      <Configure
        aroundLatLng={userGeolocation}
        aroundLatLngViaIP={!userGeolocation}
        hitsPerPage={12}
      />

      <Row>
        <Col md="12" className="search-results">
          <AnimalSearchHits hitComponent={AnimalSearchHit} />

          <AnimalSearchStateResults />
        </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <Button
            color="primary"
            outline
            tag={Link}
            to="/animals"
          >
            View More Near You
          </Button>
        </Col>
      </Row>
    </InstantSearch>
  );
}

export default AnimalList;
