import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default function CenteredPage({ className, children }) {
  return (
    <Container className={`py-4 py-md-5 ${className}`}>
      <Row>
        <Col xs="12" md="8" className="mx-auto">
          {children}
        </Col>
      </Row>
    </Container>
  );
}
