import React, { Fragment, PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import AddAnimal from '../pages/AddAnimalPage/AddAnimal';
import AnimalListPage from '../pages/AnimalListPage/AnimalListPage';
import AnimalPage from '../pages/AnimalPage/AnimalPage';
import EditAnimalPage from '../pages/EditAnimalPage/EditAnimal';
import EditFosterPage from '../pages/EditFosterPage/EditFoster';
import EditUser from '../pages/EditUserPage/EditUser';
import BroadcastPage from '../pages/BroadcastPage/BroadcastPage';
import FosterListPage from '../pages/FosterListPage/FosterListPage';
import UserListPage from '../pages/UserListPage/UserListPage';
import ImportPage from '../pages/ImportPage/ImportPage';
import AddRescueUserPage from '../pages/AddRescueUserPage/AddRescueUserPage';
import LocalBroadcastPage from '../pages/LocalBroadcastPage/LocalBroadcastPage';
import FosterPage from '../pages/FosterPage/FosterPage';
import EditAdditionalFosterQuestions from '../pages/EditAdditionalFosterQuestions/EditAdditionalFosterQuestions';
import EditLegalAgreement from '../pages/EditLegalAgreement/EditLegalAgreement';
import CheckInsPage from '../pages/CheckInsPage/CheckInsPage';
import AnimalDeletePage from '../pages/AnimalDeletePage/AnimalDeletePage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';

import authService from '../services/auth.service';
import SimpleLink from '../components/SimpleLink';

class RescueApp extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasFetchedUser: false,
    };
  }

  render() {
    const { match } = this.props;

    if (
      !authService.user ||
      (authService.user &&
        authService.user.rescue !== window.location.pathname.split('/')[2])
    ) {
      return (
        <div>
          You do not have access to view this page, please{' '}
          <SimpleLink href={`/login`} targetBlank={true}>
            Log In
          </SimpleLink>{' '}
          and try again
        </div>
      );
    }

    return (
      <div>
        <Fragment>
          <Switch>
            <Route
              exact
              path={`${match.path}/:rescueid/animals/add`}
              component={AddAnimal}
            />
            <Route
              exact
              path={`${match.path}/:rescueid/animals/:animalid`}
              component={AnimalPage}
            />
            <Route
              path={`${match.path}/:rescueid/import/`}
              component={ImportPage}
            />
            <Route
              path={`${match.path}/:rescueid/animals/:animalid/edit`}
              component={EditAnimalPage}
            />
            <Route
              exact
              path={`${match.path}/:rescueid/animals/:animalid/checkIns`}
              component={CheckInsPage}
            />
            <Route
              exact
              path={`${match.path}/:rescueid/animals/:animalid/delete`}
              component={AnimalDeletePage}
            />
          </Switch>
          <Route
            exact
            path={`${match.path}/:rescueid/broadcastAnimal/:animalid`}
            component={BroadcastPage}
          />
          <Switch>
            <Route
              exact
              path={`${match.path}/:rescueid/fosters/:fosterid`}
              component={FosterPage}
            />
            <Route
              path={`${match.path}/:rescueid/fosters/:fosterid/edit`}
              component={EditFosterPage}
            />
          </Switch>
          <Route
            path={`${match.path}/:rescueid/editAdditionalFosterQuestions`}
            component={EditAdditionalFosterQuestions}
          />
          <Route
            path={`${match.path}/:rescueid/editLegalAgreement`}
            component={EditLegalAgreement}
          />

          <Route
            path={`${match.path}/:rescueid/localBroadcast`}
            component={LocalBroadcastPage}
          />

          <Route
            exact
            path={`${match.path}/:rescueid/fosters`}
            component={FosterListPage}
          />
          <Route
            exact
            path={`${match.path}/:rescueid/users`}
            component={UserListPage}
          />
          <Route
            path={`${match.path}/:rescueid/users/:userid/edit`}
            component={EditUser}
          />
          <Route
            exact
            path={`${match.path}/:rescueid/settings`}
            component={SettingsPage}
          />
          <Route
            exact
            path={`${match.path}/:rescueid/add-user`}
            component={AddRescueUserPage}
          />
          <Route
            exact
            path={`${match.path}/:rescueid/animals`}
            component={AnimalListPage}
          />
        </Fragment>
      </div>
    );
  }
}

export default RescueApp;
