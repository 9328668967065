import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const Loader = () => (
  <div className="p-3 p-md-5 text-center">
    <FontAwesomeIcon
      icon={faSpinner}
      size="2x"
      spin
      className="text-primary mx-auto"
    />
  </div>
);

export default Loader;
