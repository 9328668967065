import 'firebase/auth';

import React, { PureComponent } from 'react';

import { PUBLIC_API_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';

import Loader from '../../components/Loader';

class PublicFosterUnsubscribePage extends PureComponent {
  errorTimeout;

  constructor(props) {
    super(props);

    this.state = {
      success: false,
    };
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    requestService
      .fetch(
        `${PUBLIC_API_URL}/v1/optinUnsubscribe?optinId=${params.optinId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      )
      .catch((res) => {
        this.alert('failed');
      })
      .then((res) => {
        if (res.status !== 200) {
          alert('failed');
        } else {
          this.setState({ success: true });
        }
      });
  }

  render() {
    return (
      <div>
        <div className="login-page mt-2 mb-5 text-center">
          {this.state.success ? (
            <div>You have been successfully ununsubscribed</div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    );
  }
}

export default PublicFosterUnsubscribePage;
