import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connectStateResults } from 'react-instantsearch-dom';
import Loader from '../../components/Loader';

function SearchStateResults({ searching, searchResults, fosterId }) {
  const noResultsText = fosterId
    ? 'There are no foster animals in your area in our system that match your specific criteria. You can always contact your local rescue or shelter directly, or keep checking in to see if we get some that match your needs.'
    : 'There are no foster animals in your area in our system. Contact your local rescue or shelter if you are interested in fostering and tell them to sign up by emailing outreach@911FosterPets.com.';

  return (
    <Row className="search-state">
      <Col className="text-center mt-3">
        {searching &&
          <Loader />
        }

        {!searching && searchResults?.nbHits === 0 &&
          <h3 className="text-info my-3">
            {noResultsText}
          </h3>
        }
      </Col>
    </Row>
  );
}

export default connectStateResults(SearchStateResults);
