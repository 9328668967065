import firebase from 'firebase';

// Default to production
const IS_INTEGRATION = process.env.REACT_APP_DEV;

let FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCLEo2Rad_9R_evaI68aG6WhmERukYhwqg',
  appId: '1:525632595401:web:9001100290f92b17',
  authDomain: 'foster-app-7dcfe.firebaseapp.com',
  databaseURL: 'https://foster-app-7dcfe.firebaseio.com',
  messagingSenderId: '525632595401',
  projectId: 'foster-app-7dcfe',
  storageBucket: 'foster-app-7dcfe.appspot.com',
};

// if (IS_INTEGRATION) {
//   FIREBASE_CONFIG = {
//     apiKey: 'AIzaSyDNB-IUJwJ_2iEGcqpoLX75xrlKfodc0D8',
//     appId: '1:368576265427:web:0ef2a9625f0d30a68b2826',
//     authDomain: 'foster-app-dev.firebaseapp.com',
//     databaseURL: 'https://foster-app-dev.firebaseio.com',
//     messagingSenderId: '368576265427',
//     projectId: 'foster-app-dev',
//     storageBucket: 'foster-app-dev.appspot.com',
//   };
// }

// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

export default firebase;
