import { Form as FormikForm } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { Button } from '../../components';
import { InputField } from '../../components/formik';
import AddPrimaryContactDropdown from '../AddPrimaryContactDropdown/AddPrimaryContactDropdown';
import { useMountEffect } from '../../hooks';
import firebase from '../../Firestore';

const AddAnimalFosterRelationshipForm = ({ ...formikProps }) => {
  const {
    errors,
    touched,
    handleChange,
    isSubmitting,
    values,
    setFieldValue,
  } = formikProps;
  const [userOptions, setUserOptions] = useState([]);
  const [showPrimaryContactDropdown, setShowPrimaryContactDropdown] = useState(
    false,
  );

  useMountEffect(() => {
    const db = firebase.firestore();

    const currentUserUid = JSON.parse(localStorage.getItem('user')).uid;

    db.collection('rescues')
      .doc(values.rescueId)
      .collection('users')
      .get()
      .then((data) => {
        const users = data.docs.map((userDoc) => {
          const phoneNumber = userDoc.get('phoneNumber');

          if (phoneNumber) {
            setShowPrimaryContactDropdown(true);
            if (!values.primaryContact) {
              setFieldValue('primaryContact', userDoc.id);
            }
            if (currentUserUid === userDoc.get('id')) {
              setFieldValue('primaryContact', userDoc.id);
            }
          }

          return {
            phoneNumber,
            name: userDoc.get('displayName'),
            id: userDoc.id,
          };
        });

        setUserOptions(users);
      });
  });

  return (
    <FormikForm>
      <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
        <h5>Fostering Information</h5>

        <InputField
          label="Start date"
          errors={errors}
          name="startDate"
          onChange={handleChange}
          touched={touched}
          type="datetime-local"
          defaultValue={values.startDate}
        />

        <InputField
          label="Estimated return date"
          errors={errors}
          name="estimatedReturnDate"
          onChange={handleChange}
          touched={touched}
          type="datetime-local"
          defaultValue={values.estimatedReturnDate}
        />

        <Form.Group>
          <Form.Label className="font-weight-bold mb-1">
            How often would you like to do automated check-ins?
          </Form.Label>

          <br />
          <select
            name="checkInFrequency"
            className="form-control mb-4"
            onChange={handleChange}
            defaultValue={values.checkInFrequency}
          >
            <option value="never">Never(No automated check-ins)</option>
            <option value="2">Every 2 days</option>
            <option value="7">Weekly</option>
            <option value="14">Every 2 weeks</option>
            <option value="30">Once a month</option>
          </select>
        </Form.Group>

        <Form.Group>
          <Form.Check
            inline
            type="checkbox"
            name="shouldSendNotification"
            label="Send notification to new parent"
            onChange={handleChange}
            checked={values.shouldSendNotification}
            id="shouldSendNotification"
          />
        </Form.Group>

        {showPrimaryContactDropdown && values.shouldSendNotification && (
          <AddPrimaryContactDropdown
            name="primaryContact"
            rescueId={values.rescueId}
            onChange={handleChange}
            options={userOptions}
            value={values.primaryContact}
          />
        )}

        <Button
          loading={isSubmitting}
          color="primary"
          disabled={!values.animalId || !values.fosterId}
          type="submit"
        >
          Add Foster Relationship
        </Button>
      </div>
    </FormikForm>
  );
};

export default AddAnimalFosterRelationshipForm;
