import React from 'react';
import styled from 'styled-components';
import { backgroundBlue } from '../../assets/images';

const StyledHalfBackground = styled.div`
  background: url(${backgroundBlue});
  background-size: 100% 8%;
  background-repeat: no-repeat;
  background-position: top;
`;

export default function PageHeaderHalfBackground({
  title,
  subtitle,
  children,
}) {
  return (
    <>
      <div className="pt-5 pb-5 bg-primary text-white text-center">
        <h3 className={`${subtitle ? '' : 'mb-0'}`}>{title}</h3>
        {subtitle && <p className="mb-0">{subtitle}</p>}
      </div>
      <StyledHalfBackground>{children}</StyledHalfBackground>
      <div className="py-5" />
    </>
  );
}
