import firebase from '../Firestore';
import jwtDecode from 'jwt-decode';

import { stringify } from '../utils/json.util';

class AuthService {
  idToken;
  user;
  unsubscribeUser;
  callbacks = [];

  onAuthStateChanged(cb: AuthStateHandler): () => void {
    this.callbacks.push(cb);

    return () => {
      // Remove callback
      const index = this.callbacks.indexOf(cb);

      if (index >= 0) {
        this.callbacks.splice(index, 1);
      }
    };
  }

  subscribe() {
    if (this.unsubscribeUser) {
      return;
    }

    // Tests will fail if we attempt to use session storage in a Node JS test environment
    // Check if we are running the website before using session storage
    const persistenceType =
      process.env.NODE_ENV === 'test'
        ? firebase.auth.Auth.Persistence.NONE
        : firebase.auth.Auth.Persistence.LOCAL;

    firebase.auth().setPersistence(persistenceType);

    this.unsubscribeUser = firebase.auth().onAuthStateChanged(async (user) => {
      let idToken;
      let userWithClaims;
      if (user) {
        idToken = await firebase.auth().currentUser.getIdToken(true);
        const decodedToken = jwtDecode(idToken);

        userWithClaims = {
          email: user.email,
          isAdmin: decodedToken.isAdmin,
          isRescueAdmin: decodedToken.isRescueAdmin,
          rescue: decodedToken.rescue,
          isRehomingUser: decodedToken.isRehomingUser,
          uid: user.uid,
        };

        // TODO: Understand why local storage quota is reached
        // Update local storage
        localStorage.setItem('user', stringify(userWithClaims));
        localStorage.setItem('idToken', idToken);
        // Update service state
        this.user = userWithClaims;
        this.idToken = idToken;

        this.callbacks.forEach((cb) => {
          cb(userWithClaims, idToken);
        });
      }
    });
  }

  unsubscribeAll() {
    if (this.unsubscribeUser) {
      this.unsubscribeUser();
    }
  }
}

const authService = new AuthService();

export default authService;
