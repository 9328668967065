import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { Footer, ScrollToTop } from './components';
import {
  FosterSignUpPage as PublicFosterSignUpPage,
  RescueSignUpPage,
} from './pages/SignUp';
import { HomePage, BlogPage, FosteringFaqPage, PrivacyPolicy } from './pages';
import GlobalNavigation from './components/GlobalNavigation';

import ErrorHandler from './components/ErrorHandler';
import GATracker from './components/GATracker';
import FosterSignUpPage from './pages/AddFosterPage/AddFoster';
import ContactUSPage from './pages/ContactUsPage/ContactUsPage';
import FosterAnimalPage from './pages/FosterAnimalPage/FosterAnimalPage';
import PublicAnimalPage from './pages/PublicAnimalPage/PublicAnimalPage';
import PublicAnimalsPage from './pages/PublicAnimalsPage/PublicAnimalsPage';
import PublicRescueAnimalsPage from './pages/PublicRescueAnimalsPage/PublicRescueAnimalsPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import LogoutPage from './pages/LogoutPage/LogoutPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import FosterContractPage from './pages/FosterContractPage/FosterContractPage';
import PublicFosterPage from './pages/PublicFosterPage/PublicFosterPage';
import PublicFosterUnsubscribePage from './pages/public/PublicFosterUnsubscribePage';
import OptinUnsubscribePage from './pages/public/OptinUnsubscribePage';
import FosterAgreementPage from './pages/FosterAgreementPage/FosterAgreementPage';
import PublicFosterSignUpPageStep2 from './pages/PublicFosterSignUpPageStep2/PublicFosterSignUpPageStep2';
import HelpOutPage from './pages/HelpOutPage/HelpOutPage';
import AdminRoute from './routes/AdminRoute';
import RescueRoute from './routes/RescueRoute';
import authService from './services/auth.service';
import AdditionFosterQuestionsPage from './pages/AdditionalFosterQuestions/AdditionalFosterQuestions';
import AlreadyAFosterPage from './pages/AlreadyAFosterPage/AlreadyAFosterPage';
import CheckInPage from './pages/CheckInPage/CheckInPage';

authService.subscribe();

const reload = () => window.location.reload();

function App() {
  const [authCallback, setAuthCallback] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    if (!authCallback) {
      const unsubscribeCallback = authService.onAuthStateChanged((newUser) => {
        ReactGA.set({ userId: newUser.email });
        setUser(newUser);
      });

      setAuthCallback(() => unsubscribeCallback);
    }

    return authCallback;
  }, [authCallback, user]);

  return (
    <Router>
      <ScrollToTop />
      <div className="App bg-white">
        <GlobalNavigation user={user} />

        <Route component={GATracker} />

        <ErrorHandler>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/admin" component={AdminRoute} />
            <Route path="/rescue" component={RescueRoute} />
            <Route path="/login" component={LoginPage} />

            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>

            <Route path="/fostering-faq">
              <FosteringFaqPage />
            </Route>

            <Route path="/foster-contract">
              <FosterContractPage />
            </Route>

            <Route path="/shelter-signup" component={RescueSignUpPage} />
            <Route path="/foster-signup">
              <PublicFosterSignUpPage />
            </Route>
            <Route path="/rehome-signup" component={RescueSignUpPage} />

            <Route
              path="/temporary-rehome-signup"
              component={RescueSignUpPage}
            />

            <Route
              path="/foster-signup-step-2"
              component={PublicFosterSignUpPageStep2}
            />

            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/help-out" component={HelpOutPage} />
            <Route exact path="/blog" component={BlogPage} />
            <Route
              exact
              path="/blogs/fostering-animals-is-free-tax-deduction-for-fostering.html"
              render={reload}
            />
            <Route
              exact
              path="/blogs/fostering-animals-is-free-tax-deduction-for-fostering"
              render={() => (
                <Redirect
                  to={{
                    pathname:
                      '/blogs/fostering-animals-is-free-tax-deduction-for-fostering.html',
                  }}
                />
              )}
            />
            <Route
              exact
              path="/blogs/do-more-good-with-technology.html"
              render={reload}
            />
            <Route
              exact
              path="/blogs/do-more-good-with-technology"
              render={() => (
                <Redirect
                  to={{ pathname: '/blogs/do-more-good-with-technology.html' }}
                />
              )}
            />
            <Route
              exact
              path="/blogs/covid-transport-guidelines.html"
              render={reload}
            />
            <Route
              exact
              path="/blogs/covid-transport-guidelines"
              render={() => (
                <Redirect
                  to={{ pathname: '/blogs/covid-transport-guidelines.html' }}
                />
              )}
            />
            <Route exact path="/blogs/why-we-are-here.html" render={reload} />
            <Route
              exact
              path="/blogs/why-we-are-here"
              render={() => (
                <Redirect to={{ pathname: '/blogs/why-we-are-here.html' }} />
              )}
            />
            <Route exact path={`/animals`} component={PublicAnimalsPage} />
            <Route
              path={`/:rescueid/animals/:animalid/checkIn`}
              component={CheckInPage}
            />
            <Route
              exact
              path={`/:rescueid/animals`}
              component={PublicRescueAnimalsPage}
            />
            <Route
              exact
              path={`/publicFosterUnsubscribe/:publicFosterId`}
              component={PublicFosterUnsubscribePage}
            />
            <Route
              exact
              path={`/optinUnsubscribe/:optinId`}
              component={OptinUnsubscribePage}
            />
            <Route
              exact
              path={`/optinUnsubscribe`}
              component={OptinUnsubscribePage}
            />
            <Route
              exact
              path={`/animals/:animalid`}
              component={PublicAnimalPage}
            />
            <Route
              path={`/:rescueid/fosterSignup`}
              component={FosterSignUpPage}
            />
            <Route
              path={`/:rescueid/fosterAgreement`}
              component={FosterAgreementPage}
            />

            <Route
              path={`/:rescueid/alreadyAFoster`}
              component={AlreadyAFosterPage}
            />

            <Route
              path={`/:rescueid/additionalFosterQuestions/:fosterid`}
              component={AdditionFosterQuestionsPage}
            />

            <Route
              path={`/:rescueid/fosterAnimal/:animalid`}
              component={FosterAnimalPage}
            />

            <Route
              path={`/:rescueid/fosters/:fosterid`}
              component={PublicFosterPage}
            />

            <Route path={'/contact-us'} component={ContactUSPage} />

            <Route component={NotFoundPage} />
          </Switch>
        </ErrorHandler>
      </div>

      <Footer />
    </Router>
  );
}

export default App;
