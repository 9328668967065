import React from 'react';
import { Input, FormGroup, FormFeedback } from 'reactstrap';
import { Field, ErrorMessage } from 'formik';
import { LabelField } from './index';

export default function InputField({
  name,
  label,
  type = 'text',
  placeholder,
  required,
  optional,
  ...rest
}) {
  return (
    <FormGroup className="w-100 mb-4">
      {!!label && (
        <LabelField
          labelName={label}
          fieldName={name}
          required={required}
          optional={optional}
        />
      )}
      <Field name={name}>
        {({ form, field }) => (
          <>
            <Input
              bsSize="sm"
              type={type}
              placeholder={placeholder}
              invalid={form.errors[field.name] && form.touched[field.name]}
              {...field}
              {...rest}
            />
          </>
        )}
      </Field>
      <ErrorMessage name={name} component={FormFeedback} className="d-block" />
    </FormGroup>
  );
}
