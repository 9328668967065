import { Form as FormikForm } from 'formik';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputField } from '../../components/formik';
import { Button } from '../../components';

const AddAnimalForm = ({ ...formikProps }) => {
  const { errors, touched, handleChange, isValid, isSubmitting } = formikProps;

  return (
    <FormikForm>
      <fieldset disabled="">
        <Row>
          <Col md="8" className="mx-auto">
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              <h5>Additional Questions</h5>

              <InputField
                label="Question"
                errors={errors}
                name="question"
                required
                onChange={handleChange}
                placeholder="Enter a new question"
                touched={touched}
                type="text"
              />

              <Button
                loading={isSubmitting}
                color="primary"
                disabled={!isValid}
                type="submit"
              >
                <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
                Add Question
              </Button>
            </div>
            <div className="py-5" />
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AddAnimalForm;
