import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { validEmail, validZip } from '../../../utils/regex';
import { PUBLIC_API_URL } from '../../../constants/urls.constant';
import { requestService } from '../../../services/request.service';

export const useCovid19Panel = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showPanel, setShowPanel] = useState(
    sessionStorage.getItem('showCovid19Panel') === 'true' &&
      localStorage.getItem('hasCompletedCovid19Panel') === 'false',
  );

  const closePanel = () => {
    setShowPanel(false);
    sessionStorage.setItem('showCovid19Panel', 'false');
  };

  useEffect(() => {
    if (sessionStorage.getItem('showCovid19Panel') === null) {
      setShowPanel(true);
      sessionStorage.setItem('showCovid19Panel', 'true');
      localStorage.setItem('hasCompletedCovid19Panel', 'false');
    }
  }, [setShowPanel]);

  const onSuccess = () => {
    localStorage.setItem('hasCompletedCovid19Panel', 'true');
    history.push('/foster-signup');
  };

  const initialValues = {
    email: '',
    zip: '',
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(validEmail, 'Must be a valid email')
      .required('Required'),
    zip: yup
      .string()
      .matches(validZip, 'Must be a valid ZIP code')
      .required('Required'),
  });

  const handleSubmit = async (values, actions) => {
    setLoading(true);

    requestService
      .fetch(`${PUBLIC_API_URL}/v1/optins`, {
        body: JSON.stringify({
          emailAddress: values.email,
          zipCode: values.zip,
          first3OfZip: values.zip.substring(0, 3),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then((_data) => {
        actions.resetForm();
        closePanel();
        onSuccess();
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        setErrors(errors);
      });
  };

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    loading,
    errors,
    showPanel,
    closePanel,
  };
};
