import React from 'react';
import ReactTable from 'react-table';
import {
  TablePlaceholder,
  TableLoader,
  NextButton,
  PreviousButton,
} from './index';

const Table = (props) => {
  return (
    <div className="border border-gray-400 border-width-2">
      <ReactTable
        {...props}
        NoDataComponent={TablePlaceholder}
        LoadingComponent={TableLoader}
        NextComponent={NextButton}
        PreviousComponent={PreviousButton}
      />
    </div>
  );
};

export default Table;
