import * as yup from 'yup';

const AskAboutMeModalFormSchema = yup.object().shape({
  emailAddress: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  phoneNumber: yup
    .string()
    .required('Required')
    .max(11, 'Phone number cannot be more than 11 digits')
    .min(10, 'Phone number cannot be less than 10 digits'),
  zip: yup.string().required('Required'),
  notes: yup.string(),
});

export { AskAboutMeModalFormSchema };
