import React from 'react';

const Placeholder = ({ children, className }) => {
  return (
    <div
      className={`${className} bg-light text-muted text-center font-italic p-3 p-md-4`}
    >
      {children}
    </div>
  );
};

export default Placeholder;
