import { useState } from 'react';
import * as yup from 'yup';
import firebase from '../../../Firestore';
import { PUBLIC_API_URL } from '../../../constants/urls.constant';
import { requestService } from '../../../services/request.service';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const useRescueSignUp = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);

  const isRehoming =
    window.location.pathname.includes('rehome-signup') ||
    window.location.pathname.includes('temporary-rehome-signup');

  const handleSubmit = async (potentialRescue, actions) => {
    const formattedPhoneNumber = potentialRescue.phoneNumber.replace(/\D/g, '');
    const isRehoming = window.location.pathname.includes('rehome-signup');
    potentialRescue.state = potentialRescue.state.toUpperCase();

    const db = firebase.firestore();
    actions.setSubmitting(true);

    if (isRehoming) {
      return requestService
        .fetch(`${PUBLIC_API_URL}/v1/rehomeAccount`, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(potentialRescue),
          method: 'POST',
        })
        .then((res) => {
          return res.json();
        })
        .then(async (data) => {
          if (data && data.EmailAlreadyFound) {
            actions.setSubmitting(false);
            actions.setStatus('EmailAlreadyFound');
          } else if (data && data.UserAlreadyFound) {
            actions.setStatus('UserAlreadyFound');
          } else {
            await sleep(3000);

            firebase
              .auth()
              .signInWithEmailAndPassword(
                potentialRescue.email,
                potentialRescue.password,
              )
              .then((user) => {
                setTimeout(() => {
                  user.user.getIdToken().then((idtoken) => {
                    const decoded = jwtDecode(idtoken);
                    window.scrollTo(0, 0);
                    if (potentialRescue.forMultiple) {
                      history.push(
                        `/rescue/${decoded.rescue}/animals/add?from-rehome-signup=true&multiple=true`,
                      );
                    } else {
                      history.push(
                        `/rescue/${decoded.rescue}/animals/add?from-rehome-signup=true`,
                      );
                    }
                  });
                }, 2000);
              });
          }
        })
        .catch((e) => {
          actions.setStatus(
            'Email was already found in our system, try logging in',
          );
          actions.setSubmitting(false);
        });
    } else {
      return db
        .collection('potentialRescues')
        .add({
          ...potentialRescue,
          name: potentialRescue.rescue,
          phoneNumber: formattedPhoneNumber,
        })
        .then(() => {
          setSuccess(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address1: '',
    address2: '',
    password: '',
    zipCode: '',
    rescue: '',
    website: '',
    intake: 0,
    city: '',
    state: '',
    forMultiple: false,
    canTransport: false,
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    state: yup.string().required('Required'),
    phoneNumber: yup
      .string()
      .required('Required')
      .max(11, 'Phone number cannot be more than 11 digits')
      .min(10, 'Phone number cannot be less than 10 digits'),
    email: yup
      .string()
      .email()
      .required('Required'),
    password: yup
      .string()
      .min(6)
      .required('Required'),
    rescue: yup.string(),
    intake: yup.string(),
    canTransport: yup.boolean(),
    forMultiple: yup.boolean(),
  });

  return {
    initialValues,
    handleSubmit,
    validationSchema,
    success,
    isRehoming,
  };
};
