import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Badge, FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSpinner,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';

import { PUBLIC_API_URL } from '../constants/urls.constant';
import { requestService } from '../services/request.service';

export default function LocationSearch({
  className = '',
  coordinates = '',
  delay = 500,
  placeholder = '',
  onLocationCleared = (x) => x,
  onLocationSelected = (x) => x,
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState();
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const locationSearch = urlParams.get('locationSearch');

    if (locationSearch) {
      const locationArray = locationSearch.split(',');
      setSelectedResult({
        title: locationSearch,
        lat: locationArray[0],
        lng: locationArray[1],
      });
    }
    console.log('once?');
  }, []);

  const handleInput = async (value) => {
    setError(false);

    if (!value || value.trim().length < 3) {
      setSearchResults([]);
      return;
    }

    setSearching(true);
    const atParam = coordinates ? `&at=${coordinates}` : '';
    const response = await requestService.fetch(
      `${PUBLIC_API_URL}/v1/location?q=${encodeURIComponent(value)}${atParam}`,
    );

    if (!response.ok) {
      setSearching(false);
      setError(true);
      return;
    }

    const { items } = await response.json();

    setSearchResults(items);
    setSearching(false);
  };

  const debouncedHandleInput = debounce(handleInput, delay);

  const handleLocationClick = (location, result) => {
    onLocationSelected(location);
    setSearchResults([]);
    setSelectedResult(result);
  };

  const handleLocationCleared = () => {
    setSelectedResult(null);
    onLocationCleared();
  };

  const getIconStyle = (top) => ({
    left: '1.4rem',
    position: 'absolute',
    top,
    transform: 'translateY(-50%)',
  });

  return (
    <div class-name={`location-search ${className}`}>
      {selectedResult == null && (
        <div>
          <FormControl
            type="search"
            placeholder={placeholder}
            style={{ padding: '.3rem 1.7rem' }}
            onInput={(e) => debouncedHandleInput(e.target.value)}
          />

          {!searching && !error && (
            <button
              type="submit"
              title="Submit your search query."
              className="ais-SearchBox-submit"
              style={{ marginLeft: '1rem' }}
            >
              <svg
                className="ais-SearchBox-submitIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 40 40"
              >
                <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
              </svg>
            </button>
          )}

          {searching && (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              style={getIconStyle('30%')}
            />
          )}

          {error && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              style={getIconStyle('50%')}
            />
          )}
        </div>
      )}

      {selectedResult != null && (
        <Badge
          variant="primary"
          style={{ fontSize: '1rem', fontWeight: 'normal' }}
        >
          {selectedResult.title}
          <FontAwesomeIcon
            icon={faTimes}
            style={{ marginLeft: '.5rem', cursor: 'pointer' }}
            onClick={() => handleLocationCleared(null)}
          />
        </Badge>
      )}

      {Array.isArray(searchResults) && searchResults.length > 0 && (
        <ListGroup
          style={{ position: 'absolute', marginTop: '2px', zIndex: 10 }}
        >
          {searchResults.map((result, index) => {
            if (!result.position) {
              return;
            }
            const location = `${result.position.lat},${result.position.lng}`;

            return (
              <ListGroupItem
                action
                key={index}
                onClick={() => handleLocationClick(location, result)}
              >
                {result.address != null ? result.address.label : result.title}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      )}
    </div>
  );
}
