import React, { useState } from 'react';
import { useMountEffect } from '../../hooks';
import { Container, Row, Col } from 'react-bootstrap';

import Table from '../../components/react-table/Table';
import firebase from '../../Firestore';
import Loader from '../../components/Loader';
import placeholder from '../../assets/paw.png';
import { requestService } from '../../services/request.service';
import { COMM_URL } from '../../constants/urls.constant';
import SimpleLink from '../../components/SimpleLink';
import { WEBSITE_URL } from '../../constants/urls.constant';
import { Button } from '../../components';

const CheckInPage = (props) => {
  const [animal, setAnimal] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [checkIns, setCheckIns] = useState([]);

  const {
    match: { params },
  } = props;

  function load() {
    const db = firebase.firestore();

    const animalInfo = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid);

    animalInfo.get().then((data) => {
      setAnimal(data.data());
    });

    db.collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid)
      .collection('checkIns')
      .get()
      .then((data) => {
        const checkInData = data.docs.map((checkInDoc) => {
          return checkInDoc.data();
        });

        setCheckIns(checkInData);
      });

    setLoading(false);
  }

  const confirmCheckIn = async () => {
    const r = window.confirm('Are you sure you want to send a check-in?');

    if (r === true) {
      setButtonLoading(true);
      const db = firebase.firestore();

      const fosterParentPromises = animal.fosterParents.map((fosterParent) => {
        return db
          .collection('rescues')
          .doc(params.rescueid)
          .collection('fosters')
          .doc(fosterParent.fosterId)
          .get();
      });

      const fosterDocs = await Promise.all(fosterParentPromises);

      let checkInMessage = `please let us know how ${animal.name} is doing by clicking here\n\n${WEBSITE_URL}/${params.rescueid}/animals/${params.animalid}/checkIn`;

      let hideFosterParam = false;
      const checkIns = await db
        .collection('rescues')
        .doc(params.rescueid)
        .collection('settings')
        .where('name', '==', 'animalCheckIns')
        .get();

      if (!checkIns.empty) {
        const { message, showLink } = checkIns.docs[0].data();
        checkInMessage = message.replace(/#AnimalName#/g, animal.name);

        if (showLink) {
          checkInMessage += ` ${WEBSITE_URL}/${params.rescueid}/animals/${params.animalid}/checkIn`;
        } else {
          hideFosterParam = true;
        }
      }

      fosterDocs.forEach((fosterDoc) => {
        const foster = { ...fosterDoc.data(), id: fosterDoc.id };

        requestService
          .fetch(`${COMM_URL}/v1/message`, {
            body: JSON.stringify({
              messages: [
                {
                  message: checkInMessage,
                  recipients: [
                    {
                      phoneNumber: foster.phoneNumber,
                      emailAddress: foster.emailAddress,
                      communicationPreference: foster.communicationPreference,
                      fosterId: foster.id,
                      firstName: foster.firstName,
                      lastName: foster.lastName,
                    },
                  ],
                  rescueId: params.rescueid,
                  type: 'animalCheckIn',
                  hideFosterParam,
                },
              ],
            }),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'POST',
          })
          .then(() => {
            setButtonLoading(false);
            alert('Check in notification successful');
          });
      });
    }
  };

  useMountEffect(() => {
    load();
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="py-3 py-sm-5">
      <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400 mb-5">
        <Row>
          <Col sm="4" className="mx-3">
            <div className="align-items-center justify-content-center w-100 mb-3">
              <Row className="mb-3">
                <img
                  height="100%"
                  width="100%"
                  alt="Animal from rescue or shelter that needs fostering. This helps
                    foster coordinators identify and manage their animals more easily."
                  src={animal.imageUrl ? animal.imageUrl : placeholder}
                />
              </Row>

              <Row className="align-items-center justify-content-center">
                <Button
                  loading={buttonLoading}
                  color="primary"
                  onClick={() => confirmCheckIn()}
                >
                  Ask For check-in
                </Button>
              </Row>
            </div>
          </Col>

          <Col>
            <div className="mb-3">
              <h3 className="mb-0">check-ins for {animal.name}</h3>
            </div>

            <Table
              data={checkIns}
              columns={[
                {
                  Cell: ({ original: checkIn }) => {
                    return (
                      <SimpleLink
                        href={`/rescue/${params.rescueid}/fosters/${checkIn.fosterId}`}
                      >
                        {checkIn.fosterName}
                      </SimpleLink>
                    );
                  },
                  Header: () => <b>Name</b>,
                  accessor: 'fosterName',
                  width: 200,
                },
                {
                  Cell: ({ value }) => {
                    return value;
                  },
                  Header: () => <b>Message</b>,
                  accessor: 'text',
                  style: { whiteSpace: 'unset' },
                  sortable: false,
                },
                {
                  Cell: ({ _, value }) => {
                    return `${new Date(value).toLocaleDateString()}, ${new Date(
                      value,
                    ).toLocaleTimeString()}`;
                  },
                  Header: () => <b>Date</b>,
                  accessor: 'timeStamp',
                },
                {
                  Cell: ({ _, value }) => {
                    return (
                      <div>
                        <div>
                          <img src={value} alt="from checkin" width="100%" />
                        </div>
                        <div>
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                            download="ImageFromCheckIn.png"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    );
                  },
                  Header: () => <b>Image</b>,
                  accessor: 'imageUrl',
                },
              ]}
              loading={loading}
              defaultSorted={[
                {
                  id: 'timeStamp',
                  desc: true,
                },
              ]}
              showPagination={checkIns.length > 20}
              pageSize={checkIns.length > 20 ? 20 : checkIns.length}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CheckInPage;
