const PLURALIZATION = {
  plural: {
    title: 'Add Multiple Animals',
    imageLabel: 'Upload a photo of the pets',
    breedLabel: "Enter the animals' breed(s)",
    addAnimalButtonLabel: 'Add Animals',
    animal: 'animals',
    Animal: 'Animals',
    needs: 'need',
    this: 'these',
    This: 'These',
    ThisFosterAnimal: 'These foster animals',
    thisFosterAnimal: 'these foster animals',
    a: 'some',
    s: 's',
  },
  singular: {
    title: 'Add an Animal',
    imageLabel: 'Upload a photo of the pet',
    breedLabel: "Enter the animal's breed",
    addAnimalButtonLabel: 'Add Animal',
    animal: 'animal',
    Animal: 'Animal',
    needs: 'needs',
    this: 'this',
    This: 'This',
    ThisFosterAnimal: 'This animal',
    thisFosterAnimal: 'this animal',
    a: 'a',
    s: '',
  },
};

export { PLURALIZATION };
