import React from 'react';
import { Container } from 'reactstrap';
// import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HomePageBanner() {
  if (window.location.host !== '911fosterpets.com') {
    return <div />;
  }

  return (
    <div className="bg-primary d-flex align-items-center px-3 py-2 text-white text-center">
      <Container className="px-0 px-md-3">
        <h6 className="mb-0 d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={['fas', 'bell']} size="sm" className="mr-2" />
          Exciting News!
        </h6>
        <div className="fs-sm">
          RescueFoster is now a part of 911FosterPets.com
          {/* <NavLink
            color="link"
            to="/blog/rescuefoster-joins-911fosterpets"
            className="text-danger p-0 pl-1"
          >
            <u className="text-white font-weight-500">Learn more</u>
          </NavLink> */}
        </div>
      </Container>
    </div>
  );
}
