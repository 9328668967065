import React, { useState, useReducer } from 'react';
import { useMountEffect } from '../../hooks';
import firebase from '../../Firestore';
import { Container, Button } from 'react-bootstrap';
import Table from '../../components/react-table/Table';
import { PUBLIC_API_URL } from '../../constants/urls.constant';
import { requestService } from '../../services/request.service';
import Loader from '../../components/Loader';

import { Form } from 'react-bootstrap';
import './PublicFosterSignUpPageStep2.css';

const PublicFosterSignUpPageStep2 = (props) => {
  useMountEffect(loadNow);
  const [rescues, setRescues] = useState([]);
  const [includedRescues, setIncludedRescues] = useState({});
  const [potentialFosterId, setPotentialFosterId] = useState({});
  const [loading, setLoading] = useState(false);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function loadNow() {
    load();
  }

  async function load() {
    const db = firebase.firestore();
    setPotentialFosterId(new URL(window.location.href).searchParams.get('id'));
    const state = new URL(window.location.href).searchParams.get('state');

    const rescuesCollection = await db
      .collection('rescues')
      .where('state', '==', state)
      .get();

    let rescuesToSet = [];

    rescuesToSet.push({
      name: 'Individuals in need of temporary pet care',
      id: 'individuals',
      rescue: 'individuals',
    });

    rescuesToSet.push({
      name: 'Animal shelters and rescue groups',
      id: 'rescuesandshelters',
      rescue: 'rescuesandshelters',
    });

    rescuesCollection.docs.forEach(async (doc) => {
      let includedRescuesToChange = includedRescues;
      includedRescuesToChange[doc.data().rescue] = false;

      setIncludedRescues(includedRescuesToChange);

      const publicSettings = await doc.ref
        .collection('settings')
        .where('name', '==', 'publicSettings')
        .get();

      let showRescueToPublic = true;

      if (
        !publicSettings.empty &&
        publicSettings.docs[0].data().showRescueToPublic === false
      ) {
        showRescueToPublic = false;
      }

      if (
        doc.data().rescue &&
        !doc.data().isRehomingUser &&
        showRescueToPublic
      ) {
        rescuesToSet.push({ ...doc.data(), id: doc.id });
        setRescues([...rescues, ...rescuesToSet]);
      }
    });

    let includedRescuesToChange = includedRescues;
    includedRescuesToChange['individuals'] = true;
    includedRescuesToChange['rescuesandshelters'] = true;
    setIncludedRescues(includedRescuesToChange);

    setLoading(false);
  }

  function submit() {
    setLoading(true);

    let rescueIdsToSend = [];

    Object.keys(includedRescues).forEach((includedRescueId) => {
      if (
        includedRescues[includedRescueId] &&
        includedRescueId !== 'undefined'
      ) {
        rescueIdsToSend.push(includedRescueId);
      }
    });

    requestService
      .fetch(`${PUBLIC_API_URL}/v1/signUpForRescues`, {
        body: JSON.stringify({
          potentialFosterId,
          rescueIds: rescueIdsToSend,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(() => {
        const state = new URL(window.location.href).searchParams.get('state');
        props.history.push(
          `/animals?fosterId=${potentialFosterId}&state=${state}`,
        );
      });
  }

  function handleChange(event) {
    let includedRescuesToChange = includedRescues;

    includedRescuesToChange[event.target.name] = !includedRescuesToChange[
      event.target.name
    ];

    setIncludedRescues(includedRescuesToChange);
    forceUpdate();
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="mt-2">
      <div className="my-2">
        Please select those you would be comfortable fostering for below.
        {rescues.length > 2
          ? " If you can, it's better to be specific about what rescues or shelters you'd like to help."
          : ''}
        :
      </div>

      <Table
        data={rescues}
        columns={[
          {
            Cell: ({ original: rescue }) => {
              return (
                <span>
                  {rescue.name}
                  {rescue.city && rescue.state && (
                    <span>
                      ({rescue.city}, {rescue.state})
                    </span>
                  )}
                </span>
              );
            },
            Header: () => <b>Name</b>,
          },
          {
            Cell: ({ original: rescue }) => {
              return (
                <span>
                  <Form.Check
                    className="mb-3"
                    name={rescue.rescue}
                    label="Sign up"
                    onChange={handleChange}
                    checked={includedRescues[rescue.rescue]}
                    id={rescue.rescue}
                  />
                </span>
              );
            },
            Header: () => <b></b>,
          },
        ]}
        loading={loading}
        showPagination={rescues.length > 20}
        pageSize={rescues.length > 20 ? 20 : rescues.length}
      />

      <div className="text-align-right">
        <Button className="align-center mt-3" onClick={submit}>
          Submit
        </Button>
      </div>
    </Container>
  );
};

export default PublicFosterSignUpPageStep2;
