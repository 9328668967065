import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import placeholder from '../../assets/paw.png';

const StyledImage = styled.div`
  padding-bottom: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
`;

const StyledCard = styled.div.attrs({
  className: 'bg-white rounded border p-2 mb-3 mb-md-0',
})`
  box-shadow: 0 0.15rem 0.25rem rgba(18, 22, 31, 0.15);
  cursor: pointer;
  &:hover {
    box-shadow: 0 0.35rem 0.75rem rgba(18, 22, 31, 0.15);
  }
  &:active {
    box-shadow: 0 0.15rem 0.25rem rgba(18, 22, 31, 0.15);
  }
`;

export default function AnimalSearchHit({
  objectID,
  city,
  hasUrgentNeed,
  imageUrl,
  name,
  rescueName,
  state,
  fromRehomingUser,
  publicUrl,
  sz,
}) {
  return (
    <Col className="mb-3" xs="12" sm="6" md="6" lg="4" xl="4">
      <a href={publicUrl || `/animals/${objectID}`}>
        <StyledCard>
          <StyledImage
            image={imageUrl ? imageUrl : placeholder}
            alt="Public animal from nearby rescue or shelter that needs fostering. This
                is available to potential foster parents who live near"
            className="rounded mb-1"
          />
          <div className="text-center">
            <h6 className="mb-0">{name}</h6>
            <div className="small text-muted">
              {rescueName && fromRehomingUser[0] !== 'Yes'
                ? `${rescueName} - ${city}, ${state}`
                : `Pet parent - ${city}, ${state}`}
            </div>
            {hasUrgentNeed && (
              <div className="text-danger small font-weight-bold">
                Placement needed urgently
              </div>
            )}
          </div>
        </StyledCard>
      </a>
    </Col>
  );
}
