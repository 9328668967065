import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import statusMap from '../../utils/fosterStatusMap';
import Table from '../../components/react-table/Table';
import firebase from '../../Firestore';
import './FosterListPage.css';

class FosterListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fosters: [],
      loaded: false,
      allFoster: [],
      filteredFosters: [],
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    const db = firebase.firestore();
    const {
      match: { params },
    } = this.props;

    const rescueDoc = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('fosters');

    rescueDoc.get().then((data) => {
      const fosterList = data.docs
        .filter((doc) => {
          if (doc.data()) {
            const foster = doc.data();

            if (foster.status === 'archived') {
              return false;
            }

            return true;
          } else {
            return false;
          }
        })
        .map((doc) => {
          if (doc.data()) {
            const foster = doc.data();

            foster.id = doc.id;
            return foster;
          }

          return null;
        });

      this.setState({
        fosters: fosterList,
        allFosters: fosterList,
        filteredFosters: fosterList,
        loaded: true,
      });
    });

    return null;
  }

  handleStatusFilterChange(e) {
    const { allFosters } = this.state;

    if (e.target.value === 'all') {
      this.setState({ fosters: allFosters });
    } else {
      const filteredFosters = allFosters.filter((foster) => {
        if (foster.status === e.target.value) {
          return foster;
        }

        return false;
      });

      this.setState({ fosters: filteredFosters, filteredFosters });
    }
  }

  handleSearchChange(e) {
    const { filteredFosters } = this.state;

    if (e.target.value) {
      const searchResults = filteredFosters.filter((foster) => {
        const name = `${foster.firstName &&
          foster.firstName.toLowerCase()} ${foster.lastName &&
          foster.lastName.toLowerCase()}`;
        if (name.includes(e.target.value.toLowerCase())) {
          return foster;
        }

        return false;
      });

      this.setState({ fosters: searchResults });
    } else {
      this.setState({ fosters: filteredFosters });
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const { rescueid: rescueId } = params;
    const { fosters, loaded, filterStatus } = this.state;

    return (
      <div>
        <Container className="py-3 py-sm-5">
          <div className="d-flex justify-content-end mb-3 actions row pl-3">
            <div className="mr-3 col-12 pl-0 col-xl-3 pb-3 pb-xl-0 pl-3 pr-0">
              <input
                className="form-control"
                name="search"
                onChange={this.handleSearchChange}
                placeholder="Search..."
                type="text"
              />
            </div>

            <div className="mr-3 col-12 pl-0 col-xl-3 pb-3 pb-3 pb-xl-0 pl-3 pr-0 pl-xl-1">
              <select
                name="status"
                className="form-control"
                onChange={this.handleStatusFilterChange}
                defaultValue={filterStatus}
              >
                <option value="all">All Statuses</option>
                <option value="unverified">Unknown/Unverified</option>
                <option value="available">Available</option>
                <option value="fostering">
                  Actively Fostering (Not at Capacity)
                </option>
                <option value="fosteringatmax">
                  Actively Fostering (At Capacity)
                </option>
                <option value="unavailable">Unavailable</option>
              </select>
            </div>

            <div className="d-flex pr-3">
              <NavLink to={`/rescue/${rescueId}/editAdditionalFosterQuestions`}>
                <Button size="med" className="btn-secondary text-white mr-3">
                  <FontAwesomeIcon icon={['far', 'pen']} className="mr-2" />
                  Edit Questions
                </Button>
              </NavLink>

              <NavLink to={`/rescue/${rescueId}/editLegalAgreement`}>
                <Button size="med" className="btn-secondary text-white mr-3">
                  <FontAwesomeIcon
                    icon={['far', 'file-contract']}
                    className="mr-2"
                  />
                  Edit Legal
                </Button>
              </NavLink>

              <NavLink to={`/${rescueId}/fosterSignUp`}>
                <Button color="primary" size="med" className="text-white px-3">
                  <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
                  Add Foster Parent
                </Button>
              </NavLink>
            </div>
          </div>

          <Table
            data={fosters}
            columns={[
              {
                Cell: ({ original: foster }) => {
                  return (
                    <NavLink to={`/rescue/${rescueId}/fosters/${foster.id}`}>
                      {foster.firstName} {foster.lastName}
                    </NavLink>
                  );
                },
                Header: () => <b>Name</b>,
                accessor: 'firstName',
              },
              {
                Cell: ({ original: foster, value }) => {
                  const fosterAnimals = value || [{}];
                  const names = fosterAnimals.map((fosterAnimal) => {
                    return (
                      <div key={`${fosterAnimal.animalId}${foster.id}`}>
                        <NavLink
                          to={`/rescue/${rescueId}/animals/${fosterAnimal.animalId}`}
                        >
                          {fosterAnimal.name}
                        </NavLink>
                      </div>
                    );
                  });
                  return <span>{names}</span>;
                },
                Header: () => <b>Foster Animals</b>,
                accessor: 'fosterAnimals',
                width: 250,
              },
              {
                Cell: ({ _, value }) => {
                  return <span>{statusMap[value] || value}</span>;
                },
                Header: () => <b>Status</b>,
                accessor: 'status',
                width: 250,
              },
            ]}
            loading={!loaded}
            showPagination={fosters.length > 20}
            pageSize={fosters.length > 20 ? 20 : fosters.length}
          />
        </Container>
      </div>
    );
  }
}
export default FosterListPage;
