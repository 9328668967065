import React, { PureComponent } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADMIN_URL } from '../../constants/urls.constant';
import authService from '../../services/auth.service';
import Loader from '../../components/Loader';
import './AdminPage.css';

class AdminPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      loading: true,
    };

    this.fetchUsers = this.fetchUsers.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  async fetchUsers() {
    const users = await fetch(`${ADMIN_URL}/v1/users`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
    }).then((res) => {
      this.setState({
        loading: false,
      });

      return res.json();
    });

    this.setState({
      users,
    });
  }

  addUser() {
    const { history } = this.props;

    history.push('/admin/add-user');
  }

  confirmDeleteUser(user) {
    const r = window.confirm('Are you sure you want to delete this user?');
    if (r === true) {
      this.deleteUser(user);
    }
  }

  deleteUser(user) {
    fetch(`${ADMIN_URL}/v1/deleteUser`, {
      headers: new Headers({
        Authorization: authService.idToken,
      }),
      method: 'POST',
      body: JSON.stringify({
        emailAddress: user.email,
        rescueId: user.rescue,
        userId: user.uid,
      }),
    }).then(() => this.fetchUsers());
  }

  renderUsers() {
    const { users } = this.state;

    if (users.length === 0) {
      return null;
    }

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <Accordion>
        {users.map((user) => (
          <Card key={user.uid}>
            <Card.Header>
              <Col xs="auto">
                <Card.Title>{user.email}</Card.Title>
              </Col>

              <Col xs="auto">
                <h4>
                  {user.isAdmin ? (
                    <Badge variant="secondary">Admin</Badge>
                  ) : null}
                  {user.isRescueAdmin ? (
                    <Badge variant="secondary">Rescue Admin</Badge>
                  ) : null}
                  {user.disabled ? (
                    <Badge variant="danger">Disabled</Badge>
                  ) : null}
                </h4>
              </Col>

              <Col xs="auto" id="col-expand">
                <button
                  className="btn btn-danger mr-3"
                  onClick={() => this.confirmDeleteUser(user)}
                >
                  <FontAwesomeIcon
                    icon={['far', 'trash-alt']}
                    className="mr-2"
                  />
                  Delete User
                </button>
                <Accordion.Toggle as={Button} eventKey={user.uid}>
                  +
                </Accordion.Toggle>
              </Col>
            </Card.Header>

            <Accordion.Collapse eventKey={user.uid}>
              <Card.Body>
                <Card.Subtitle>
                  <p>UID: {user.uid}</p>
                </Card.Subtitle>

                <h3>Rescues</h3>

                <ListGroup>
                  {!user.rescue ? <p>None</p> : null}
                  <ListGroup.Item>
                    <Card.Text>{user.rescue}</Card.Text>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  }

  render() {
    return <div className="mx-5 mb-5">{this.renderUsers()}</div>;
  }
}

export default AdminPage;
