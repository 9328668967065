import React from 'react';
import { Container } from 'react-bootstrap';
import firebase from '../../Firestore';
import { useState } from 'react';
import { useMountEffect } from '../../hooks';
import BasicAnimalInfo from '../../components/BasicAnimalInfo';
import CheckInBox from '../../components/CheckInBox';
import Loader from '../../components/Loader';

const CheckInPage = (props) => {
  const [animal, setAnimal] = useState({});
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const {
    match: { params },
  } = props;

  function load(fosterId) {
    const db = firebase.firestore();

    const animalInfo = db
      .collection('rescues')
      .doc(params.rescueid)
      .collection('animals')
      .doc(params.animalid);

    animalInfo.get().then((data) => {
      const fosterParentIds = data.data().fosterParents.map((fosterParent) => {
        return fosterParent.fosterId;
      });

      if (fosterParentIds.includes(fosterId)) {
        setAnimal(data.data());
        setLoading(false);
      } else {
        setLoading(false);
        setHasError(true);
      }
    });
  }

  useMountEffect(() => {
    const fosterId = new URL(window.location.href).searchParams.get('fosterId');

    if (fosterId) {
      load(fosterId);
    } else {
      setLoading(false);
      setHasError(true);
    }
  });

  if (loading) {
    return <Loader />;
  }

  if (hasError) {
    return 'You do not have permission to access this page';
  }

  return (
    <Container className="py-3 py-sm-5">
      <BasicAnimalInfo
        name={animal.name}
        species={animal.species}
        gender={animal.gender}
        breed={animal.breed}
        ageMonths={animal.ageMonths}
        ageYears={animal.ageYears}
        imageUrl={animal.imageUrl}
        conditions={animal.conditions}
        notes={animal.publicNotes}
      />

      <CheckInBox animalId={params.animalid} rescueId={params.rescueid} />
    </Container>
  );
};

export default CheckInPage;
