import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import './CopyToClipboard.css';

const CopyToClipboard = (props) => {
  const [text, setText] = useState('');

  function copyLink() {
    navigator.clipboard.writeText(props.value);
    return setText('Link copied!');
  }

  return (
    <h6>
      {props.description || ''}
      &nbsp;
      <span className="copy-icon">
        <FontAwesomeIcon
          icon={faCopy}
          size="1x"
          onClick={() => copyLink()}
          color="light"
        />
      </span>
      <span className="link-copied-text">&nbsp; {text}</span>
    </h6>
  );
};
export default CopyToClipboard;
