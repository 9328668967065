import firebase from 'firebase';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router';

import 'firebase/auth';

class LogoutPage extends PureComponent {
  componentDidMount() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.reload();
        localStorage.removeItem('user');
        localStorage.removeItem('idToken');

        this.setState({
          isLoggedOut: true,
        });
      });
  }

  render() {
    if (window.location.hostname === 'foster-app-7dcfe.web.app') {
      window.location.href = 'http://www.911fosterpets.com';
    } else {
      return <Redirect to="/" />;
    }
  }
}

export default LogoutPage;
