import React from 'react';
import { FormControl } from 'react-bootstrap';

class SearchDropdownMenu extends React.Component {
  render() {
    const {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      onChange,
      value,
    } = this.props;

    return (
      <div style={style} className={className} aria-labelledby={labeledBy}>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={onChange}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  }
}

export default SearchDropdownMenu;
