import React from 'react';
import { Form } from 'react-bootstrap';

const AddPrimaryContactDropdown = (props) => {
  const Options = ({ options }) => {
    return options.map((option) => {
      if (option.phoneNumber) {
        return (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        );
      }
      return null;
    });
  };

  return (
    <Form.Group>
      <Form.Label className="font-weight-bold mb-1">
        Emergency Contact
      </Form.Label>

      <br />

      <select
        name={props.name}
        className="form-control"
        onChange={props.onChange}
        value={props.value}
      >
        <Options options={props.options} />
      </select>
      <br />
    </Form.Group>
  );
};

export default AddPrimaryContactDropdown;
