import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledBlogEntryCard = styled.div.attrs({
  className: 'bg-white d-block p-3 p-md-4 mb-1',
})`
  box-shadow: 0 0.5rem 1rem rgba(18, 22, 31, 0.15);
  cursor: pointer;
  color: ${(props) => props.theme.gray700};
  min-height: 300px;
  &:hover {
    box-shadow: 0 1rem 3rem rgba(18, 22, 31, 0.175) !important;
  }
  &:active {
    box-shadow: 0 0.5rem 1rem rgba(18, 22, 31, 0.15);
  }
`;

export default function BlogEntryCard({
  title,
  author,
  authorInfo,
  preview,
  path,
}) {
  const history = useHistory();
  const count = 350;
  let displayText = preview.slice(0, count) + '...';

  return (
    <StyledBlogEntryCard onClick={() => history.push(path)}>
      <div style={{ minHeight: '125px' }}>
        <h3>{title}</h3>
        <div className="fs-sm">
          <div className="font-weight-500">{author}</div>
          <div className="mb-3">{authorInfo}</div>
        </div>
      </div>

      <p>{displayText}</p>
      <div className="d-flex justify-content-end mt-4">
        <FontAwesomeIcon icon={['far', 'arrow-right']} />
      </div>
    </StyledBlogEntryCard>
  );
}
