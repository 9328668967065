import 'firebase/auth';
import React, { useState } from 'react';
import { Container } from 'reactstrap';

import { useMountEffect } from '../../hooks';
import firebase from '../../Firestore';
import { AnimalCard } from '../../components/animals';
import Loader from '../../components/Loader';
import CopyToClipboard from '../../components/CopyToClipboard/CopyToClipboard';
import { WEBSITE_URL } from '../../constants/urls.constant';

const PublicAnimalsPage = (props) => {
  const [animals, setAnimals] = useState([]);
  const [rescueName, setRescueName] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasUrgentNeed, setHasUrgentNeed] = useState(false);
  const [isInCustody, setIsInCustody] = useState(false);

  const {
    match: { params },
  } = props;

  async function fetchDataForSpecificRescue(rescueid) {
    const db = firebase.firestore();
    const hasUrgentNeedParam = new URL(window.location.href).searchParams.get(
      'hasUrgentNeed',
    );
    const isInCustodyParam = new URL(window.location.href).searchParams.get(
      'isInCustody',
    );

    let publicAnimalCollection;
    if (hasUrgentNeedParam) {
      setHasUrgentNeed(true);
      publicAnimalCollection = await db
        .collection('publicAnimals')
        .where('rescueId', '==', rescueid)
        .where('hasUrgentNeed', '==', true)
        .orderBy('timeStamp')
        .get();
    } else if (isInCustodyParam) {
      setIsInCustody(true);
      publicAnimalCollection = await db
        .collection('publicAnimals')
        .where('rescueId', '==', rescueid)
        .where('isInCustody', '==', true)
        .orderBy('timeStamp')
        .get();
    } else {
      publicAnimalCollection = await db
        .collection('publicAnimals')
        .where('rescueId', '==', rescueid)
        .orderBy('timeStamp')
        .get();
    }

    setAnimals(
      publicAnimalCollection.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      }),
    );

    const rescueDoc = await db
      .collection('rescues')
      .doc(rescueid)
      .get();

    setRescueName(rescueDoc.get('name'));

    setLoading(false);
  }

  async function fetchDataForRescueGroups(rescueid) {
    const db = firebase.firestore();
    const hasUrgentNeedParam = new URL(window.location.href).searchParams.get(
      'hasUrgentNeed',
    );
    const isInCustodyParam = new URL(window.location.href).searchParams.get(
      'isInCustody',
    );

    let publicAnimalCollection;
    if (hasUrgentNeedParam) {
      setHasUrgentNeed(true);
      publicAnimalCollection = await db
        .collection('publicAnimals')
        .where('rescueGroupsOrgId', '==', rescueid)
        .where('hasUrgentNeed', '==', true)
        .orderBy('timeStamp')
        .get();
    } else if (isInCustodyParam) {
      setIsInCustody(true);
      publicAnimalCollection = await db
        .collection('publicAnimals')
        .where('rescueGroupsOrgId', '==', rescueid)
        .where('isInCustody', '==', true)
        .orderBy('timeStamp')
        .get();
    } else {
      publicAnimalCollection = await db
        .collection('publicAnimals')
        .where('rescueGroupsOrgId', '==', rescueid)
        .orderBy('timeStamp')
        .get();
    }

    setAnimals(
      publicAnimalCollection.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      }),
    );

    const rescueDoc = await db
      .collection('rescues')
      .doc(rescueid)
      .get();

    setRescueName(rescueDoc.get('name'));

    setLoading(false);
  }

  const load = () => {
    const fromRescueGroups = new URL(window.location.href).searchParams.get(
      'fromRescueGroups',
    );

    if (fromRescueGroups) {
      fetchDataForRescueGroups(params.rescueid);
    } else if (params.rescueid) {
      fetchDataForSpecificRescue(params.rescueid);
    }
  };

  useMountEffect(load);

  const animalCards = animals.map((animal) => {
    return (
      <AnimalCard
        key={`${animal.name}${animal.rescue}`}
        name={animal.name}
        rescue={animal.rescue}
        id={animal.id}
        imageUrl={animal.imageUrl}
        breed={animal.breed}
        city={animal.city}
        state={animal.state}
        hasUrgentNeed={animal.hasUrgentNeed}
        clickAction={() => {
          window.location.href = animal.publicUrl;
        }}
      />
    );
  });

  const showSubHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      return (
        <CopyToClipboard
          description="Want to share this page with potential foster parents? Copy this link"
          value={`${WEBSITE_URL}/${params.rescueid}/animals`}
        />
      );
    }

    return (
      <h4 className="mb-4">Click on an animal to get more info and sign up!</h4>
    );
  };

  const handleStatusFilterChange = (e) => {
    let searchParams = '';
    if (
      e.target.value === 'hasUrgentNeed' ||
      e.target.value === 'isInCustody'
    ) {
      searchParams = `?${e.target.value}=true`;
    }
    window.location.href = `/${params.rescueid}/animals${searchParams}`;
  };

  if (loading) {
    return <Loader />;
  }

  const showCards = () => {
    if (!loading && animals.length === 0) {
      return (
        <h3 className="my-3">
          There are no foster animals from your rescue available right now
        </h3>
      );
    }

    return <div className="row justify-content-center mb-3">{animalCards}</div>;
  };

  const status = () => {
    if (hasUrgentNeed) {
      return 'hasUrgentNeed';
    } else if (isInCustody) {
      return 'isInCustody';
    } else {
      return 'all';
    }
  };

  return (
    <Container className="mt-5 justify-content-center">
      <h1 className="ml-2 text-center">{rescueName}</h1>
      <h1 className="ml-2 text-center">Animals in Need of Temporary Homes</h1>

      {hasUrgentNeed && (
        <h1 className="ml-2 text-center text-danger">Urgent Placement Needs</h1>
      )}

      {isInCustody && <h1 className="ml-2 text-center">In Custody</h1>}

      <div className="text-center">{showSubHeader()}</div>

      <select
        name="status"
        className="form-control col-4 mx-auto mb-2"
        onChange={handleStatusFilterChange}
        value={status()}
      >
        <option value="all">All Statuses</option>
        <option value="hasUrgentNeed">Has Urgent Need</option>
        <option value="isInCustody">In Custody</option>
      </select>

      <div className="text-center mb-2">{showCards()}</div>
    </Container>
  );
};

export default PublicAnimalsPage;
