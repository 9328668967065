import { Form as FormikForm, Field } from 'formik';
import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import firebase from '../../Firestore';
import { InputField, LabelField } from '../../components/formik';
import { Button, Loader } from '../../components';
import { useMountEffect } from '../../hooks';
import { PLURALIZATION } from '../../constants/pluralization.constant';
import {
  CONDITIONSMAPWITHUNKNOWNS,
  CONDITIONSARRAY,
} from '../../constants/conditions.constant';
const user = JSON.parse(localStorage.getItem('user'));

const AddAnimalForm = ({ ...formikProps }) => {
  const {
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    setFieldTouched,
    isValid,
    isSubmitting,
  } = formikProps;

  useMountEffect(() => {
    setFieldValue('isFromRehomingUser', user && user.isRehomingUser);
    setFieldTouched('isFromRehomingUser', true);
  });

  const [isImageLoading, setIsImageLoading] = useState(false);

  const uploadImage = (e) => {
    setIsImageLoading(true);
    const tgt = e.target || window.event.srcElement;
    const files = tgt.files;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      const imageFile = tgt.files[0];

      const formData = new FormData();
      formData.append('image', imageFile);

      fr.onload = () => {
        const imageFile = tgt.files[0];
        let imageName = 'image' + Date.now();
        const storageRef = firebase.storage().ref('/images/' + imageName);

        storageRef
          .put(imageFile)
          .then((uploadTaskSnapshot) => {
            uploadTaskSnapshot.ref.getDownloadURL().then((downloadUrl) => {
              setIsImageLoading(false);
              setFieldValue('image_url', downloadUrl);
              setFieldTouched('image_url', true);
              setFieldValue('imageName', imageName);
              setFieldTouched('imageName', imageName);
            });
          })
          .catch((e) => {
            alert(e);
          });
      };

      fr.readAsDataURL(files[0]);
    }
  };

  const showThankYou = () => {
    if (user && user['isRehomingUser']) {
      return (
        <div className="alert alert-info d-flex justify-content-between mb-3">
          <div className="alert-text">
            Thank you for creating an account. Please add the pet that needs a
            temporary home for.
          </div>
        </div>
      );
    }
  };

  const isForMultipleAnimals = Boolean(
    new URL(window.location.href).searchParams.get('multiple'),
  );

  const amountOfAnimals = isForMultipleAnimals ? 'plural' : 'singular';
  return (
    <FormikForm>
      <fieldset disabled="">
        <Row>
          <Col md="8" className="mx-auto">
            <div className="bg-white p-3 p-md-4 border border-width-2 border-gray-400">
              {showThankYou()}
              <h5>{PLURALIZATION[amountOfAnimals].title}</h5>

              <LabelField
                labelName="Species"
                htmlFor="species"
                fieldName="species"
                required
              />
              <Form.Group>
                <Form.Check
                  inline
                  type="radio"
                  name="species"
                  label="Dog"
                  onChange={() => {
                    setFieldValue('species', 'dog');
                    setFieldTouched('species', true);
                  }}
                  checked={values.species === 'dog'}
                  id="speciesDog"
                />

                <Form.Check
                  inline
                  type="radio"
                  name="species"
                  label="Cat"
                  onChange={() => {
                    setFieldValue('species', 'cat');
                    setFieldTouched('species', true);
                  }}
                  checked={values.species === 'cat'}
                  id="speciesCat"
                />

                <Form.Check
                  inline
                  type="radio"
                  name="species"
                  label="Other (specify in breed field below)"
                  onChange={() => {
                    setFieldValue('species', 'other');
                    setFieldTouched('species', true);
                  }}
                  checked={values.species === 'other'}
                  id="speciesOther"
                />
              </Form.Group>
              <LabelField
                labelName="Gender"
                htmlFor="gender"
                fieldName="gender"
                required
              />
              <Form.Group>
                <Form.Check
                  inline
                  type="radio"
                  name="gender"
                  label="Male"
                  onChange={() => {
                    setFieldValue('gender', 'male');
                    setFieldTouched('gender', true);
                  }}
                  checked={values.gender === 'male'}
                  id="genderMale"
                />

                <Form.Check
                  inline
                  type="radio"
                  name="gender"
                  label="Female"
                  onChange={() => {
                    setFieldValue('gender', 'female');
                    setFieldTouched('gender', true);
                  }}
                  checked={values.gender === 'female'}
                  id="genderFemale"
                />

                {isForMultipleAnimals ? (
                  <Form.Check
                    inline
                    type="radio"
                    name="gender"
                    label="One or more of each"
                    onChange={() => {
                      setFieldValue('gender', 'mixed gender');
                      setFieldTouched('gender', true);
                    }}
                    checked={values.gender === 'mixed gender'}
                    id="genderMixed"
                  />
                ) : (
                  <div></div>
                )}
              </Form.Group>

              {values.species === 'cat' && (
                <div>
                  <LabelField
                    labelName={
                      values.species === 'cat'
                        ? 'Is unsocialized or feral'
                        : 'Is unsocialized'
                    }
                    htmlFor="isFeral"
                    fieldName="isFeral"
                    required
                  />

                  <Form.Group>
                    <Form.Check
                      inline
                      type="radio"
                      name="isFeral"
                      label="Yes"
                      onChange={() => {
                        setFieldValue('isFeral', true);
                      }}
                      checked={values.isFeral}
                    />

                    <Form.Check
                      inline
                      type="radio"
                      name="isFeral"
                      label="No"
                      onChange={() => {
                        setFieldValue('isFeral', false);
                      }}
                      checked={!values.isFeral}
                    />
                  </Form.Group>
                </div>
              )}
              <InputField
                label="Breed (or looks like)"
                errors={errors}
                name="breed"
                required
                onChange={handleChange}
                placeholder={PLURALIZATION[amountOfAnimals].breedLabel}
                touched={touched}
                type="text"
              />
              {isForMultipleAnimals ? (
                <InputField
                  label="Quantity"
                  errors={errors}
                  name="quantity"
                  required
                  onChange={handleChange}
                  placeholder="Enter quantity of pets"
                  touched={touched}
                  type="number"
                />
              ) : (
                <div />
              )}
              <InputField
                label={
                  values.isFromRehomingUser
                    ? isForMultipleAnimals
                      ? 'Names of pets'
                      : 'Name of pet'
                    : isForMultipleAnimals
                    ? 'Pet names/Identifiers of pets'
                    : 'Pet name/Identifier of pet'
                }
                errors={errors}
                name="name"
                required
                onChange={handleChange}
                placeholder="Enter a pet name or identifier"
                touched={touched}
                type="text"
              />
              <InputField
                label={
                  isForMultipleAnimals
                    ? 'Microchip Numbers'
                    : 'Microchip Number'
                }
                errors={errors}
                name="microChipNumber"
                onChange={handleChange}
                placeholder="Enter the pet's microchip number"
                touched={touched}
                type="text"
              />
              <div>
                <LabelField
                  labelName={
                    isForMultipleAnimals
                      ? 'Are these pets spayed/neutered?'
                      : 'Is this pet spayed/neutered?'
                  }
                  htmlFor="isSpayedOrNeutered"
                  fieldName="isSpayedOrNeutered"
                />

                <Form.Group>
                  <Form.Check
                    inline
                    type="radio"
                    name="isSpayedOrNeutered"
                    label="Yes"
                    onChange={() => {
                      setFieldValue('isSpayedOrNeutered', true);
                    }}
                    checked={values.isSpayedOrNeutered}
                    id="isSpayedOrNeuteredYes"
                  />

                  <Form.Check
                    inline
                    type="radio"
                    name="isSpayedOrNeutered"
                    label="No"
                    onChange={() => {
                      setFieldValue('isSpayedOrNeutered', false);
                    }}
                    checked={values.isSpayedOrNeutered === false}
                    id="isSpayedOrNeuteredNo"
                  />

                  <Form.Check
                    inline
                    type="radio"
                    name="isSpayedOrNeutered"
                    label="Unknown"
                    onChange={() => {
                      setFieldValue('isSpayedOrNeutered', null);
                    }}
                    checked={values.isSpayedOrNeutered === null}
                    id="isSpayedOrNeuteredUnknown"
                  />
                </Form.Group>
              </div>
              {user && values.isFromRehomingUser && (
                <div>
                  <LabelField
                    labelName="What type of home are you looking for?"
                    htmlFor="typeOfFoster"
                    fieldName="typeOfFoster"
                    required
                  />

                  <Form.Group>
                    <Form.Check
                      inline
                      type="radio"
                      name="typeOfFoster"
                      label="Looking for temporary home"
                      onChange={() => {
                        setFieldValue('typeOfFoster', 'temporary');
                      }}
                      checked={values.typeOfFoster === 'temporary'}
                    />

                    <Form.Check
                      inline
                      type="radio"
                      name="typeOfFoster"
                      label="Looking for permanent home"
                      onChange={() => {
                        setFieldValue('typeOfFoster', 'permanent');
                      }}
                      checked={values.typeOfFoster === 'permanent'}
                    />
                  </Form.Group>
                </div>
              )}
              <LabelField labelName="Age" />
              <div className="d-flex mb-2">
                <InputField
                  errors={errors}
                  name="ageYears"
                  onChange={handleChange}
                  placeholder="Years"
                  touched={touched}
                  type="text"
                  className="px-1"
                />

                <label className="p-2">and</label>

                <InputField
                  errors={errors}
                  name="ageMonths"
                  onChange={handleChange}
                  placeholder="Months"
                  touched={touched}
                  type="text"
                  className="px-1"
                />
              </div>
              {user && !values.isFromRehomingUser && (
                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Status
                  </Form.Label>

                  <div>
                    <small>
                      Animals Marked as Available will be shown to the public
                    </small>
                  </div>

                  <select
                    name="status"
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={values.status}
                  >
                    <option value="available">Available for fostering</option>
                    <option value="unavailable">Unavailable</option>
                    <option value="fostering">In Foster Home</option>
                  </select>
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label className="font-weight-bold mb-0">
                  How long is fostering needed?
                </Form.Label>

                <select
                  name="potentialLengthOfStay"
                  className="form-control"
                  onChange={handleChange}
                  defaultValue={values.potentialLengthOfStay}
                >
                  <option value="A few days">A few days</option>
                  <option value="A few weeks">A few weeks</option>
                  <option value="A few months">A few months</option>
                  <option value="Long term">Long term</option>
                  <option value="Until a forever home for pet is found">
                    Until a forever home for pet is found
                  </option>
                </select>
              </Form.Group>

              {/* <LabelField
                required={!!values.isFromRehomingUser}
                labelName="How long is fostering needed for?"
              />
              <div className="d-flex mb-2">
                <InputField
                  errors={errors}
                  name="potentialLengthOfStay"
                  onChange={handleChange}
                  placeholder="1 month/until I find a place to stay"
                  touched={touched}
                  required={!!values.isFromRehomingUser}
                  type="text"
                  className="px-1"
                />
              </div> */}
              {values.status === 'available' && (
                <Form.Group>
                  <Form.Check
                    inline
                    type="checkbox"
                    name="hasUrgentNeed"
                    label="Foster urgently needed"
                    onChange={handleChange}
                    checked={values.hasUrgentNeed}
                    id="hasUrgentNeed"
                  />
                  {user && !values.isFromRehomingUser && (
                    <Form.Check
                      inline
                      type="checkbox"
                      name="isInCustody"
                      label="In Custody"
                      onChange={handleChange}
                      checked={values.isInCustody}
                      id="isInCustody"
                    />
                  )}
                </Form.Group>
              )}
              <Form.Group>
                <LabelField
                  labelName={PLURALIZATION[amountOfAnimals].imageLabel}
                  htmlFor="image_url"
                  fieldName="image_url"
                  required
                />
                <div>
                  One clear photo showing pet’s face and body is best. This is
                  important when potential fosters view the pet’s profile to
                  consider fostering.
                </div>
                <input
                  id="image_url"
                  className="w-100 p-3 border rounded border-gray-400"
                  name="image_url"
                  type="file"
                  accept="image/*"
                  onChange={(e) => uploadImage(e)}
                />
                {isImageLoading ? <Loader /> : <div />}
              </Form.Group>

              {user && !values.isFromRehomingUser && (
                <Form.Group>
                  <Form.Label className="font-weight-bold mb-0">
                    Private Notes
                  </Form.Label>

                  <div>
                    <small>These Notes will NOT be shown to the public</small>
                  </div>

                  <Field
                    component="textarea"
                    className="form-control mb-3"
                    label="Notes"
                    errors={errors}
                    name="notes"
                    onChange={handleChange}
                    placeholder="Medical details, shelter ID, microchip, etc"
                    touched={touched}
                    type="textarea"
                  />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label className="font-weight-bold mb-0">
                  Other Details
                </Form.Label>

                <div>
                  Provide a few paragraphs to describe your pet for potential
                  foster parent to view, this information will be public. What
                  is it like to live with your pet? Think of the things you
                  would like to know if you were trying to choose which pet to
                  foster.
                </div>

                <Field
                  component="textarea"
                  className="form-control mb-3"
                  label="Public Notes"
                  errors={errors}
                  name="publicNotes"
                  onChange={handleChange}
                  placeholder="For example: energy level, character, dietary & medical needs, sleeping & other habits, good with kids & other pets, etc."
                  touched={touched}
                  type="textarea"
                />
              </Form.Group>
              {user && values.isFromRehomingUser && (
                <div>
                  <InputField
                    label="Primary Vet"
                    errors={errors}
                    name="primaryVet"
                    onChange={handleChange}
                    placeholder="Enter the pet's primary veterinarian"
                    touched={touched}
                    type="text"
                  />

                  <InputField
                    label="Emergency Contact Name"
                    errors={errors}
                    name="emergencyContactName"
                    onChange={handleChange}
                    placeholder="Enter the pet's emergency contact's name"
                    touched={touched}
                    type="text"
                  />

                  <InputField
                    label="Emergency Contact Phone Number"
                    errors={errors}
                    name="emergencyContactPhoneNumber"
                    onChange={handleChange}
                    placeholder="Enter the pet's emergency contact's phone number"
                    touched={touched}
                    type="text"
                  />

                  <InputField
                    label="Emergency Contact's relationship to owner"
                    errors={errors}
                    name="emergencyContactRelationship"
                    onChange={handleChange}
                    placeholder="Enter the pet's emergency contact's relationship to the pets owner"
                    touched={touched}
                    type="text"
                  />
                </div>
              )}
              <Form.Group>
                <LabelField
                  labelName="More Information"
                  htmlFor="conditions"
                  fieldName="conditions"
                  optional
                />
                <div>
                  Add multiple details that apply to the pet from the pull down
                  menu on the right to help us make a good foster match for your
                  pet.
                </div>
                <Select
                  value={values.conditions.map((condition) => {
                    return {
                      label: CONDITIONSMAPWITHUNKNOWNS[condition],
                      value: condition,
                    };
                  })}
                  onChange={(values) =>
                    setFieldValue(
                      'conditions',
                      values ? values.map((value) => value.value) : [],
                    )
                  }
                  onBlur={() => setFieldTouched('conditions', true, true)}
                  name="conditions"
                  options={CONDITIONSARRAY}
                  isMulti
                  isSearchable
                />
                {errors.conditions && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {errors.conditions}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Button
                loading={isSubmitting}
                color="primary"
                disabled={!isValid}
                type="submit"
                size="sm"
              >
                Submit
              </Button>
            </div>
            <div className="my-5 py-5" />
          </Col>
        </Row>
      </fieldset>
    </FormikForm>
  );
};

export default AddAnimalForm;
