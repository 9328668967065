import React from 'react';
import { Container } from 'react-bootstrap';
import ShelterLuvImportCard from '../../components/ShelterLuvImportCard';
import PetpointImportCard from '../../components/PetpointImportCard';

const ImportPage = (props) => {
  return (
    <Container className="py-3 py-sm-5">
      <h3 className="mb-3">Import Animals</h3>

      <ShelterLuvImportCard {...props} />

      <PetpointImportCard />
    </Container>
  );
};

export default ImportPage;
